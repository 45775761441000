import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import {
  AcceleratorFormSchema,
  submitAcceleratorForm,
} from 'src/api/endpoints/accelerator';
import { AcceleratorFormLocalStorage } from 'src/utils/Constants';
import { SNACKBAR } from 'src/utils/constants/app';
import { useDebouncedCallback } from 'src/utils/hooks';
import { removeByIndex } from 'src/utils/ArrayHelper';
import Stepper from 'src/components/Stepper';

import Heading from './components/Heading';
import MalwareScanDialog from './components/MalwareScanDialog';
import ManifestTemplateActions from './components/ManifestTemplateActions';
import { useMalwareCheck } from './hooks/useMalwareScan';
import {
  ACCELERATOR_FORM_LAST_STEP_NUMBER,
  ACCELERATOR_FORM_STEP_COMPONENTS,
  ACCELERATOR_FORM_STEP_INDEX,
  ACCELERATOR_FORM_STEP_NAMES,
  DEFAULT_ACCELERATOR_FORM_DATA,
} from './constants';
import { TerminatedBy } from './typings';

const AcceleratorForm: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const {
    addFilesToScanQueue,
    removeFileFromScanQueue,
    removeAllFilesFromScanQueue,
    inProgress: isMalwareScanInProgress,
    isCompleted: isMalwareScanCompleted,
    filesWithMalware,
    temporaryFilesWithMalware,
    totalScannedFilesCount,
    cleanTemporaryFilesWithMalware,
  } = useMalwareCheck();

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [activeStep, setActiveStep] = useState(
    ACCELERATOR_FORM_STEP_INDEX.siteInformation
  );
  const [formData, setFormData] = useState<AcceleratorFormSchema>(
    DEFAULT_ACCELERATOR_FORM_DATA
  );

  const [terminatedBy, setTerminatedBy] = useState<TerminatedBy | undefined>();

  const hasFilesWithMalware = useMemo(() => {
    return temporaryFilesWithMalware.length > 0;
  }, [temporaryFilesWithMalware]);

  const stepWithWarning = useMemo<number | undefined>(() => {
    const isTerminatedByWarning = terminatedBy && terminatedBy === 'warning';

    if (
      (hasFilesWithMalware &&
        temporaryFilesWithMalware.length !== totalScannedFilesCount) ||
      isMalwareScanInProgress ||
      isTerminatedByWarning
    ) {
      return ACCELERATOR_FORM_STEP_INDEX.materialsInformation;
    }

    return undefined;
  }, [
    hasFilesWithMalware,
    isMalwareScanInProgress,
    temporaryFilesWithMalware,
    totalScannedFilesCount,
    terminatedBy,
  ]);

  const stepWithError = useMemo<number | undefined>(() => {
    const isTerminatedByError = terminatedBy && terminatedBy === 'error';

    if (
      (hasFilesWithMalware &&
        temporaryFilesWithMalware.length === totalScannedFilesCount) ||
      isTerminatedByError
    ) {
      return ACCELERATOR_FORM_STEP_INDEX.materialsInformation;
    }

    return undefined;
  }, [
    hasFilesWithMalware,
    temporaryFilesWithMalware,
    totalScannedFilesCount,
    terminatedBy,
  ]);

  useEffect(() => {
    const savedFormData = localStorage.getItem(AcceleratorFormLocalStorage);
    const persistedFormData = savedFormData ? JSON.parse(savedFormData) : {};

    setFormData({ ...DEFAULT_ACCELERATOR_FORM_DATA, ...persistedFormData });
  }, []);

  const updateFormData = useCallback((data: Partial<AcceleratorFormSchema>) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));
  }, []);

  const submitForm = async (data: AcceleratorFormSchema) => {
    setInProgress(true);

    try {
      await submitAcceleratorForm(data);

      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      localStorage.setItem(
        AcceleratorFormLocalStorage,
        JSON.stringify(DEFAULT_ACCELERATOR_FORM_DATA)
      );
    } catch (error) {
      enqueueSnackbar(error.message, {
        ...SNACKBAR.defaultOptions,
        variant: 'error',
        persist: false,
        resumeHideDuration: SNACKBAR.resumeHideDuration.error,
      });
    } finally {
      setInProgress(false);
    }
  };

  const handleBackToMaterialsInfoStep = (terminatedReason?: TerminatedBy) => {
    setTerminatedBy(terminatedReason);
    setActiveStep(ACCELERATOR_FORM_STEP_INDEX.materialsInformation);
  };

  const handleNext = (data: AcceleratorFormSchema) => {
    const updatedFormData = { ...formData, ...data };
    if (activeStep === ACCELERATOR_FORM_STEP_INDEX.projectCompletion) {
      submitForm(updatedFormData);
    } else {
      updateFormData(updatedFormData);
      cleanTemporaryFilesWithMalware();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = (data?: AcceleratorFormSchema) => {
    if (activeStep === ACCELERATOR_FORM_STEP_INDEX.complete) {
      setFormData(DEFAULT_ACCELERATOR_FORM_DATA);
      localStorage.removeItem(AcceleratorFormLocalStorage);
      setActiveStep(ACCELERATOR_FORM_STEP_INDEX.siteInformation);
    } else {
      if (data) {
        updateFormData({ ...formData, ...data });
      }

      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const [syncFormDataInLocalStorage] = useDebouncedCallback(
    (data: Partial<AcceleratorFormSchema>) => {
      const savedFormData = localStorage.getItem(AcceleratorFormLocalStorage);
      const formData = savedFormData
        ? JSON.parse(savedFormData)
        : DEFAULT_ACCELERATOR_FORM_DATA;
      const newFormData = {
        ...formData,
        ...data,
      };

      localStorage.setItem(
        AcceleratorFormLocalStorage,
        JSON.stringify(newFormData)
      );
    },
    500
  );

  useEffect(() => {
    // automatically remove files with malware
    if (isMalwareScanCompleted && filesWithMalware.length > 0) {
      for (const fileWithMalware of filesWithMalware) {
        const currentLinks = JSON.parse(
          `${formData.sampleReagentsManifestLinks}`
        );

        const index = formData.sampleReagentsManifests.findIndex(
          (f) => f === fileWithMalware
        );

        const newFormData = {
          sampleReagentsManifestLinks: JSON.stringify(
            removeByIndex(currentLinks, index)
          ),
          sampleReagentsManifests: formData.sampleReagentsManifests.filter(
            (f) => f !== fileWithMalware
          ),
        };

        updateFormData(newFormData);
        syncFormDataInLocalStorage(newFormData);
        removeFileFromScanQueue(fileWithMalware);
      }
    }
  }, [
    formData.sampleReagentsManifests,
    formData.sampleReagentsManifestLinks,
    filesWithMalware,
    isMalwareScanCompleted,
    removeFileFromScanQueue,
    updateFormData,
    syncFormDataInLocalStorage,
  ]);

  useEffect(() => {
    if (isMalwareScanCompleted && !hasFilesWithMalware && !!terminatedBy) {
      // clear terminatedBy reason
      setTerminatedBy(undefined);
    }
  }, [isMalwareScanCompleted, hasFilesWithMalware, terminatedBy]);

  const handleFormCancel = () => {
    removeAllFilesFromScanQueue();

    setFormData(DEFAULT_ACCELERATOR_FORM_DATA);

    localStorage.setItem(
      AcceleratorFormLocalStorage,
      JSON.stringify(DEFAULT_ACCELERATOR_FORM_DATA)
    );

    history.push('/welcome');
  };

  const ActiveStepComponent = ACCELERATOR_FORM_STEP_COMPONENTS[activeStep];

  return (
    <div className="AuthLayoutContent">
      <Heading />
      <Stepper
        steps={ACCELERATOR_FORM_STEP_NAMES}
        activeStep={activeStep}
        stepWithWarning={stepWithWarning}
        stepWithError={stepWithError}
      />
      {activeStep !== ACCELERATOR_FORM_LAST_STEP_NUMBER && (
        <ManifestTemplateActions />
      )}
      <div>
        <ActiveStepComponent
          inProgress={inProgress}
          formData={formData}
          isMalwareScanInProgress={isMalwareScanInProgress}
          isMalwareScanCompleted={isMalwareScanCompleted}
          hasFilesWithMalware={hasFilesWithMalware}
          filesWithMalware={filesWithMalware}
          totalScannedFilesCount={totalScannedFilesCount}
          addFilesToScanQueue={addFilesToScanQueue}
          removeFileFromScanQueue={removeFileFromScanQueue}
          onManifestsUpload={updateFormData}
          onFormDataChange={syncFormDataInLocalStorage}
          onNext={handleNext}
          onPrevious={handleBack}
          onFormCancelOpen={setIsCancelDialogOpen}
          onFormCancel={handleFormCancel}
        />
      </div>
      <MalwareScanDialog
        isCancelDialogOpen={isCancelDialogOpen}
        isMaterialsInfoStepActive={
          activeStep === ACCELERATOR_FORM_STEP_INDEX.materialsInformation
        }
        isMalwareScanCompleted={isMalwareScanCompleted}
        filesWithMalware={temporaryFilesWithMalware}
        cleanFilesWithMalware={cleanTemporaryFilesWithMalware}
        totalScannedFilesCount={totalScannedFilesCount}
        onLinkClick={handleBackToMaterialsInfoStep}
      />
    </div>
  );
};

export default AcceleratorForm;
