import { AcceleratorFormSchema } from './typings';

type NormilizeFormDataResult = Partial<AcceleratorFormSchema>;

export const normilizeFormData = (
  formData: AcceleratorFormSchema
): NormilizeFormDataResult => {
  const normalized: NormilizeFormDataResult = {};

  for (const [key, value] of Object.entries(formData)) {
    if (value) {
      normalized[key as keyof AcceleratorFormSchema] = value;
    }
  }

  if (normalized.sampleReagentsManifestLinks) {
    normalized.sampleReagentsManifestLinks = JSON.parse(
      `${normalized.sampleReagentsManifestLinks}`
    );
  }

  return normalized;
};
