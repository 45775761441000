import { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { confirmUserByAdmin } from 'src/utils/CognitoIdentityHelper';

const ConfirmUser: FC = (props) => {
  const [message, setMessage] = useState('Confirming...');

  const { username } = useParams<{ username: string }>();

  useEffect(() => {
    setMessage('Confirming...');
    confirmUserByAdmin(username)
      .then((data) => {
        data
          .on('success', function (response) {
            setMessage('User confirmed');
          })
          .on('error', function (response) {
            setMessage('User confirmation failed. ' + response.message);
          });
      })
      .catch((err) => {
        setMessage('User confirmation failed. ' + err);
      });
  }, [username]);

  return <>{message}</>;
};

export default ConfirmUser;
