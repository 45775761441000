import { privateGateway } from '../../../config';
import { RequestMalwareCheckResult } from '../typings';

export const requestMalwareScan = async (
  file_names: string[]
): Promise<RequestMalwareCheckResult> => {
  const response = await privateGateway(
    process.env.REACT_APP_SUBMISSION_API_GATEWAY as string
  ).post(`/form/file/malware_scan`, { file_names });

  return response.data;
};
