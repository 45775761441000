import { FC } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

const HelpText: FC = () => (
  <p>
    <Typography>
      Please use the drop down menu and the check boxes to refine your search.
      Tips to help you find what you are looking for include:
      <List>
        <ListItem>
          - Use quotes around multiple words to ensure the search only returns
          when the words appear together in that sequence as entered.
        </ListItem>
        <ListItem>
          - Add a wildcard * behind or in front of a few letters in the search
          terms to return any document that contains part of the word you are
          looking for.
        </ListItem>
        <ListItem>
          - If you can't find what you are looking for, try a few other search
          terms.
        </ListItem>
      </List>
    </Typography>
  </p>
);

export default HelpText;
