import {
  requestFileUploadPlacement,
  requestFileUrl,
  requestSaveTemplate,
  requestTemplateDownloadUrl,
  RequestTemplateResult,
  uploadFile,
} from 'src/api/endpoints/blobStorage';

export const getDownloadUrl = async (file_name: string): Promise<string> => {
  const data = await requestFileUrl({ file_name });

  return data.download_url;
};

export const uploadToBlobStorage = async (file: File): Promise<string> => {
  const result = await requestFileUploadPlacement({
    file_name: file.name,
  });
  await uploadFile(result.url, file, result.data);

  return result.data.key;
};

export const uploadTemplateToBlobStorage = async (
  file: File,
  template_type: string
): Promise<RequestTemplateResult> => {
  const file_name = await uploadToBlobStorage(file);
  const response = await requestSaveTemplate({
    file_name,
    template_type,
  });

  return response;
};

export const getTemplateDownloadUrl = async (
  template_type: string
): Promise<string> => {
  const { file_name } = await requestTemplateDownloadUrl({ template_type });
  const download_url = await getDownloadUrl(file_name);

  return download_url;
};
