import { FC } from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ChangePasswordForm from 'src/components/ChangePasswordForm';

const ChangeExpiredPassword: FC = () => {
  return (
    <>
      <Box mt={2} mb={3}>
        <Typography variant="subtitle1">
          Your password is expired, change to continue
        </Typography>
      </Box>
      <ChangePasswordForm />
    </>
  );
};

export default ChangeExpiredPassword;
