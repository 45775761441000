import { useEffect, useState } from 'react';

import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/Info';

import SearchBox from 'src/pages/Search/components/SearchBox';
import SearchActions from 'src/pages/Search/components/SearchActions';

import './SearchBar.css';

export default function SearchBar({
  path,
  inProgress,
  onSubmitSearch,
  onClearSearch,
}) {
  const [query, setQuery] = useState('');
  const [isTitle, setIsTitle] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setQuery('');
    setIsTitle(true);
  }, [path]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (query) {
      onSubmitSearch(query, isTitle ? 'title' : 'content');
    }
  };

  const onClear = () => {
    setQuery('');
    setIsTitle(true);
    onClearSearch();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="FolderViewerSearchBar">
      <form onSubmit={onSubmit}>
        <Grid container spacing={1} alignItems="center" justify="flex-end">
          <Grid item>
            <FormControlLabel
              className={'SearchSwitch'}
              control={
                <Switch
                  checked={isTitle}
                  color="primary"
                  onChange={(e) => setIsTitle(e.target.checked)}
                />
              }
              label={isTitle ? 'Search by Title' : 'Search by Content'}
            />
          </Grid>
          <Grid item>
            <Info
              aria-describedby={id}
              color="primary"
              className="SearchTipsIcon"
              onClick={handleClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={handleClose}
            >
              <Typography className="SearchTips">
                Add a wildcard <strong>*</strong> after a few letters in the
                search term to return more results
              </Typography>
            </Popover>
          </Grid>
          <Grid item>
            <SearchBox query={query} onChange={setQuery} />
          </Grid>
          <Grid item>
            <SearchActions
              inProgress={inProgress}
              disabled={inProgress}
              onClearSearch={onClear}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
