import { OptionsObject } from 'notistack';

export const HEADER = {
  height: 55,
};

export const FOOTER = {
  height: 115,
};

export const SUPPORT_SECTION = {
  height: 49,
};

export const SNACKBAR = {
  defaultOptions: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  } as Partial<OptionsObject>,
  resumeHideDuration: {
    success: 800,
    error: 2400,
  },
};

export const COMMON_ERRORS = {
  invalidSignature:
    'For security reasons, your portal accessibility is limited due to a discrepancy between the time on your computer and the local time zone. Please set your computer to automatically adjust to the local time zone and refresh the page.',
};
