import axios, { AxiosInstance } from 'axios';

import { includeAccessToken, includeSessionToken } from './interceptors';

export interface PrivateGatewayOptions {
  useAccessToken?: boolean;
}

const privateGateway = (
  apiGateway: string,
  { useAccessToken = false }: PrivateGatewayOptions = {}
): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: apiGateway,
  });

  if (useAccessToken) {
    axiosInstance.interceptors.request.use(includeAccessToken);
  } else {
    axiosInstance.interceptors.request.use(includeSessionToken);
  }

  return axiosInstance;
};

export { privateGateway };
