import { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import MuiButton from '@material-ui/core/Button';

import Button from '@quanterix-ui/core/Button';

import { useConfirmUser } from 'src/aws/UserPool';
import { COMMON_ERRORS, SNACKBAR } from 'src/utils/constants/app';
import {
  confirmUserByAdmin,
  deleteUserByAdmin,
  disableUserByAdmin,
  enableUserByAdmin,
  getUsersByAdmin,
  parseUserAttribute,
} from 'src/utils/CognitoIdentityHelper';
import { IOSSwitch } from 'src/components/common/ios-switch/IOSSwitch';
import ConfirmationInfo from 'src/components/common/users/ConfirmationInfo';
import ConfirmDialog from 'src/components/ConfirmDialog';
import 'src/components/common/users/BaseUser.css';

import Filter from './Filter';
import ExportUsersList from './components/ExportUsersList';

export default function UserList() {
  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { confirmUser } = useConfirmUser();

  const [users, setUsers] = useState([]);
  const [paginationToken, setPaginationToken] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);
  const [filterValue, setUsersFilterValue] = useState(null);
  const [filterField, setUsersFilterField] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getUserList();
      setLoaded(true);
      setUserToDelete(null);
    };

    fetchData();
  }, []);

  const fetchUsers = async (paginationToken, filter) => {
    try {
      const users = await getUsersByAdmin(paginationToken, filter);
      return users;
    } catch (error) {
      if (error.name === 'InvalidSignatureException') {
        enqueueSnackbar(COMMON_ERRORS.invalidSignature, {
          ...SNACKBAR.defaultOptions,
          variant: 'error',
          persist: true,
          action: (key) => (
            <MuiButton
              variant="text"
              color="inherit"
              size="small"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              Ok
            </MuiButton>
          ),
        });
      }

      throw error;
    }
  };

  const enableUser = async (username) => {
    const request = await enableUserByAdmin(username);
    request.on('error', function (response) {
      console.error('User enabling failed. ' + response.error.message);
      throw 'enabling failed';
    });
  };

  const enableDisableUser = async (username, enabled) => {
    changeEnabling(username, enabled);
    if (enabled) {
      await enableUser(username).catch(() => {
        changeEnabling(username, !enabled);
      });
    } else {
      await disableUser(username).catch(() => {
        changeEnabling(username, !enabled);
      });
    }
  };

  const changeEnabling = (username, enabled) => {
    const newUsersOnPage = Object.assign([], filteredUsers);
    const index = newUsersOnPage.findIndex((x) => x.Username === username);
    newUsersOnPage[index].Enabled = enabled;
    setFilteredUsers(newUsersOnPage);
  };

  const handleDeleteConfirm = async () => {
    if (userToDelete.Enabled) {
      await disableUser(userToDelete.Username);
    }

    const request = await deleteUserByAdmin(userToDelete.Username);

    request.on('success', () => {
      setUserToDelete(null);
      setIsDeleteUserDialogOpen(false);
      getUserList(filterField, filterValue);
    });

    request.on('error', (response) => {
      console.error('User deleting failed. ' + response.error.message);
    });
  };

  const disableUser = async (username) => {
    const request = await disableUserByAdmin(username);

    request.on('error', (response) => {
      console.error('User disabling failed. ' + response.error.message);
      throw new Error('disabling failed');
    });
  };

  const getUserList = async (filter) => {
    const response = await fetchUsers(null, filter);
    const usersFromResponse = response.Users;
    const usersAfterFilter = usersFromResponse;

    setUsers(usersFromResponse);
    setPaginationToken(response.PaginationToken || '');
    setFilteredUsers(usersAfterFilter);
    setIsFiltering(false);
  };

  const handleConfirmUser = (username) => {
    try {
      confirmUser(username);

      setTimeout(() => {
        getUserList(filterField, filterValue);
      }, 1000);
    } catch (error) {
      console.error(error.message);
    }
  };

  const loadMoreUsers = async () => {
    setPageLoading(true);

    const response = await fetchUsers(paginationToken);
    const usersFromResponse = response.Users;
    setUsers(users.concat(usersFromResponse));
    setPaginationToken(response.PaginationToken);
    setFilteredUsers(filteredUsers.concat(usersFromResponse));

    setPageLoading(false);
  };

  const filterUserApplied = (filter) => {
    setUsersFilterField(filter.field);
    setUsersFilterValue(filter.value);
    setIsFilterApplied(filter.field && filter.value);
    setIsFiltering(true);
    getUserList(filter);
  };

  const renderButtons = (user) => {
    const linkToEdit = '/admin/users/' + encodeURI(user.Username);

    const handleDeleteUserClick = () => {
      setIsDeleteUserDialogOpen(true);
      setUserToDelete(user);
    }

    return (
      <Grid container justify="flex-end" wrap="nowrap">
        {user.Enabled && (
          <IconButton href={linkToEdit}>
            <FontAwesomeIcon icon={faEdit} size="xs" className="actionIcon" />
          </IconButton>
        )}
        <IconButton onClick={handleDeleteUserClick}>
          <FontAwesomeIcon icon={faTrash} size="xs" className="actionIcon" />
        </IconButton>
      </Grid>
    );
  };

  const redirectToView = (username) => {
    const linkToView = '/admin/users/' + encodeURI(username) + '/view';
    history.push(linkToView);
  };

  const handleAddUser = () => {
    history.push('/admin/users/new');
  };

  const renderUser = (user) => {
    return (
      <TableRow key={parseUserAttribute(user.Attributes, 'email')}>
        <TableCell
          align="left"
          scope="row"
          className="Clickable"
          onClick={() => {
            redirectToView(user.Username);
          }}
        >
          {parseUserAttribute(user.Attributes, 'family_name') +
            ' ' +
            parseUserAttribute(user.Attributes, 'given_name')}
        </TableCell>
        <TableCell
          className="highlighted-text Clickable"
          align="left"
          onClick={() => {
            redirectToView(user.Username);
          }}
        >
          {parseUserAttribute(user.Attributes, 'email')}
        </TableCell>
        <TableCell
          className="highlighted-text Clickable"
          align="left"
          onClick={() => {
            redirectToView(user.Username);
          }}
        >
          {parseUserAttribute(user.Attributes, 'phone_number')}
        </TableCell>
        <TableCell
          className="Clickable"
          align="left"
          onClick={() => {
            redirectToView(user.Username);
          }}
        >
          {parseUserAttribute(user.Attributes, 'custom:company')}
        </TableCell>
        <TableCell align="left">
          <ConfirmationInfo
            small
            status={user.UserStatus}
            onConfirm={() => handleConfirmUser(user.Username)}
          />
        </TableCell>
        <TableCell align="left" className="IOSSwitchColumn">
          <IOSSwitch
            checked={user.Enabled}
            onChange={() => enableDisableUser(user.Username, !user.Enabled)}
          />
          {!user.Enabled ? (
            <span className="LabelInactive">Inactive</span>
          ) : (
            <span className="LabelActive">Active</span>
          )}
        </TableCell>
        <TableCell align="right">{renderButtons(user)}</TableCell>
      </TableRow>
    );
  };

  if (!loaded) {
    return <LinearProgress />;
  }

  return (
    <>
      <TableContainer className="UsersContainer">
        <Box mb={4}>
          <Grid container justify="space-between">
            <Filter
              isFilterApplied={isFilterApplied}
              isFiltering={isFiltering}
              onFilterUserApplied={filterUserApplied}
            />
            <Grid item>
              <Grid container justify="flex-end">
                <Box mt={4}>
                  <ExportUsersList />
                </Box>
                <Box mt={4} ml={2}>
                  <Button onClick={handleAddUser}>Add New</Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Table className="UsersTable" stickyHeader aria-label="users">
          <TableHead className="UsersTableHeader">
            <TableRow>
              <TableCell align="left">Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Enabled</TableCell>
              <TableCell align="right" style={{ paddingRight: 32 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="UsersTableBody">
            {filteredUsers.map((x) => renderUser(x))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isFilterApplied && !isFiltering && paginationToken && (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ height: 65 }}
        >
          <Button
            loading={pageLoading}
            disabled={pageLoading}
            variant="outlined"
            onClick={loadMoreUsers}
          >
            Load more users
          </Button>
        </Grid>
      )}
      {createPortal(
        <ConfirmDialog
          open={isDeleteUserDialogOpen}
          title={`Please confirm you want to delete ${
            parseUserAttribute(userToDelete?.Attributes, 'family_name') +
            ' ' +
            parseUserAttribute(userToDelete?.Attributes, 'given_name')
          }`}
          acceptButtonText="Continue"
          onClose={() => setIsDeleteUserDialogOpen(false)}
          onAccept={handleDeleteConfirm}
        />,
        document.getElementById('modal-root')
      )}
    </>
  );
}
