import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { useCognito } from 'src/aws/Cognito';
import { SNACKBAR } from 'src/utils/constants/app';

import { USER_POOL_ID } from '../constants';
import { UserSchema } from '../typings';

import { useIdentityService } from './useIdentityService';

export interface GetUserOptions {
  username: string;
}

interface UseGetUserResult {
  isLoading: boolean;
  user: UserSchema;
  refetchUser: () => void;
}

export const useGetUser = ({ username }: GetUserOptions): UseGetUserResult => {
  const { identityService } = useIdentityService();
  const { isAdmin } = useCognito();
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState<UserSchema>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = useCallback(() => {
    if (identityService && isAdmin) {
      setIsLoading(true);

      identityService.adminGetUser(
        {
          UserPoolId: USER_POOL_ID as string,
          Username: username,
        },
        (error, data) => {
          if (error) {
            enqueueSnackbar(error.message, {
              ...SNACKBAR.defaultOptions,
              variant: 'error',
              persist: false,
              resumeHideDuration: SNACKBAR.resumeHideDuration.error,
            });

            throw new Error(error.message);
          }

          setUser(data);
          setIsLoading(false);
        }
      );
    } else {
      setUser(null);
    }
  }, [username, identityService, isAdmin, enqueueSnackbar]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return { isLoading, user, refetchUser: fetchUser };
};
