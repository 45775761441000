import { FC, useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { requestDocumentsCount } from 'src/api/endpoints/admin';

const DocumentsCount: FC = () => {
  const [inProgress, setInProgress] = useState(false);
  const [documentsCount, setDocumentsCount] = useState(0);

  useEffect(() => {
    const fetchDocumentsCount = async () => {
      setInProgress(true);

      try {
        const response = await requestDocumentsCount();
        setDocumentsCount(response.count);
      } catch (error) {
        console.error('Failed to fetch documents count:', error);
      } finally {
        setInProgress(false);
      }
    };

    fetchDocumentsCount();
  }, []);

  if (inProgress) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid item>
        <Box>Total number of documents available:&nbsp;</Box>
      </Grid>
      <Grid item>
        <Box fontWeight={800}>{documentsCount}</Box>
      </Grid>
    </>
  );
};

export default DocumentsCount;
