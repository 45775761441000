import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: 320,
    padding: 10,
  },
  rootPaper: {
    borderRadius: '10px',
  },
  textfield: {
    fontSize: 14,
    height: 40,
    borderRadius: 10,
  },
  buttonWrapper: {
    paddingTop: 8,
    '&:last-of-type': {
      paddingLeft: 8,
    },
  },
}));
