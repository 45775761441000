import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import PasswordTextField from 'src/components/elements/PasswordTextField';

import { PASSWORD_PATTERN } from './constants';

interface Props {
  name: string;
  equalTo?: string;
  notEqualTo?: string;
  withMaxLength?: boolean;
  withPattern?: boolean;
}

const ControlledPasswordTextField: FC<Props> = ({
  name,
  equalTo,
  notEqualTo,
  withMaxLength = false,
  withPattern = false,
}) => {
  const { control, getValues } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, ref },
        fieldState: { error, invalid },
      }) => (
        <PasswordTextField
          fullWidth
          value={value}
          inputRef={ref}
          error={invalid}
          helperText={error?.message}
          onChange={onChange}
        />
      )}
      rules={{
        required: {
          value: true,
          message: 'This field is required',
        },

        minLength: {
          value: withMaxLength ? 10 : 0,
          message: 'Should contain 10 or more characters',
        },

        validate: {
          shouldFollowPattern: (value: string) => {
            if (!withPattern) return true;

            const errors = [];

            if (
              !new RegExp(`^${PASSWORD_PATTERN.atLeastOneDigit}`).test(value)
            ) {
              errors.push('1 digit');
            }

            if (
              !new RegExp(`^${PASSWORD_PATTERN.atLeastOneLowercase}`).test(
                value
              )
            ) {
              errors.push('1 lowercase letter');
            }

            if (
              !new RegExp(`^${PASSWORD_PATTERN.atLeastOneUppercase}`).test(
                value
              )
            ) {
              errors.push('1 uppercase letter');
            }

            if (
              !new RegExp(`^${PASSWORD_PATTERN.atLeastOneSpecial}`).test(value)
            ) {
              errors.push('1 special character');
            }

            return (
              errors.length === 0 ||
              `Should contain at least: ${errors.join(', ')}`
            );
          },

          shouldBeEqualTo: (value) => {
            if (!equalTo) return true;

            const values = getValues();
            const equalToValue = values?.[equalTo];
            return value === equalToValue || 'New passwords do not match';
          },

          shouldNotBeEqualTo: (value) => {
            if (!notEqualTo) return true;

            const values = getValues();
            const notEqualToValue = values?.[notEqualTo];
            return (
              value !== notEqualToValue ||
              'Should be different from the current password'
            );
          },
        },
      }}
    />
  );
};

export default ControlledPasswordTextField;
