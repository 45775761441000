import { FC, FormEventHandler, useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { usePortalContext } from 'src/state/context/PortalContext';

import { search } from './services/api';
import HelpText from './components/HelpText';
import COAText from './components/COAText';
import SearchBox from './components/SearchBox';
import SearchActions from './components/SearchActions';
import SearchResults from './components/SearchResults';
import SearchRefinemer from './components/SearchRefinemer';
import './Search.css';

interface SearchOptions {
  searchWithin: string;
  searchFolders: string[];
}

interface SearchBy {
  query: string;
  searchOptions: SearchOptions;
}

const Search: FC = () => {
  const { folders } = usePortalContext();

  const [inProgress, setInProgress] = useState(false);
  const [result, setResult] = useState<any>(null);
  const [query, setQuery] = useState('');
  const [searchBy, setSearchBy] = useState<SearchBy | null>(null);
  const [refinemerCollapsed, setRefinemerCollapsed] = useState(false);
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({
    searchWithin: 'title',
    searchFolders: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!searchBy) return;

      setInProgress(true);
      setResult(null);

      const { query, searchOptions } = searchBy;
      const data = await search(query, searchOptions);

      setResult(data);
      setInProgress(false);
    };

    fetchData();
  }, [searchBy]);

  useEffect(() => {
    setSearchOptions({
      searchWithin: 'title',
      searchFolders: folders.map((f) => f.name),
    });
  }, [folders]);

  const handleSetQuery = (value: string) => {
    setQuery(value);
  };

  const handleSubmitSearch: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    setRefinemerCollapsed(false);
    setSearchBy({ query, searchOptions });
  };

  const handleClearSearch = () => {
    setQuery('');
    setSearchBy(null);
    setResult(null);
  };

  const disableSeachButton =
    !query ||
    inProgress ||
    (searchOptions &&
      searchOptions.searchFolders &&
      searchOptions.searchFolders.length === 0);

  return (
    <div className="AuthLayoutContent SearchPage">
      <div className="folderHeader">Search</div>
      <form onSubmit={handleSubmitSearch}>
        <HelpText />
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <SearchBox query={query} onChange={handleSetQuery} />
          </Grid>
          <Grid item>
            <SearchActions
              inProgress={inProgress}
              disabled={disableSeachButton}
              onClearSearch={handleClearSearch}
            />
          </Grid>
        </Grid>
      </form>
      <SearchRefinemer
        searchOptions={searchOptions}
        collapsed={refinemerCollapsed}
        onCollapse={() => setRefinemerCollapsed(!refinemerCollapsed)}
        onRefine={setSearchOptions}
      />
      <SearchResults result={result} />
      <COAText />
    </div>
  );
};

export default Search;
