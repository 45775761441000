import { FC } from 'react';

import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';

const SearchResultHeader: FC = () => (
  <Grid container>
    <Grid item xs={7}>
      <FormLabel component="legend">Document</FormLabel>
    </Grid>
    <Grid item xs={5}>
      <FormLabel component="legend">Category</FormLabel>
    </Grid>
  </Grid>
);

export default SearchResultHeader;
