import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import ConfirmationInfo from '../ConfirmationInfo';
import MailVerifiedInfo from '../mail-verified-info/MailVerifiedInfo';

export default function BaseUserHeader({
  isAdmin = false,
  isNew = false,
  withDivider = false,
  fullName,
  mailVerified,
  status,
  controlButtons,
  confirmUser,
}) {
  const handleConfirmUser = () => {
    confirmUser();
  };

  const renderAdditionalButtons = () => {
    if (isNew) {
      return null;
    }

    if (isAdmin) {
      return (
        <ConfirmationInfo
          status={status}
          onConfirm={handleConfirmUser}
        />
      );
    }

    return (
      <MailVerifiedInfo mailVerified={mailVerified} />
    );
  };

  return (
    <Box width="100%" my={5}>
      <Grid container justify="space-between">
        <Grid item>
          <Grid container alignItems="center">
            <Box mr={5}>
              <Typography variant="h5">
                {fullName}
              </Typography>
            </Box>
            {withDivider && <Divider flexItem orientation="vertical" />}
            <Box ml={5} mb={-0.5}>
              {renderAdditionalButtons()}
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="center">
            {controlButtons}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
