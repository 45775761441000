import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-gutterBottom': {
      margin: theme.spacing(0, 0, 1, 0),
    },
    '& .MuiSvgIcon-root': {
      color: 'rgba(0,0,0,0.54)',
    },
  },
  formControl: {
    paddingTop: theme.spacing(2),
  },
  confirmPassword: {
    paddingTop: theme.spacing(1),
  },
  button: {
    width: 150,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  wrapper: {
    position: 'relative',
  },
  boldLink: {
    fontWeight: 600,
  },
  forgotPassMessage: {
    color: '#375e77',
    fontWeight: 900,
  },
}));
