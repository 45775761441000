import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';

import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

import Button from '@quanterix-ui/core/Button';
import TextField from '@quanterix-ui/core/TextField';

import { useCognito } from 'src/aws/Cognito';

import { useStyles } from './styles';

const EmailVerification: FC = () => {
  const classes = useStyles();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { userData, isEmailVerified, isAuthenticated } = useCognito();

  const [code, setCode] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const sendVerificationCode = async () => {
      setInProgress(true);

      try {
        setIsInitialized(true);

        if (!isEmailVerified) {
          await Auth.verifyCurrentUserAttribute('email');
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setInProgress(false);
      }
    };

    if (isAuthenticated && !isInitialized && !inProgress) {
      sendVerificationCode();
    }
  }, [
    isAuthenticated,
    isEmailVerified,
    isInitialized,
    inProgress,
    enqueueSnackbar,
  ]);

  const handleCodeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setCode(value);
  };

  const handleSendCode: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    setInProgress(true);

    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code);

      enqueueSnackbar('Your email has been verified.', { variant: 'success' });

      history.push('/');
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setInProgress(false);
    }
  };

  if (!isAuthenticated) {
    return (
      <>
        <Typography gutterBottom variant="subtitle1">
          Please log in to verify email
        </Typography>
        <Link className={classes.boldLink} to="/signin">
          Click here to Login
        </Link>
      </>
    );
  }

  if (isEmailVerified) {
    return (
      <>
        <Typography gutterBottom variant="subtitle1">
          Your email <b>{userData.email}</b> already verified
        </Typography>
        <Link className={classes.boldLink} to="/">
          Click here to go home
        </Link>
      </>
    );
  }

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="subtitle1">
        Verify your email <b>{userData.email}</b>
      </Typography>
      <form autoComplete="off" onSubmit={handleSendCode}>
        <div className={classes.formControl}>
          <FormLabel component="legend">Verification Code</FormLabel>
          <TextField
            fullWidth
            name="code"
            helperText="Please check your email for the verification code"
            onChange={handleCodeChange}
          />
        </div>
        <div className={classes.wrapper}>
          <Button
            className={classes.button}
            type="submit"
            loading={inProgress}
            disabled={inProgress || code.length === 0}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EmailVerification;
