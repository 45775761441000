import { useEffect, useState } from 'react';

import { DropzoneArea } from 'material-ui-dropzone';

import { getLink, uploadFile } from 'src/utils/S3Helper';
import ProgressOverlay from 'src/components/common/progress-overlay/ProgressOverlay';
import { useCognito } from 'src/aws/Cognito';

import './ImageEditor.css';

export default function ImageEditor(props) {
  const { userData } = useCognito();
  const [image, setImage] = useState(null);
  const [inProgress, setinProgress] = useState(false);
  const path = props.path;
  useEffect(() => {
    getLink(path).then((data) => {
      setImage(data);
    });
  }, [path]);

  const updateImage = async (files) => {
    if (files.length && files.length > 0) {
      setinProgress(true);
      let message = 'Image changed successfully';
      const result = await uploadFile({
        path,
        file: files[0],
        user_id: userData.id,
        source: 'cp page image',
      });
      if (result) {
        setImage(await getLink(path));
      } else {
        message = 'Failed to update image';
      }
      if (props.onUpload) {
        props.onUpload({ show: true, success: !!result, message });
      }
      setinProgress(false);
    }
  };

  return (
    <div
      style={{ backgroundImage: `url('${image}')` }}
      className={'imageEditorContainer'}
    >
      <ProgressOverlay inProgress={inProgress}>
        <DropzoneArea
          onChange={async (selected) => await updateImage(selected)}
          showPreviews={false}
          showPreviewsInDropzone={false}
          dropzoneText={'Choose image'}
          acceptedFiles={['image/png']}
          filesLimit={1}
          showAlerts={false}
          dropzoneClass={'transparentDropzone'}
        />
      </ProgressOverlay>
    </div>
  );
}
