import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

interface HookReturnData {
  isAdvanced: boolean;
  isProtected: boolean;
}

export const usePortalView = (): HookReturnData => {
  const { pathname } = useLocation();

  const [isProtected, setIsProtected] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);

  useEffect(() => {
    setIsProtected(pathname.startsWith('/admin/'));
    setIsAdvanced(
      pathname !== '/' &&
        !pathname.startsWith('/admin/users') &&
        !pathname.startsWith('/profile') &&
        !pathname.startsWith('/dashboard')
    );
  }, [pathname]);

  return {
    isAdvanced,
    isProtected,
  };
};
