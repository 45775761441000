import { makeStyles } from '@material-ui/core/styles';

import { HEADER } from 'src/utils/constants/app';

export const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: 'lightgray',
    overflow: 'hidden',
    backgroundColor: 'var(--light-grey)',
  },
  scrollbars: {
    height: `calc(100vh - ${HEADER.height}px) !important`,
  },
}));
