import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  step: {
    '& .MuiStepIcon-root': {
      color: theme.palette.grey[200],

      '& .MuiStepIcon-text': {
        fontSize: 12,
        fontWeight: 600,
      },
    },

    '& .MuiStepIcon-active, & .MuiStepIcon-completed': {
      color: theme.palette.secondary.main,

      '& .MuiStepIcon-text': {
        fill: theme.palette.common.white,
      },
    },

    '&.hasWarning .MuiStepIcon-root': {
      color: theme.palette.warning.main,

      '& .MuiStepIcon-text': {
        fill: theme.palette.common.white,
      },
    },

    '& .Mui-error': {
      color: theme.palette.error.main,
    },
  },

  stepLabel: {
    color: theme.palette.grey[300],

    '&.MuiStepLabel-active, &.MuiStepLabel-completed': {
      color: theme.palette.secondary.main,
    },

    '&.hasWarning': {
      color: theme.palette.warning.main,
    },

    '&.Mui-error': {
      color: theme.palette.error.main,
    },
  },
}));
