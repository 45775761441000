import { FC } from 'react';

import MuiSnackbarContent from '@material-ui/core/SnackbarContent';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiCloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';

interface Props {
  onClose: () => void;
}

const InfoBanner: FC<Props> = ({ children, onClose }) => {
  const classes = useStyles();

  return (
    <MuiSnackbarContent
      className={classes.root}
      message={children}
      action={
        <MuiIconButton className={classes.actionButton} onClick={onClose}>
          <MuiCloseIcon />
        </MuiIconButton>
      }
    />
  );
};

export default InfoBanner;
