import { AxiosResponse } from 'axios';

import { privateGateway } from '../../../config';

export const requestExportUsersList = async (): Promise<AxiosResponse> => {
  const currentLocalDate = new Date();
  const timezoneOffset = currentLocalDate.getTimezoneOffset() * -1;

  const response = await privateGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).get(`/admin/export/users?tz_minutes=${timezoneOffset}`);

  return response;
};
