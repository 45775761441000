import { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';

import ControlledPasswordTextField from 'src/components/elements/ControlledPasswordTextField';

import { FIELD_NAME } from '../../constants';

const name = FIELD_NAME.repeatPassword;

const ConfirmPassword: FC = () => {
  return (
    <Grid item>
      <Box mb={4}>
        <FormLabel component="legend">Confirm password</FormLabel>
        <ControlledPasswordTextField
          name={name}
          equalTo={FIELD_NAME.newPassword}
        />
      </Box>
    </Grid>
  );
};

export default ConfirmPassword;
