import { FC, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MuiIconThumbUp from '@material-ui/icons/ThumbUp';
import MuiIconCloudUpload from '@material-ui/icons/CloudUpload';
import MuiIconVisibility from '@material-ui/icons/Visibility';

import Button from '@quanterix-ui/core/Button';

import {
  getFileExtension,
  getFileName,
  getFileStatus,
} from 'src/utils/StringHelper';
import { showFileInBrowser } from 'src/utils/S3Helper';
import { FileStatuses } from 'src/utils/Constants';

import { CustomFile } from '../../typings';

import { getFileTypeIcon, isVideo } from './utils';
import { useStyles } from './styles';

interface Props {
  file: CustomFile;
  isEditMode: boolean;
  onFilePublish?: (name: string) => Promise<void>;
  onRequireApproval?: (name: string) => Promise<void>;
  onFileRemove?: (name: string) => Promise<void>;
}

const UploadedFile: FC<Props> = ({
  file,
  isEditMode = false,
  onFileRemove,
  onFilePublish,
  onRequireApproval,
}) => {
  const classes = useStyles();

  const [inProgress, setInProgress] = useState(false);

  const fileName = useMemo(() => {
    return file ? getFileName(file.key) : '';
  }, [file]);

  const shortFileName = useMemo(() => {
    const fileNameParts = fileName.split('.');
    return fileNameParts.slice(0, -1).join('');
  }, [fileName]);

  const fileExtension = useMemo(() => {
    return getFileExtension(fileName);
  }, [fileName]);

  const fileStatus = useMemo(() => {
    return file ? getFileStatus(file.key) : '';
  }, [file]);

  const downloadFile = useMemo(() => {
    return !showFileInBrowser(fileExtension);
  }, [fileExtension]);

  const handleRequireApproval = async () => {
    if (onRequireApproval) {
      setInProgress(true);
      await onRequireApproval(file.key);
      setInProgress(false);
    }
  };

  const handleFilePublish = async () => {
    if (onFilePublish) {
      setInProgress(true);
      await onFilePublish(file.key);
      setInProgress(false);
    }
  };

  const handleFileRemove = async () => {
    if (onFileRemove) {
      setInProgress(true);
      await onFileRemove(file.key);
      setInProgress(false);
    }
  };

  if (!isEditMode && fileStatus !== FileStatuses.Published) {
    return null;
  }

  if (!isEditMode && isVideo(fileExtension)) {
    return (
      <Box pb={2}>
        <Box pb={1}>{shortFileName}</Box>
        <video controls width="400">
          <source src={file.urlPath} type={'video/' + fileExtension} />
          Your browser does not support HTML5 video.
        </video>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={8}>
          <Grid container alignItems="center">
            <Box className={classes.fileTypeIconContainer} mr={2}>
              {inProgress && (
                <Box className={classes.fileProgressContainer}>
                  <MuiCircularProgress size={20} thickness={3} />
                </Box>
              )}
              {!inProgress && (
                <FontAwesomeIcon
                  className={classes.fileTypeIcon}
                  icon={getFileTypeIcon(fileExtension)}
                />
              )}
            </Box>
            <Link
              key={file.urlPath}
              href={file.urlPath}
              className={cx({
                [classes.italicLink]: fileStatus !== FileStatuses.Published,
              })}
              target={downloadFile ? '_self' : '_blank'}
              rel="noopener noreferrer"
              underline="none"
            >
              {shortFileName}
            </Link>
          </Grid>
        </Grid>
        <Grid item>
          <Box className={classes.gridItemHeightHolder} />
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            {isEditMode && (
              <>
                {fileStatus === FileStatuses.New && (
                  <>
                    {onRequireApproval && (
                      <IconButton size="small" onClick={handleRequireApproval}>
                        <MuiIconVisibility fontSize="default" />
                      </IconButton>
                    )}
                    {onFilePublish && (
                      <IconButton size="small" onClick={handleFilePublish}>
                        <MuiIconCloudUpload fontSize="default" />
                      </IconButton>
                    )}
                  </>
                )}
                {fileStatus === FileStatuses.Pending && onFilePublish && (
                  <IconButton size="small" onClick={handleFilePublish}>
                    <MuiIconThumbUp fontSize="default" />
                  </IconButton>
                )}
                {onFileRemove && (
                  <Box ml={2}>
                    <Button
                      className={classes.actionButton}
                      variant="text"
                      color="secondary"
                      onClick={handleFileRemove}
                    >
                      Remove
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadedFile;
