import { FC } from 'react';

import cx from 'classnames';

import MuiStepper from '@material-ui/core/Stepper';
import MuiStep from '@material-ui/core/Step';
import MuiStepLabel from '@material-ui/core/StepLabel';

import { useStyles } from './styles';

interface Props {
  steps: string[];
  activeStep: number;
  stepWithWarning?: number;
  stepWithError?: number;
}

const Stepper: FC<Props> = ({
  steps,
  activeStep,
  stepWithWarning,
  stepWithError,
}) => {
  const classes = useStyles();

  return (
    <MuiStepper alternativeLabel activeStep={activeStep}>
      {steps.map((step, index) => {
        const hasWarning = stepWithWarning === index;
        const hasError = !!stepWithError && stepWithError === index;

        return (
          <MuiStep key={step} className={cx(classes.step, { hasWarning })}>
            <MuiStepLabel
              classes={{
                label: cx(classes.stepLabel, {
                  hasWarning,
                }),
              }}
              error={hasError && !hasWarning}
            >
              {step}
            </MuiStepLabel>
          </MuiStep>
        );
      })}
    </MuiStepper>
  );
};

export default Stepper;
