import { makeStyles, Theme } from '@material-ui/core/styles';

import { FOOTER, HEADER, SUPPORT_SECTION } from 'src/utils/constants/app';

interface StyleProps {
  isAdvanced: boolean;
  isProtected: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    height: '100vh',
    overflowY: 'hidden',
  },
  sidebar: {
    zIndex: 2,
  },
  contentWrapper: {
    height: ({ isAdvanced, isProtected }) => {
      const offsetY =
        isAdvanced && !isProtected
          ? HEADER.height + SUPPORT_SECTION.height
          : HEADER.height;
      return `calc(100vh - ${offsetY}px) !important`;
    },
  },
  content: {
    minHeight: ({ isAdvanced, isProtected }) => {
      const offsetY =
        isAdvanced && !isProtected
          ? HEADER.height + FOOTER.height + SUPPORT_SECTION.height
          : HEADER.height + FOOTER.height;
      return `calc(100vh - ${offsetY}px) !important`;
    },
    padding: 45,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    },
  },
}));
