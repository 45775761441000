import { useCallback, useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';

type IdentityService = CognitoIdentityServiceProvider | null;

interface UseIdentityServiceResult {
  identityService: IdentityService;
  identityServiceInit: () => Promise<void>;
}

export const useIdentityService = (): UseIdentityServiceResult => {
  const [identityService, setIdentityService] = useState<IdentityService>(null);

  const identityServiceInit = useCallback(async () => {
    try {
      const credentials = await Auth.currentCredentials();
      const session = await Auth.currentSession();
      const sessionToken = session.getAccessToken().getJwtToken();

      const cognitoIdentityServiceEntity = new CognitoIdentityServiceProvider({
        region: process.env.REACT_APP_IDENTITY_POOL_REGION,
        sessionToken,
        credentials,
      });

      setIdentityService(cognitoIdentityServiceEntity);
    } catch (error) {
      setIdentityService(null);
    }
  }, []);

  useEffect(() => {
    identityServiceInit();
  }, [identityServiceInit]);

  return { identityService, identityServiceInit };
};
