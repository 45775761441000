import { memo, useEffect, useMemo, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import TextField from '@quanterix-ui/core/TextField';
import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import {
  parseAddress,
  parseInstruments,
  parseUserAttribute,
} from 'src/utils/CognitoIdentityHelper';
import { validatePhone } from 'src/utils/StringHelper';
import { getDefaultInstruments } from 'src/utils/helpers/accelerator';

import BaseUserHeader from './user-header/BaseUserHeader';
import { DEFAULT_USER_FORM_VALUES } from './constants';
import { useStyles } from './styles';

const BaseUserEdit = ({
  isNew = false,
  userAttributes,
  status = undefined, // TODO: make optional after migration to TS
  cancelEdit = undefined, // TODO: make optional after migration to TS
  saveUser = undefined, // TODO: make optional after migration to TS
  onConfirmClicked = undefined, // TODO: make optional after migration to TS
}) => {
  const classes = useStyles();

  const { isAdmin } = useCognito();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: DEFAULT_USER_FORM_VALUES });

  const [instruments, setInstruments] = useState(getDefaultInstruments());

  const fullName = useMemo(() => {
    return `${parseUserAttribute(
      userAttributes,
      'given_name'
    )} ${parseUserAttribute(userAttributes, 'family_name')}`;
  }, [userAttributes]);

  const isEmailVerified = useMemo(() => {
    return parseUserAttribute(userAttributes, 'email_verified') === true;
  }, [userAttributes]);

  const defaultAcceleratorServices = useMemo(() => {
    const accelerator_services = parseUserAttribute(
      userAttributes,
      'custom:accelerator_services'
    );

    return typeof accelerator_services === 'string'
      ? accelerator_services === 'true'
      : accelerator_services;
  }, [userAttributes]);

  const defaultInstruments = useMemo(() => {
    return parseInstruments(
      parseUserAttribute(userAttributes, 'custom:instrument'),
      parseUserAttribute(userAttributes, 'custom:serial_number')
    );
  }, [userAttributes]);

  const userFields = useMemo(() => {
    return [
      { key: 'email', value: parseUserAttribute(userAttributes, 'email') },
      {
        key: 'given_name',
        value: parseUserAttribute(userAttributes, 'given_name'),
      },
      {
        key: 'family_name',
        value: parseUserAttribute(userAttributes, 'family_name'),
      },
      {
        key: 'company',
        value: parseUserAttribute(userAttributes, 'custom:company'),
      },
      {
        key: 'website',
        value: parseUserAttribute(userAttributes, 'website'),
      },
      {
        key: 'phone_number',
        value: parseUserAttribute(userAttributes, 'phone_number'),
      },
      {
        key: 'address_street',
        value: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'street_address'
        ),
      },
      {
        key: 'address_street2',
        value: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'street_address2'
        ),
      },
      {
        key: 'address_locality',
        value: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'locality'
        ),
      },
      {
        key: 'address_region',
        value: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'region'
        ),
      },
      {
        key: 'address_postal_code',
        value: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'postal_code'
        ),
      },
      {
        key: 'address_country',
        value: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'country'
        ),
      },
      {
        key: 'accelerator_services',
        value: defaultAcceleratorServices,
      },
      { key: 'instrums', value: [] },
    ];
  }, [userAttributes, defaultAcceleratorServices]);

  useEffect(() => {
    if (userAttributes?.length !== 0) {
      setInstruments(defaultInstruments);

      userFields.forEach(({ key, value }) => {
        setValue(key, value);
      });
    }
  }, [
    userAttributes,
    userFields,
    defaultInstruments,
    defaultAcceleratorServices,
    setValue,
  ]);

  const combineAddress = (fields) => {
    return JSON.stringify({
      street_address: fields.address_street,
      street_address2: fields.address_street2,
      locality: fields.address_locality,
      region: fields.address_region,
      postal_code: fields.address_postal_code,
      country: fields.address_country,
    });
  };

  const getSerials = (instruments) => {
    return JSON.stringify(
      instruments.filter((x) => x.Selected).map((x) => x.Serial)
    );
  };

  const getInstrumentCodes = (instruments) => {
    return JSON.stringify(
      instruments.filter((x) => x.Selected).map((x) => x.Instrument)
    );
  };

  const combineAttributesFromState = (data) => {
    const userAttributes = [];

    userAttributes.push({ Name: 'website', Value: data.website });
    userAttributes.push({ Name: 'address', Value: combineAddress(data) });
    userAttributes.push({ Name: 'given_name', Value: data.given_name });
    userAttributes.push({
      Name: 'custom:instrument',
      Value: getInstrumentCodes(instruments),
    });
    userAttributes.push({ Name: 'custom:company', Value: data.company });
    userAttributes.push({
      Name: 'custom:accelerator_services',
      Value: String(data.accelerator_services),
    });
    userAttributes.push({
      Name: 'custom:serial_number',
      Value: getSerials(instruments),
    });
    userAttributes.push({
      Name: 'phone_number',
      Value:
        data.phone_number.indexOf('+') === 0
          ? data.phone_number
          : `+${data.phone_number}`,
    });
    userAttributes.push({ Name: 'family_name', Value: data.family_name });

    if (isNew) {
      userAttributes.push({ Name: 'email', Value: data.email });
    }

    return userAttributes;
  };

  const handleSave = (data) => {
    const userAttributes = combineAttributesFromState(data);
    saveUser(userAttributes);
  };

  const instrumentChanged = (instrument, selected) => {
    const newInstruments = instruments.map((object) => ({ ...object }));
    const index = newInstruments.findIndex((x) => x.Instrument === instrument);

    newInstruments[index].Selected = selected;

    setInstruments(newInstruments);
    trigger('instrums');
  };

  const serialChanged = (e) => {
    const value = e.target.value;
    const instrument = e.target.id.substring(7);
    const newInstruments = instruments.map((object) => ({ ...object }));
    const index = newInstruments.findIndex((x) => x.Instrument === instrument);

    newInstruments[index].Serial = value;

    setInstruments(newInstruments);
  };

  const validateInstruments = () => {
    const { accelerator_services } = getValues();
    const isInstrumentsSelected =
      instruments.filter((v) => v.Selected).length > 0;

    return (
      accelerator_services ||
      isInstrumentsSelected ||
      'At least one instrument should be checked'
    );
  };

  const renderInstrument = (instrument) => {
    const { ref } = register('instrums', { validate: validateInstruments });

    return (
      <Grid
        key={instrument.Instrument}
        container
        direction="column"
        className={classes.fieldset}
      >
        <Grid item>
          <FormControlLabel
            label={instrument.Instrument}
            control={
              <Checkbox
                checked={instrument.Selected}
                color="primary"
                inputRef={ref}
                onChange={() => {
                  instrumentChanged(
                    instrument.Instrument,
                    !instrument.Selected
                  );
                }}
              />
            }
          />
        </Grid>
        <Grid item>
          <FormLabel>Serial Number</FormLabel>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            disabled={!instrument.Selected}
            id={'Serial_' + instrument.Instrument}
            value={instrument.Serial}
            onChange={serialChanged}
          />
        </Grid>
      </Grid>
    );
  };

  if (!userAttributes && !isNew) {
    return <LinearProgress />;
  }

  return (
    <Container maxWidth="xl">
      <Grid container>
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleSubmit(handleSave)}
        >
          <BaseUserHeader
            isNew={isNew}
            isAdmin={isAdmin}
            status={status}
            mailVerified={isEmailVerified}
            confirmUser={onConfirmClicked}
            fullName={fullName}
            controlButtons={
              <>
                <Box ml={2}>
                  <Button variant="outlined" onClick={cancelEdit}>
                    Cancel
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button type="submit">Save</Button>
                </Box>
              </>
            }
          />
          <Grid container className={classes.gridMainContainer}>
            <Grid item xs={12} lg={4} className={classes.gridColumn}>
              <Grid container direction="column" className={classes.fieldset}>
                <Typography gutterBottom variant="h6">
                  User Information
                </Typography>
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel required>First Name</FormLabel>
                </Grid>
                <Grid item>
                  <ControlledTextField
                    fullWidth
                    name="given_name"
                    control={control}
                    rules={{
                      required: true || 'This field is required',
                    }}
                    helperText={errors.given_name?.message}
                  />
                </Grid>
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel required>Last Name</FormLabel>
                </Grid>
                <ControlledTextField
                  fullWidth
                  name="family_name"
                  control={control}
                  rules={{
                    required: true || 'This field is required',
                  }}
                  helperText={errors.family_name?.message}
                />
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel required>Company</FormLabel>
                </Grid>
                <ControlledTextField
                  fullWidth
                  name="company"
                  control={control}
                  rules={{
                    required: true || 'This field is required',
                  }}
                  helperText={errors.company?.message}
                />
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel required>Email</FormLabel>
                </Grid>
                <ControlledTextField
                  fullWidth
                  name="email"
                  disabled={!isNew}
                  control={control}
                  rules={{
                    required: true || 'This field is required',
                    pattern: {
                      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g,
                      message: 'Wrong email format',
                    },
                  }}
                  helperText={errors.email?.message}
                />
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel>Website</FormLabel>
                </Grid>
                <ControlledTextField
                  fullWidth
                  name="website"
                  control={control}
                />
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel required>Phone</FormLabel>
                </Grid>
                <Controller
                  name="phone_number"
                  control={control}
                  rules={{ validate: validatePhone }}
                  render={({ field: { value, onChange } }) => (
                    <PhoneInput
                      enableSearch
                      inputStyle={{
                        width: '100%',
                        borderRadius: 4,
                        height: 40,
                      }}
                      disableCountryGuess={false}
                      placeholder="Enter phone number"
                      value={value}
                      onChange={(value) => onChange(value)}
                    />
                  )}
                />
                {errors.phone_number?.message && (
                  <FormHelperText error>
                    {errors.phone_number?.message}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.gridColumn}>
              <Grid container direction="column" className={classes.fieldset}>
                <Typography gutterBottom variant="h6">
                  Address
                </Typography>
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel required>Address 1</FormLabel>
                </Grid>
                <ControlledTextField
                  fullWidth
                  name="address_street"
                  control={control}
                  rules={{
                    required: true || 'This field is required',
                  }}
                  helperText={errors.address_street?.message}
                />
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel>Address 2</FormLabel>
                </Grid>
                <ControlledTextField
                  fullWidth
                  name="address_street2"
                  control={control}
                />
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel required>City</FormLabel>
                </Grid>
                <ControlledTextField
                  fullWidth
                  name="address_locality"
                  control={control}
                  rules={{
                    required: true || 'This field is required',
                  }}
                  helperText={errors.address_locality?.message}
                />
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel required>State/Locality</FormLabel>
                </Grid>
                <ControlledTextField
                  fullWidth
                  name="address_region"
                  control={control}
                  rules={{
                    required: true || 'This field is required',
                  }}
                  helperText={errors.address_region?.message}
                />
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel required>Zip/Postal Code</FormLabel>
                </Grid>
                <ControlledTextField
                  fullWidth
                  name="address_postal_code"
                  control={control}
                  rules={{
                    required: true || 'This field is required',
                  }}
                  helperText={errors.address_postal_code?.message}
                />
              </Grid>
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormLabel required>Country</FormLabel>
                </Grid>
                <ControlledTextField
                  fullWidth
                  name="address_country"
                  control={control}
                  rules={{
                    required: true || 'This field is required',
                  }}
                  helperText={errors.address_country?.message}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.gridColumn}>
              <Grid container direction="column" className={classes.fieldset}>
                <Typography gutterBottom variant="h6">
                  Instrument
                </Typography>
              </Grid>
              {instruments.map(renderInstrument)}
              <Grid container direction="column" className={classes.fieldset}>
                <Grid item>
                  <FormControlLabel
                    label="Accelerator Services"
                    control={
                      <Controller
                        name="accelerator_services"
                        control={control}
                        render={() => (
                          <Checkbox
                            checked={getValues('accelerator_services')}
                            color="primary"
                            onChange={(e) => {
                              setValue(
                                'accelerator_services',
                                e.target.checked
                              );
                              trigger('instrums');
                            }}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
              </Grid>
              {!!errors.instrums && (
                <Grid container direction="column" className={classes.fieldset}>
                  <FormHelperText error={!!errors.instrums}>
                    {errors.instrums.message}
                  </FormHelperText>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Container>
  );
};

export default memo(BaseUserEdit);
