import { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';

import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import Input from '@quanterix-ui/core/Input';
import Button from '@quanterix-ui/core/Button';

import { useCognito, COGNITO_CHALLENGE_NAME } from 'src/aws/Cognito';
import ControlledEmailTextField from 'src/components/elements/ControlledEmailTextField';
import ControlledPasswordTextField from 'src/components/elements/ControlledPasswordTextField';

import { useStyles } from './styles';

const showForgotPassMessage = (() => {
  const date = new Date('10/30/2020');
  const today = new Date();

  return (
    today.getDate() <= date.getDate() &&
    today.getMonth() <= date.getMonth() &&
    today.getFullYear() <= date.getFullYear()
  );
})();

const SignIn = () => {
  const classes = useStyles();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    user,
    isLoading,
    isEmailVerified,
    isAuthenticated,
    signIn,
    completeNewPassword,
  } = useCognito();

  const [code, setCode] = useState('');

  const signInFormMethods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = signInFormMethods;

  const newPasswordFormMethods = useForm({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });

  const {
    handleSubmit: newPassFormHandleSubmit,
    formState: { errors: newPassFormErrors },
  } = newPasswordFormMethods;

  useEffect(() => {
    if (user?.challengeName !== COGNITO_CHALLENGE_NAME.newPasswordRequired) {
      if (isAuthenticated && isEmailVerified) {
        history.push('/');
      }
  
      if (isAuthenticated && !isEmailVerified) {
        history.push('/verify-email');
      }
    }
  }, [isAuthenticated, isEmailVerified]);

  useEffect(() => {
    setCode('');
  }, [history]);

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const submitNewPass = async ({ newPassword }) => {
    await completeNewPassword({
      user,
      newPassword,
      attributes: [],
    });

    enqueueSnackbar('Your password has been changed', {
      variant: 'success',
    });
  };

  const submitSignIn = async () => {
    await Auth.confirmSignIn(
      user,
      code,
      user?.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    );

    history.push('/');
  };

  const handleSignIn = ({ email, password }) => {
    signIn({ email, password });
  };

  if (isAuthenticated) {
    if (
      user?.challengeName === COGNITO_CHALLENGE_NAME.smsMFA ||
      user?.challengeName === COGNITO_CHALLENGE_NAME.softwareTokenMFA
    ) {
      return (
        <>
          <div>Login to Quanterix Customer Portal</div>
          <div>Secret code to confirm SignIn</div>
          <Input onChange={handleCodeChange} />
          <Button onClick={submitSignIn}>Confirm</Button>
        </>
      );
    }

    if (user?.challengeName === COGNITO_CHALLENGE_NAME.newPasswordRequired) {
      return (
        <FormProvider {...newPasswordFormMethods}>
          <div className={classes.root}>
            <Typography gutterBottom variant="subtitle1">
              <b>Login</b> to Quanterix Customer Portal
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              Your password should be changed. Please choose new password
            </Typography>
            <form
              autoComplete="off"
              onSubmit={newPassFormHandleSubmit(submitNewPass)}
            >
              <div className={classes.formControl}>
                <FormLabel component="legend">New Password</FormLabel>
                <FormControl
                  fullWidth
                  size="small"
                  error={!!newPassFormErrors.newPassword}
                >
                  <ControlledPasswordTextField
                    withMaxLength
                    withPattern
                    name="newPassword"
                  />
                  <FormHelperText>
                    Use 10 or more characters with uppercase and lowercase
                    letters, numbers and special characters (e.g. %,$)
                  </FormHelperText>
                </FormControl>
              </div>
              <div className={classes.confirmPassword}>
                <FormLabel component="legend">Confirm Password</FormLabel>
                <FormControl
                  fullWidth
                  error={!!newPassFormErrors.confirmPassword}
                  size="small"
                >
                  <ControlledPasswordTextField
                    name="confirmPassword"
                    equalTo="newPassword"
                  />
                </FormControl>
              </div>
              <div className={classes.container}>
                <div className={classes.wrapper}>
                  <Button
                    className={classes.button}
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </FormProvider>
      );
    }
  }

  return (
    <FormProvider {...signInFormMethods}>
      <div className={classes.root}>
        <Typography gutterBottom variant="subtitle1">
          <b>Login</b> to Quanterix Customer Portal
        </Typography>
        <form autoComplete="off" onSubmit={handleSubmit(handleSignIn)}>
          <div className={classes.formControl}>
            <FormLabel component="legend">Email</FormLabel>
            <ControlledEmailTextField required name="email" />
          </div>
          <div className={classes.formControl}>
            <FormLabel component="legend">Password</FormLabel>
            <FormControl fullWidth size="small" error={!!errors.password}>
              <ControlledPasswordTextField name="password" />
            </FormControl>
          </div>
          <div className={classes.container}>
            <div className={classes.wrapper}>
              <Button
                className={classes.button}
                type="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                Log In
              </Button>
            </div>
            <div className={classes.boldLink}>
              <Link to="/forgot-password">Forgot password?</Link>
            </div>
          </div>
          {showForgotPassMessage && (
            <p>
              <Typography
                variant="caption"
                className={classes.forgotPassMessage}
              >
                We updated the Customer Portal on October 19, 2020. If you have
                an existing portal account and have not logged in since then,
                you must reset your password in order to login. Select "Forgot
                Password" above. If you do not receive an email containing login
                instructions within 30 minutes, please click Register at the top
                of the page
              </Typography>
            </p>
          )}
        </form>
      </div>
    </FormProvider>
  );
};

export default SignIn;
