import { FC } from 'react';

import { Route, RouteProps } from 'react-router-dom';

import DefaultLayout from 'src/layouts/default/DefaultLayout';

const PublicRoute: FC<RouteProps> = ({ children, ...restProps }) => {
  return (
    <Route {...restProps}>
      <DefaultLayout>{children}</DefaultLayout>
    </Route>
  );
};

export default PublicRoute;
