import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 720,
    borderRadius: 10,
    padding: 30,
  },
  title: {
    '& .MuiTypography-root': {
      fontWeight: 400,
    },
  },
  actions: {
    justifyContent: 'space-evenly',
    paddingTop: 20,
  },
}));
