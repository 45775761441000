import { useState } from 'react';

import { Link } from 'react-router-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import PhoneInput from 'react-phone-input-2';
import cx from 'classnames';
import 'react-phone-input-2/lib/high-res.css';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import MuiButton from '@material-ui/core/Button';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import TextField from '@quanterix-ui/core/TextField';
import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import { validatePhone } from 'src/utils/StringHelper';
import { getDefaultInstruments } from 'src/utils/helpers/accelerator';
import ControlledEmailTextField from 'src/components/elements/ControlledEmailTextField';
import ControlledPasswordTextField from 'src/components/elements/ControlledPasswordTextField';

import { DEFAULT_FORM_VALUES } from './constants';
import { useStyles } from './styles';

const SignUp = () => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { isLoading, signUp } = useCognito();

  const [instruments, setInstruments] = useState(getDefaultInstruments());

  const formMethods = useForm({ defaultValues: DEFAULT_FORM_VALUES });

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = formMethods;

  const getSerials = (instruments) => {
    return JSON.stringify(
      instruments.filter((x) => x.Selected).map((x) => x.Serial)
    );
  };

  const getInstrumentCodes = (instruments) => {
    return JSON.stringify(
      instruments.filter((x) => x.Selected).map((x) => x.Instrument)
    );
  };

  const handleSignUp = async (data) => {
    const address = {
      street_address: data.address_street,
      street_address2: data.address_street2,
      locality: data.city,
      region: data.state,
      postal_code: data.address_postal_code,
      country: data.address_country,
    };

    const userAttributes = {
      given_name: data.firstName,
      family_name: data.lastName,
      email: data.email,
      'custom:instrument': getInstrumentCodes(instruments),
      'custom:serial_number': getSerials(instruments),
      'custom:accelerator_services': JSON.stringify(data.accelerator_services),
      address: JSON.stringify(address),
      'custom:company': data.company,
      website: data.website,
      phone_number:
        data.phone_number.indexOf('+') === 0
          ? data.phone_number
          : `+${data.phone_number}`,
    };

    const clientMetadata = {
      sourceEmailAddress: process.env.REACT_APP_SOURCE_EMAIL_ADDRESS,
      approversEmailAddress: process.env.REACT_APP_APPROVERS_EMAIL_ADDRESS,
    };

    try {
      await signUp({
        email: data.email,
        password: data.password,
        attributes: userAttributes,
        clientMetadata,
      });
  
      enqueueSnackbar(
        'Customer Portal accounts are approved Monday-Friday 8am-5pm EST in order to ensure access remains exclusive for Quanterix users. We appreciate your patience in advance. Please email techsupport@quanterix.com if your registration is urgent.',
        {
          variant: 'success',
          persist: true,
          action: (key) => (
            <MuiButton
              variant="text"
              color="inherit"
              size="small"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              GOT IT
            </MuiButton>
          ),
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeAcceleratorServices = (event) => {
    const { checked } = event.target;
    setValue('accelerator_services', checked);
    trigger('instruments');
  }

  const instrumentChanged = (instrument, selected) => {
    const newInstruments = [...instruments];
    const index = newInstruments.findIndex((x) => x.Instrument === instrument);
    newInstruments[index].Selected = selected;
    setInstruments(newInstruments);
    trigger('instruments');
  };

  const serialChanged = (event) => {
    const value = event.target.value;
    const instrument = event.target.id.substring(7);
    const newInstruments = [...instruments];
    const index = newInstruments.findIndex((x) => x.Instrument === instrument);
    newInstruments[index].Serial = value;
    setInstruments(newInstruments);
  };

  const validateInstruments = () => {
    const isInstrumentsSelected =
      instruments.filter((v) => v.Selected).length > 0;
    const { accelerator_services } = getValues();

    return accelerator_services || isInstrumentsSelected || 'At least one instrument should be checked';
  };

  const renderInstrument = (instrument) => {
    const { ref } = register('instruments', { validate: { validateInstruments } });

    return (
      <div
        key={instrument.Instrument}
        style={{ paddingLeft: 10, paddingBottom: 10 }}
      >
        <FormControlLabel
          label={instrument.Instrument}
          control={
            <Checkbox
              checked={instrument.Selected}
              inputRef={ref}
              onChange={() =>
                instrumentChanged(instrument.Instrument, !instrument.Selected)
              }
            />
          }
        />
        <TextField
          fullWidth
          id={'Serial_' + instrument.Instrument}
          placeholder={`${instrument.Instrument} serial number`}
          disabled={!instrument.Selected}
          value={instrument.Serial}
          onChange={serialChanged}
        />
      </div>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <div className={classes.root}>
        <Typography gutterBottom variant="subtitle1">
          <b>Register</b> in Quanterix Customer Portal
        </Typography>
        <form autoComplete="off" onSubmit={handleSubmit(handleSignUp)}>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              First Name
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="firstName"
              control={control}
              rules={{
                required: true,
              }}
              helperText={errors.firstName?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Last Name
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="lastName"
              control={control}
              rules={{
                required: true,
              }}
              helperText={errors.lastName?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Company
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="company"
              control={control}
              rules={{
                required: true,
              }}
              helperText={errors.company?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Email
            </FormLabel>
            <ControlledEmailTextField required name="email" />
          </div>
          <div className={classes.formControl}>
            <FormLabel component="legend">Website</FormLabel>
            <ControlledTextField fullWidth name="website" control={control} />
          </div>
          <div className={cx(classes.formControl, classes.phoneNumberWrapper)}>
            <FormLabel required component="legend">
              Phone
            </FormLabel>
            <Controller
              name="phone_number"
              control={control}
              rules={{ validate: validatePhone }}
              render={({ field: { value, onChange } }) => (
                <PhoneInput
                  inputStyle={{ width: '100%', borderRadius: 10, height: 40 }}
                  dropdownStyle={{ borderRadius: 10 }}
                  disableCountryGuess={false}
                  placeholder="Enter phone number"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {errors.phone_number && (
              <FormHelperText error>
                {errors.phone_number.message}
              </FormHelperText>
            )}
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Address 1
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="address_street"
              control={control}
              rules={{
                required: true,
              }}
              helperText={errors.address_street?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel component="legend">Address 2</FormLabel>
            <ControlledTextField
              fullWidth
              name="address_street2"
              control={control}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              City
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="city"
              control={control}
              rules={{
                required: true,
              }}
              helperText={errors.city?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              State/Locality
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="state"
              control={control}
              rules={{
                required: true,
              }}
              helperText={errors.state?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Zip/Postal Code
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="address_postal_code"
              control={control}
              rules={{
                required: true,
              }}
              helperText={errors.address_postal_code?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Country
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="address_country"
              control={control}
              rules={{
                required: true,
              }}
              helperText={errors.address_country?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Instrument
            </FormLabel>
            {instruments.map(renderInstrument)}
            <div style={{ paddingLeft: 10, paddingBottom: 10 }}>
              <FormControlLabel
                label="Accelerator Services"
                control={
                  <Controller
                    name="accelerator_services"
                    control={control}
                    render={() => (
                      <Checkbox onChange={handleChangeAcceleratorServices} />
                    )}
                  />
                }
              />
            </div>
            <FormHelperText error={!!errors.instruments}>
              {errors.instruments?.message}
            </FormHelperText>
          </div>
          <div className={classes.password}>
            <FormLabel required component="legend">
              Password
            </FormLabel>
            <FormControl fullWidth size="small">
              <ControlledPasswordTextField
                withMaxLength
                withPattern
                name="password"
              />
              <FormHelperText>
                Use 10 or more characters with uppercase and lowercase letters,
                numbers and special characters (e.g. %,$)
              </FormHelperText>
            </FormControl>
          </div>
          <div className={classes.password}>
            <FormLabel required component="legend">
              Confirm Password
            </FormLabel>
            <FormControl
              fullWidth
              error={!!errors.confirmPassword}
              size="small"
            >
              <ControlledPasswordTextField
                withMaxLength
                withPattern
                name="confirmPassword"
                equalTo="password"
              />
            </FormControl>
          </div>
          <p>
            <Button
              className={classes.button}
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Register
            </Button>
          </p>
          <p>
            <Typography variant="caption">
              By submitting this form, you agree to Quanterix’s{' '}
              <Link className={classes.boldLink} to="/legal/terms-conditions">
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link className={classes.boldLink} to="/legal/privacy-policy">
                Privacy Policy
              </Link>
              . In addition, you agree to receive information regarding
              Quanterix products and services. You will be able to unsubscribe
              at any time.
            </Typography>
          </p>
        </form>
      </div>
    </FormProvider>
  );
};

export default SignUp;
