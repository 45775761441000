import { useHistory } from 'react-router-dom';

import BaseUserEdit from 'src/components/common/users/BaseUserEdit';
import {
  createUserByAdmin,
  parseUserAttribute,
} from 'src/utils/CognitoIdentityHelper';

const UserNew = () => {
  const history = useHistory();

  const cancelEdit = () => {
    history.push('/admin/users');
  };

  const saveUser = async (attributes) => {
    const username = parseUserAttribute(attributes, 'email');
    const request = await createUserByAdmin(username, attributes);
    request
      .on('success', function (response) {
        history.push('/admin/users');
      })
      .on('error', function (response) {
        alert('User saving failed. ' + request.response.error.message);
      });
  };

  return (
    <BaseUserEdit
      isNew
      userAttributes={[]}
      cancelEdit={cancelEdit}
      saveUser={saveUser}
    />
  );
};

export default UserNew;
