import { useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import { jsonParseSafe } from 'src/utils/ParseHelper';
import {
  parseAddress,
  parseUserAttribute,
} from 'src/utils/CognitoIdentityHelper';
import { IOSSwitch } from 'src/components/common/ios-switch/IOSSwitch';

import BaseUserHeader from './user-header/BaseUserHeader';
import { ADDRESS_FILEDS, PERSONAL_INFO_FIELDS } from './constants';
import { useStyles } from './styles';
import './BaseUser.css';

const BaseUserView = ({
  disabled = false,
  profile = false,
  userAttributes,
  status = undefined, // TODO: make optional after migration to TS
  onConfirm = undefined, // TODO: make optional after migration to TS
  onEnable = undefined, // TODO: make optional after migration to TS
  onEdit = undefined, // TODO: make optional after migration to TS
}) => {
  const classes = useStyles();

  const history = useHistory();

  const { isAdmin } = useCognito();

  const fullName = useMemo(() => {
    return `${parseUserAttribute(
      userAttributes,
      'given_name'
    )} ${parseUserAttribute(userAttributes, 'family_name')}`;
  }, [userAttributes]);

  const isEmailVerified = useMemo(() => {
    return parseUserAttribute(userAttributes, 'email_verified') === true;
  }, [userAttributes]);

  const isLoading = useMemo(() => {
    return !userAttributes || (Array.isArray(userAttributes) && userAttributes.length === 0);
  }, [userAttributes]);

  const renderInstrument = (instrument, serialNumbers, i) => {
    const serialNumber = serialNumbers.length > i ? serialNumbers[i] : '';

    return (
      <ListItem key={instrument} disableGutters>
        <ListItemText
          className={classes.instrumentListItemText}
          primary={instrument}
          secondary={serialNumber}
        />
      </ListItem>
    );
  };

  const renderInstruments = () => {
    const instrumentsStr = parseUserAttribute(
      userAttributes,
      'custom:instrument'
    );
    const serialNumbersStr = parseUserAttribute(
      userAttributes,
      'custom:serial_number'
    );

    if (instrumentsStr.length === 0) {
      return '';
    }

    const instruments = jsonParseSafe(instrumentsStr, []);
    const serialNumbers = jsonParseSafe(serialNumbersStr, []);

    return instruments.map((x, i) => renderInstrument(x, serialNumbers, i));
  };

  const renderAcceleratorServicesField = () => {
    const accelerator_services = parseUserAttribute(
      userAttributes,
      'custom:accelerator_services'
    );
    const isAcceleratorServices =
      typeof accelerator_services === 'string'
        ? accelerator_services === 'true'
        : accelerator_services;

    if (!isAcceleratorServices) {
      return null;
    }

    return (
      <ListItem disableGutters>
        <ListItemText>
          <Typography className={classes.fieldsetValue}>Accelerator Services</Typography>
        </ListItemText>
      </ListItem>
    );
  };

  const renderField = (label, attributeName) => {
    const attributeValue = parseUserAttribute(userAttributes, attributeName);

    return (
      <ListItem key={attributeName} disableGutters>
        <ListItemText>
          <Grid container>
            <Grid item xs={4}>
              <Typography>{label}:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.fieldsetValue}>{attributeValue}</Typography>
            </Grid>
          </Grid>
        </ListItemText>
      </ListItem>
    );
  };

  const renderAddressField = (label, attributeName) => (
    <ListItem key={attributeName} disableGutters>
      <ListItemText>
        <Grid container>
          <Grid item xs={4}>
            <Typography>{label}:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.fieldsetValue}>
              {parseAddress(
                parseUserAttribute(userAttributes, 'address'),
                attributeName
              )}
            </Typography>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );

  const handleGoToProfileChangePassword = () => {
    history.push('/profile/change-password');
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Container maxWidth="xl">
      <Grid container>
        <BaseUserHeader
          confirmUser={onConfirm}
          status={status}
          mailVerified={isEmailVerified}
          isAdmin={isAdmin}
          withDivider={!profile && isAdmin}
          fullName={fullName}
          controlButtons={
            <>
              {profile && (
                <Box ml={2}>
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={handleGoToProfileChangePassword}
                  >
                    Change Password
                  </Button>
                </Box>
              )}
              <Box ml={2}>
                <Button onClick={onEdit}>Edit</Button>
              </Box>
            </>
          }
        />
        <Grid container className={classes.gridMainContainer}>
          <Grid item xs={12} lg={4} className={classes.gridColumn}>
            <Grid container direction="column" className={classes.fieldset}>
              <Typography gutterBottom variant="h6">
                User Information
              </Typography>
            </Grid>
            <List disablePadding>
              {!profile && (
                <ListItem disableGutters>
                  <ListItemText>
                    <Grid container>
                      <Grid item xs={12} sm={4}>
                        <Typography>Status:</Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Grid container alignItems="center">
                          <IOSSwitch
                            checked={!disabled}
                            onChange={onEnable}
                          />
                          <Box ml={2}>
                            {disabled ? (
                              <Typography component="span" color="textPrimary" className={classes.switchLabel}>Inactive</Typography>
                            ) : (
                              <Typography component="span" color="primary" className={classes.switchLabel}>Active</Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItemText>
                </ListItem>
              )}
              {PERSONAL_INFO_FIELDS.map((x) =>
                renderField(x.Label, x.Attribute)
              )}
            </List>
          </Grid>
          <Grid item xs={12} lg={4} className={classes.gridColumn}>
            <Grid container direction="column" className={classes.fieldset}>
              <Typography gutterBottom variant="h6">
                Address
              </Typography>
            </Grid>
            <List disablePadding>
              {ADDRESS_FILEDS.map((x) =>
                renderAddressField(x.Label, x.Attribute)
              )}
            </List>
          </Grid>
          <Grid item xs={12} lg={4} className={classes.gridColumn}>
            <Grid container direction="column" className={classes.fieldset}>
              <Typography gutterBottom variant="h6">
                Instrument
              </Typography>
            </Grid>
            <List disablePadding>
              {renderAcceleratorServicesField()}
              {renderInstruments()}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BaseUserView;
