import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';

import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import ControlledEmailTextField from 'src/components/elements/ControlledEmailTextField';

import { useStyles } from '../../styles';

const CodeVerificationForm = ({ onCodeSubmitted }) => {
  const classes = useStyles();

  const { isLoading, forgotPassword } = useCognito();

  const formMethods = useForm({
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit } = formMethods;

  const handleCodeSubmit = async ({ email }) => {
    try {
      await forgotPassword({ email });
      onCodeSubmitted({ email });
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <div className={classes.root}>
        <Typography gutterBottom variant="subtitle1">
          Please enter your email
        </Typography>
        <form onSubmit={handleSubmit(handleCodeSubmit)}>
          <div className={classes.formControl}>
            <FormLabel component="legend">Email</FormLabel>
            <ControlledEmailTextField required name="email" />
          </div>
          <div className={classes.container}>
            <div className={classes.wrapper}>
              <Button
                className={classes.button}
                type="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                Send Verification
              </Button>
            </div>
            <div>
              <Link className={classes.boldLink} to="/signin">
                Go back to Login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default CodeVerificationForm;
