import { FC } from 'react';

import Grid from '@material-ui/core/Grid';

import {
  QUANTERIX_TECH_EMAIL,
  SUPPORT_PHONE_NUMBER,
} from 'src/utils/constants/contacts';

import './SupportSection.css';

const SupportSection: FC = () => (
  <Grid container wrap="wrap" className="SupportSection">
    <Grid item xs={5} className="SupportSectionText">
      <span>Contact Technical Support: </span>
      <a href="tel:++19784352030">{SUPPORT_PHONE_NUMBER}</a>
      <br />
      <span>Email Us: </span>
      <a href={`mailto:${QUANTERIX_TECH_EMAIL}`}>{QUANTERIX_TECH_EMAIL}</a>
    </Grid>
    <Grid item xs={7}>
      <div id="searchBarHolder" />
    </Grid>
  </Grid>
);

export default SupportSection;
