import { FC } from 'react';

import ThemeProvider from '@quanterix-ui/core/ThemeProvider';

import Router from 'src/router';
import CognitoProvider from 'src/aws/Cognito';
import ReactQueryProvider from 'src/config/ReactQueryProvider';
import SnackbarProvider from 'src/config/SnackbarProvider';
import PortalContextProvider from 'src/state/context/PortalContext';

const App: FC = () => {
  return (
    <ThemeProvider theme="light">
      <SnackbarProvider>
        <ReactQueryProvider>
          <CognitoProvider>
            <PortalContextProvider>
              <Router />
            </PortalContextProvider>
          </CognitoProvider>
        </ReactQueryProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
