import { Auth } from 'aws-amplify';

import { getLink } from 'src/utils/S3Helper';
import { getFileExtension } from 'src/utils/StringHelper';

export const search = async (query, options) => {
  const session = await Auth.currentSession();

  const { accessKeyId, secretAccessKey, sessionToken } =
    await Auth.currentCredentials();
  const accessToken = session.getAccessToken().getJwtToken();

  const body = {
    searchString: query,
    fullText: options.searchWithin === 'content',
    folders: options.searchFolders,
    pages: options.searchPages,
  };

  const url = process.env.REACT_APP_SEARCH_API;

  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        authorization: accessToken,
        accessKeyId,
        secretAccessKey,
        sessionToken,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (!resp.ok) {
      console.error(resp);
      return false;
    }

    const data = await resp.json();

    const files = data.documents;

    const bucketName = process.env.REACT_APP_CUSTOMER_BUCKET_NAME;
    const zone = process.env.REACT_APP_BUCKET_REGION;
    const bucketPath = `https://${bucketName}.s3.${zone}.amazonaws.com/public/`;

    const updatedFiles = [];
    for (const file of files) {
      const url = decodeURIComponent(file.url);
      const key = url.replace(bucketPath, '');
      const name = url.replace(/^.*[\\/]/, '');
      const extension = getFileExtension(name);
      const urlPath = await getLink(key);

      updatedFiles.push({ key, urlPath, name, extension, folder: file.folder });
    }

    return {
      files: updatedFiles,
      matches: data.matches,
    };
  } catch (err) {
    console.error('failed to search', err);
    return false;
  }
};
