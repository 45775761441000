import { FC, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import ControlledInput from '@quanterix-ui/core/ControlledInput';

import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';

import {
  validateIsNumber,
  validateMaxLength,
  validateSpecialCharacters2,
  validateWhitespaces,
} from '../../utils';
import { useManifestsSyncWithForm } from '../../hooks/useManifestsSyncWithForm';
import {
  AcceleratorFormStepComponentProps,
  SampleTypesFull,
} from '../../typings';
import { useStyles } from '../../styles';
import CheckboxList from '../CheckboxList';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

const Step4: FC<AcceleratorFormStepComponentProps> = ({
  onNext,
  onPrevious,
  formData,
  onFormDataChange,
  onFormCancelOpen,
  onFormCancel,
}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData },
  });

  useManifestsSyncWithForm({
    sampleReagentsManifests: formData.sampleReagentsManifests,
    sampleReagentsManifestLinks: formData.sampleReagentsManifestLinks,
    getValues,
    reset,
  });

  const onSubmit = (data: AcceleratorFormSchema) => {
    onNext(data);
  };

  const handleOnPrevious = () => {
    const formData = getValues();
    onPrevious(formData);
  };

  const handleChangeSampleTypes = (value: SampleTypesFull) => {
    const array = value.items.map((i) => (i !== 'Other' ? i : value.other));

    setValue('sampleTypes', array.join(', '));
    trigger('sampleTypes');
    setValue('sampleTypesFull', value);
    trigger('sampleTypesFull');
  };

  const validateSampleTypes = (value: SampleTypesFull) => {
    if (!value || !value.items || value.items.length === 0) {
      return false;
    }

    const hasOther = value.items.includes('Other');

    if (hasOther) {
      const { pattern } = validateSpecialCharacters2;
      const { maxLength } = validateMaxLength(40);

      if ((value.other || '').length > 40) {
        return maxLength.message;
      }

      return pattern.value.test(value.other || '') || pattern.message;
    }

    return true;
  };

  const sampleTypesFull = watch('sampleTypesFull');
  const allFieldsData = watch();

  useEffect(() => {
    onFormDataChange(allFieldsData);
  }, [allFieldsData, onFormDataChange]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.formControl}>
        <FormLabel required component="legend" error={!!errors.sampleTypesFull}>
          Sample Types
        </FormLabel>
        <Controller
          name="sampleTypesFull"
          control={control}
          rules={{
            required: true,
            ...validateSpecialCharacters2,
            validate: {
              validateSampleTypes,
            },
          }}
          render={() => (
            <CheckboxList
              value={sampleTypesFull}
              items={[
                { name: 'Plasma', value: 'Plasma' },
                { name: 'Serum', value: 'Serum' },
                { name: 'CSF', value: 'CSF' },
                { name: 'Other', value: 'Other' },
              ]}
              onChangeValue={handleChangeSampleTypes}
            />
          )}
        />
        <ControlledInput
          name="sampleTypes"
          type="hidden"
          control={control}
          style={{ display: 'none' }}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel required component="legend">
          Species Origin
        </FormLabel>
        <FormHelperText>
          Please indicate the species of the samples to be assayed. (Example:
          human, mouse, etc.)
        </FormHelperText>
        <ControlledTextField
          fullWidth
          name="speciesOrigin"
          control={control}
          rules={{
            required: true,
            ...validateMaxLength(40),
            ...validateSpecialCharacters2,
            validate: {
              validateWhitespaces,
            },
          }}
          helperText={errors.speciesOrigin?.message}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel required component="legend">
          Total Sample Number
        </FormLabel>
        <ControlledTextField
          fullWidth
          name="totalSampleNumber"
          control={control}
          rules={{
            required: true,
            validate: {
              validateWhitespaces,
              validateIsNumber,
            },
          }}
          helperText={errors.totalSampleNumber?.message}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel required component="legend">
          Sample Type Volumes
        </FormLabel>
        <FormHelperText>
          Please provide the volumes for each sample type you are sending.
        </FormHelperText>
        <ControlledTextField
          fullWidth
          multiline
          name="sampleTypeVolumes"
          rows={5}
          control={control}
          rules={{
            required: true,
            ...validateSpecialCharacters2,
            validate: {
              validateWhitespaces,
            },
          }}
          helperText={errors.sampleTypeVolumes?.message}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel required component="legend">
          Sample Testing Information
        </FormLabel>
        <FormHelperText>
          Specify the assay(s) that should be run for the included samples.
        </FormHelperText>
        <ControlledTextField
          fullWidth
          name="sampleTestingInformation"
          control={control}
          rules={{
            required: true,
            ...validateMaxLength(2000),
            ...validateSpecialCharacters2,
            validate: {
              validateWhitespaces,
            },
          }}
          helperText={errors.sampleTestingInformation?.message}
        />
      </div>
      <br />
      <br />
      <p>
        <FormLabel component="legend">
          Unless otherwise specified, calibrators will be assayed in{' '}
          <i>triplicate</i>, controls will be assayed in <i>duplicate</i>, and
          samples will be assayed in <i>duplicate</i>. Samples will be diluted
          in accordance with the recommend sample dilution scheme, as indicated
          by the Quanterix assay kit-specific instructions. If you would like to
          elect for an alternative plate layout or dilution scheme, please
          complete the section below accordingly.
        </FormLabel>
      </p>
      <div className={classes.formControl}>
        <FormLabel
          required
          component="legend"
          error={!!errors.calibratorReplicates}
        >
          Calibrator Replicates
        </FormLabel>
        <Controller
          name="calibratorReplicates"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              value={value}
              onChange={(e) => onChange(e.target.value)}
            >
              <FormControlLabel
                value="Duplicate"
                label="Duplicate"
                control={<Radio />}
              />
              <FormControlLabel
                value="Triplicate"
                label="Triplicate"
                control={<Radio />}
              />
            </RadioGroup>
          )}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel
          required
          component="legend"
          error={!!errors.controlReplicates}
        >
          Control Replicates
        </FormLabel>
        <Controller
          name="controlReplicates"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              value={value}
              onChange={(e) => onChange(e.target.value)}
            >
              <FormControlLabel
                value="Duplicate"
                label="Duplicate"
                control={<Radio />}
              />
              <FormControlLabel
                value="Triplicate"
                label="Triplicate"
                control={<Radio />}
              />
            </RadioGroup>
          )}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel
          required
          component="legend"
          error={!!errors.sampleReplicates}
        >
          Sample Replicates
        </FormLabel>
        <Controller
          name="sampleReplicates"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              value={value}
              onChange={(e) => onChange(e.target.value)}
            >
              <FormControlLabel
                value="Singlicate"
                label="Singlicate"
                control={<Radio />}
              />
              <FormControlLabel
                value="Duplicate"
                label="Duplicate"
                control={<Radio />}
              />
              <FormControlLabel
                value="Triplicate"
                label="Triplicate"
                control={<Radio />}
              />
            </RadioGroup>
          )}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel required component="legend">
          Custom Dilution Factor
        </FormLabel>
        <ControlledTextField
          fullWidth
          name="customDilutionFactor"
          control={control}
          rules={{
            required: true,
            ...validateMaxLength(40),
            ...validateSpecialCharacters2,
            validate: {
              validateWhitespaces,
            },
          }}
          helperText={errors.customDilutionFactor?.message}
        />
      </div>
      <StepperNavigation
        onFormCancelOpen={onFormCancelOpen}
        onFormCancel={onFormCancel}
      >
        <StepperNavButton direction="previous" onClick={handleOnPrevious} />
        <StepperNavButton direction="next" />
      </StepperNavigation>
    </form>
  );
};

export default Step4;
