import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginBottom: 20,
  },

  gridMainContainer: {
    width: 'calc(100% + 100px)',
    marginLeft: -50,
    marginRight: -50,

    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },

  gridColumn: {
    paddingLeft: 50,
    paddingRight: 50,
    borderRight: `1px solid ${theme.palette.divider}`,

    '&:nth-child(1), &:nth-child(2)': {
      paddingTop: 6,
    },

    [theme.breakpoints.down('md')]: {
      padding: 0,
      borderRight: 'unset',
    },

    '&:last-of-type': {
      borderRight: 'unset',
    },
  },

  fieldset: {
    marginBottom: 20,

    '& .MuiFormLabel-root, & .MuiFormControlLabel-label': {
      color: theme.palette.common.black,
      fontWeight: 900,
    },

    [theme.breakpoints.up('lg')]: {
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },

  fieldsetValue: {
    color: theme.palette.common.black,
    fontWeight: 900,
  },

  switchLabel: {
    fontWeight: 900,
  },

  instrumentListItemText: {
    '& span': {
      color: theme.palette.common.black,
      fontWeight: 900,
    },

    '& p': {
      color: theme.palette.text.primary,
    },
  },
}));
