import { privateGateway } from '../../../config';
import { normilizeFormData } from '../utils';
import { AcceleratorFormSchema } from '../typings';

export const submitAcceleratorForm = async (
  formData: AcceleratorFormSchema
): Promise<void> => {
  await privateGateway(
    process.env.REACT_APP_SUBMISSION_API_GATEWAY as string
  ).post<AcceleratorFormSchema>('/form', normilizeFormData(formData));
};
