import { AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';

// NOTE: is for Submissions API
export const includeSessionToken = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const session = await Auth.currentSession();
  const idToken = session.getIdToken().getJwtToken();

  config.headers.Authorization = `Bearer ${idToken}`;

  return config;
};

// NOTE: is for other API
export const includeAccessToken = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken().getJwtToken();
  const idToken = session.getIdToken().getJwtToken();

  config.headers.Authorization = `Bearer ${accessToken}`;
  config.headers.idToken = `Bearer ${idToken}`;

  return config;
};
