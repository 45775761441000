import { FC } from 'react';
import 'react-phone-input-2/lib/high-res.css';

import FormLabel from '@material-ui/core/FormLabel';
import MuiBox from '@material-ui/core/Box';

import { AcceleratorFormStepComponentProps } from '../../typings';
import { useStyles } from '../../styles';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

const Step6: FC<AcceleratorFormStepComponentProps> = ({ onPrevious }) => {
  const classes = useStyles();

  return (
    <div>
      <MuiBox mt={4} className={classes.formControl}>
        <FormLabel component="legend">
          Thank you for your submission to the Accelerator Lab
        </FormLabel>
      </MuiBox>
      <StepperNavigation>
        <StepperNavButton direction="finish" onClick={onPrevious} />
      </StepperNavigation>
    </div>
  );
};

export default Step6;
