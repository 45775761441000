import { FC } from 'react';

import Grid from '@material-ui/core/Grid';

import Button from '@quanterix-ui/core/Button';

interface Props {
  inProgress: boolean;
  disabled: boolean;
  onClearSearch: () => void;
}

const SearchActions: FC<Props> = ({ inProgress, disabled, onClearSearch }) => (
  <Grid container alignItems="center">
    <Button type="submit" loading={inProgress} disabled={disabled}>
      Search
    </Button>
    <Button disabled={disabled} variant="outlined" onClick={onClearSearch}>
      Clear
    </Button>
  </Grid>
);

export default SearchActions;
