import { ChangeEventHandler, FC, useState } from 'react';

import cx from 'classnames';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';

import TextField from '@quanterix-ui/core/TextField';
import Button from '@quanterix-ui/core/Button';

import { useStyles } from '../../styles';

interface Props {
  onAddSection: (section: string) => void;
}

const NewSectionEdit: FC<Props> = ({ onAddSection }) => {
  const classes = useStyles();

  const [section, setSection] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isRequired, setIsRequired] = useState(false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSection(event.target.value);
    setIsRequired(false);
    setIsValid(true);
  };

  const handleAdd = () => {
    if (!section) {
      setIsRequired(true);
      return;
    }

    if (section.indexOf('#') > -1) {
      setIsValid(false);
      return;
    }

    if (onAddSection) {
      onAddSection(section);
      setSection('');
    }
  };

  const hasError = isRequired || !isValid;

  return (
    <Box width="100%" mt={1} mb={3}>
      <Box width="100%">
        <Grid
          container
          className={cx(classes.fieldContainer, 'hasTextField', { hasError })}
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={8} sm={9}>
            <TextField
              fullWidth
              placeholder="New section name"
              className={classes.textfield}
              value={section}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.actionButton}
              variant="text"
              color="secondary"
              onClick={handleAdd}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
      {isRequired && (
        <FormHelperText error>Section name is required</FormHelperText>
      )}
      {!isValid && (
        <FormHelperText error>Section name can't contain #</FormHelperText>
      )}
    </Box>
  );
};

export default NewSectionEdit;
