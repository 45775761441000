import axios, { AxiosResponse } from 'axios';

import { RequestFileUploadPlacementData } from '../typings';

export const uploadFile = async (
  url: string,
  file: File,
  data: RequestFileUploadPlacementData
): Promise<AxiosResponse> => {
  const formData: FormData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  formData.append('file', file);

  const response = await axios({
    method: 'POST',
    url,
    data: formData,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  });

  return response;
};
