import { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { getDownloadUrl } from 'src/utils/helpers/blobStorage';

const Download: FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileLink, setFileLink] = useState('');
  const [fileName, setFileName] = useState('');

  const params = useParams<{ file?: string }>();

  useEffect(() => {
    let { file } = params;

    if (!file) {
      setErrorMessage('File parameter is missing');
    } else {
      try {
        file = decodeURIComponent(atob(file));
      } catch (error) {
        setErrorMessage('File is not valid');
        throw new Error(error);
      }

      const fileName = file.replace(/^.*[\\/]/, '');

      setFileName(fileName);

      getDownloadUrl(file).then((link) => {
        setFileLink(link);

        const a = document.createElement('a');
        a.setAttribute('href', link);
        a.setAttribute('target', '__blank');
        a.setAttribute('download', fileName);

        const container = document.getElementById('link_holder');
        container?.appendChild(a);

        a.click();
      });
    }
  }, [params]);

  return (
    <div className="AuthLayoutContent">
      <div className="folderHeader">Download file</div>
      <h4>{fileName}</h4>
      <p>
        {errorMessage && <div className="error">{errorMessage}</div>}
        {!errorMessage && (
          <h5 id="link_holder">
            <a target="__blank" href={fileLink}>
              If your download doesn't start immediately, click here to
              download.
            </a>
          </h5>
        )}
      </p>
    </div>
  );
};

export default Download;
