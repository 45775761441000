import { FC, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import ControlledSelect from '@quanterix-ui/core/ControlledSelect';
import SelectOption from '@quanterix-ui/core/SelectOption';

import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';

import {
  validateMaxLength,
  validateSpecialCharacters1,
  validateSpecialCharacters2,
  validateWhitespaces,
} from '../../utils';
import { SIMOA_PLATFORMS } from '../../constants';
import { useManifestsSyncWithForm } from '../../hooks/useManifestsSyncWithForm';
import { AcceleratorFormStepComponentProps } from '../../typings';
import { useStyles } from '../../styles';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

const Step2: FC<AcceleratorFormStepComponentProps> = ({
  onNext,
  onPrevious,
  formData,
  onFormDataChange,
  onFormCancelOpen,
  onFormCancel,
}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData },
  });

  const allFieldsData = watch();

  useEffect(() => {
    onFormDataChange(allFieldsData);
  }, [allFieldsData, onFormDataChange]);

  useManifestsSyncWithForm({
    sampleReagentsManifests: formData.sampleReagentsManifests,
    sampleReagentsManifestLinks: formData.sampleReagentsManifestLinks,
    getValues,
    reset,
  });

  const onSubmit = (data: AcceleratorFormSchema) => {
    onNext(data);
  };

  const handleOnPrevious = () => {
    const formData = getValues();
    onPrevious(formData);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.formControl}>
        <FormLabel component="legend">Project ID</FormLabel>
        <FormHelperText>
          For existing projects only: a unique Project ID may have been provided
          by a Quanterix representative. If so, please be sure to indicate this
          identifier below. The format of this identifier will be "PR" followed
          by a 6-digit number. Otherwise, leave this field blank.
        </FormHelperText>
        <ControlledTextField
          fullWidth
          name="projectId"
          control={control}
          rules={{
            ...validateMaxLength(40),
            ...validateSpecialCharacters1,
            validate: {
              validateWhitespaces,
            },
          }}
          helperText={errors.projectId?.message}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel component="legend">PO Number</FormLabel>
        <FormHelperText>
          Please do not send your samples in advance of PO receipt by Quanterix
        </FormHelperText>
        <ControlledTextField
          fullWidth
          name="poNumber"
          control={control}
          rules={{
            ...validateMaxLength(20),
            ...validateSpecialCharacters2,
            validate: {
              validateWhitespaces,
            },
          }}
          helperText={errors.poNumber?.message}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel required component="legend" error={!!errors.projectType}>
          Project Type
        </FormLabel>
        <Controller
          name="projectType"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              defaultChecked={!!value}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            >
              <FormControlLabel
                value="Homebrew"
                label="Homebrew"
                control={<Radio />}
              />
              <FormControlLabel
                value="Sample Testing"
                label="Sample Testing"
                control={<Radio />}
              />
              <FormControlLabel
                value="Other"
                label="Other"
                control={<Radio />}
              />
            </RadioGroup>
          )}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel component="legend">Platform</FormLabel>
        <ControlledSelect
          fullWidth
          displayEmpty
          name="platform"
          placeholder="Select a Simoa Platform"
          control={control}
          rules={{
            ...validateMaxLength(80),
            ...validateSpecialCharacters2,
            validate: {
              validateWhitespaces,
            },
          }}
        >
          <SelectOption value="">Select a Simoa Platform</SelectOption>
          {Object.values(SIMOA_PLATFORMS).map((platform) => (
            <SelectOption key={platform} value={platform}>
              {platform}
            </SelectOption>
          ))}
        </ControlledSelect>
      </div>
      <div className={classes.formControl}>
        <FormLabel required component="legend">
          Project Description
        </FormLabel>
        <ControlledTextField
          fullWidth
          multiline
          name="projectDescription"
          rows={5}
          control={control}
          rules={{
            required: true,
            ...validateMaxLength(80),
            ...validateSpecialCharacters2,
            validate: {
              validateWhitespaces,
            },
          }}
          helperText={errors.projectDescription?.message}
        />
      </div>
      {allFieldsData.projectType === 'Homebrew' && (
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            Homebrew Project Description
          </FormLabel>
          <ControlledTextField
            fullWidth
            multiline
            name="homebrewProjectDescription"
            rows={5}
            control={control}
            rules={{
              required: true,
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.homebrewProjectDescription?.message}
          />
        </div>
      )}
      <StepperNavigation
        onFormCancelOpen={onFormCancelOpen}
        onFormCancel={onFormCancel}
      >
        <StepperNavButton direction="previous" onClick={handleOnPrevious} />
        <StepperNavButton direction="next" />
      </StepperNavigation>
    </form>
  );
};

export default Step2;
