import { FC, useEffect, useState } from 'react';

import { createPortal } from 'react-dom';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';

import Button from '@quanterix-ui/core/Button';

import ConfirmDialog from 'src/components/ConfirmDialog';

import { AcceleratorFormStepComponentProps } from '../../typings';

interface Props {
  onFormCancel?: AcceleratorFormStepComponentProps['onFormCancel'];
  onFormCancelOpen?: AcceleratorFormStepComponentProps['onFormCancelOpen'];
}

const StepperNavigation: FC<Props> = ({
  children,
  onFormCancel,
  onFormCancelOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCancelConfirmation = () => {
    if (onFormCancel) {
      onFormCancel();
    }
  };

  useEffect(() => {
    if (onFormCancelOpen) {
      onFormCancelOpen(isOpen);
    }
  }, [isOpen, onFormCancelOpen]);

  return (
    <>
      <MuiBox mt={5}>
        <MuiGrid container justify="space-between">
          <MuiGrid item>
            <MuiGrid container>
              <>{children}</>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item>
            {!!onFormCancel && (
              <Button variant="outlined" onClick={handleOpen}>
                Cancel
              </Button>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiBox>
      {createPortal(
        <ConfirmDialog
          open={isOpen}
          title="Canceling will clear the form. Are you sure you want to cancel?"
          acceptButtonText="Yes"
          cancelButtonText="No"
          onClose={handleClose}
          onAccept={handleCancelConfirmation}
        />,
        document.getElementById('modal-root') as HTMLDivElement
      )}
    </>
  );
};

export default StepperNavigation;
