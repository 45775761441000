import { privateGateway } from '../../../config';
import { RequestTemplateResult } from '../typings';

interface RequestTemplateDownloadUrlParams {
  template_type: string;
}

export const requestTemplateDownloadUrl = async ({
  template_type,
}: RequestTemplateDownloadUrlParams): Promise<RequestTemplateResult> => {
  const response = await privateGateway(
    process.env.REACT_APP_SUBMISSION_API_GATEWAY as string
  ).get(`/form/template/${template_type}`);

  return response.data;
};
