/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';

import { SnackbarOrigin } from 'notistack';

export enum CognitoEvents {
  SIGN_IN = 'signIn',
  SIGN_OUT = 'signOut',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
  ERROR = 'error',
}

export interface CognitoUserAttributes {
  [key: string]: string;
}

export interface CognitoUser {
  id: string;
  name: string;
  email: string;
  emailVerified: boolean;
  attributes: CognitoUserAttributes;
}

export interface SignInOptions {
  email: string;
  password: string;
  redirectTo?: string;
}
export type SignInDefault = () => void;
export type SignInCustom = ({
  email,
  password,
}: SignInOptions) => Promise<void>;

export interface SignUpOptions {
  email: string;
  password: string;
  attributes?: any;
  clientMetadata?: any;
  redirectTo?: string;
}
export type SignUpCustom = ({
  email,
  password,
}: SignUpOptions) => Promise<void>;

export type CheckIsAuthenticated = () => Promise<boolean>;
export type CheckIsAdmin = () => Promise<boolean>;

export interface UpdateUserOptions {
  attributes: CognitoUserAttributes;
}
export type UpdateUserCustom = ({
  attributes,
}: UpdateUserOptions) => Promise<void>;

export interface ForgotPasswordOptions {
  email: string;
}
export type ForgotPasswordCustom = ({
  email,
}: ForgotPasswordOptions) => Promise<void>;

export interface ForgotPasswordSubmitOptions {
  email: string;
}
export type ForgotPasswordSubmitCustom = ({
  email,
}: ForgotPasswordOptions) => Promise<void>;

export interface ResetPasswordOptions {
  email: string;
  verificationCode: string;
  password: string;
  redirectTo?: string;
}
export type ResetPasswordCustom = ({
  email,
  verificationCode,
  password,
  redirectTo,
}: ResetPasswordOptions) => Promise<void>;

export interface ChangePasswordOptions {
  currentPassword: string;
  newPassword: string;
  redirectTo?: string;
}
export type ChangePasswordCustom = ({
  currentPassword,
  newPassword,
}: ChangePasswordOptions) => Promise<void>;

export interface CompleteNewPasswordOptions {
  user: CognitoUser;
  newPassword: string;
  attributes: any[];
}
export type CompleteNewPasswordCustom = ({
  user,
  newPassword,
  attributes,
}: CompleteNewPasswordOptions) => Promise<void>;

export type CognitoUserEntity = Record<string, any> | null;

export interface CognitoConfig {
  user: CognitoUserEntity;
  signIn: SignInCustom | SignInDefault;
  signOut: () => void;
  refreshUser: () => void;
}

export interface CognitoConfigExtended extends CognitoConfig {
  isLoading: boolean;
  isEmailVerified: boolean;
  forgotPassword: ForgotPasswordCustom;
  resetPassword: ResetPasswordCustom;
  changePassword: ChangePasswordCustom;
  completeNewPassword: CompleteNewPasswordCustom;
  signUp: SignUpCustom;
  setSnackbarAnchor: Dispatch<SetStateAction<SnackbarOrigin>>;
  checkIsAuthenticated: CheckIsAuthenticated;
  checkIsAdmin: CheckIsAdmin;
  updateUser: UpdateUserCustom;
}

export interface CognitoOptions {
  snackbarAnchor?: SnackbarOrigin;
}

export interface CognitoState
  extends Omit<CognitoConfigExtended, 'setSnackbarAnchor'> {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isLoading: boolean;
  user: CognitoUserEntity;
  userData: CognitoUser;
  userFullName: string;
  checkAccess: (group: string) => boolean;
}
