import { lighten, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 35,
    left: '50%',
    translate: '-50% 0',
    width: 'calc(100% - 40px)',
    maxWidth: 960,
    paddingRight: 80,
    backgroundColor: lighten(theme.palette.warning.main, 0.8),
    color: theme.palette.text.primary,
    zIndex: 999,

    '& a': {
      textDecoration: 'underline',
    },
  },

  actionButton: {
    position: 'absolute',
    top: '50%',
    right: 10,
    translate: '0 -50%',
  },
}));
