import { ChangeEventHandler, FC } from 'react';

import Input from '@quanterix-ui/core/Input';

interface Props {
  query: string;
  onChange: (value: string) => void;
}

const SearchBox: FC<Props> = ({ query, onChange }) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  return <Input name="search" value={query} onChange={handleChange} />;
};

export default SearchBox;
