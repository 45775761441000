import CircularProgress from "@material-ui/core/CircularProgress";
import "./ProgressOverlay.css";

export default function ProgressOverlay(props) {
  const inProgress = props.inProgress || false;
  const size = props.size || "7rem";
  return (
    <div className="uploaderContainer">
      <div className={inProgress ? "showProgressOverlay" : "hideProgress"}>
        <div className={"centerSpinner"}>
          <CircularProgress size={size} thickness={3} />
        </div>
      </div>
      {props.children}
    </div>
  );
}
