export const CSV_FILE_EXTENTIONS = [
  '.csv',
  'text/csv',
  '.xlsx',
  '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel',
  'ods',
  'application/vnd.oasis.opendocument.spreadsheet',
  'odt',
  'application/vnd.oasis.opendocument.text',
  'odp',
  'application/vnd.oasis.opendocument.presentation',
  'doc',
  'application/msword',
  'docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.lhc',
];
