export const DEFAULT_FORM_VALUES = {
  email: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  company: '',
  instruments: [],
  accelerator_services: false,
  city: '',
  state: '',
  website: '',
  phone_number: '',
  address_street: '',
  address_street2: '',
  address_postal_code: '',
  address_country: '',
};
