import { privateGateway } from '../../../config';
import { RequestTemplateResult } from '../typings';

interface RequestSaveTemplateParams {
  file_name: string;
  template_type: string;
}

export const requestSaveTemplate = async ({
  template_type,
  file_name,
}: RequestSaveTemplateParams): Promise<RequestTemplateResult> => {
  const response = await privateGateway(
    process.env.REACT_APP_SUBMISSION_API_GATEWAY as string
  ).post(`/form/template/${template_type}`, { file_name });

  return response.data;
};
