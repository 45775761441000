import { privateGateway } from '../../../config';
import { RequestFileUploadPlacementResult } from '../typings';

interface RequestFileUploadPlacementParams {
  file_name: string;
}

export const requestFileUploadPlacement = async ({
  file_name,
}: RequestFileUploadPlacementParams): Promise<RequestFileUploadPlacementResult> => {
  const response = await privateGateway(
    process.env.REACT_APP_SUBMISSION_API_GATEWAY as string
  ).post(`/form/file/upload`, { file_name });

  return response.data;
};
