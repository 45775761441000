import { useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';

import { readFile, removeFromS3, updateFileContent } from 'src/utils/S3Helper';
import ProgressOverlay from 'src/components/common/progress-overlay/ProgressOverlay';

export default function ContentEditor({
  path,
  defaultText = 'Start typing',
  rows = 5,
  onSaved,
}) {
  const [content, setContent] = useState('');
  const [prevContent, setPrevContent] = useState('');
  const [inProgress, setinProgress] = useState(false);

  useEffect(() => {
    const readFileFunc = async (file) => {
      setinProgress(true);
      const data = await readFile(file);

      if (data) {
        setContent(data);
      }

      setinProgress(false);
    };
    setContent('');
    readFileFunc(path);
  }, [path]);

  const handleSave = async () => {
    setinProgress(true);
    await updateFileContent(path, content);
    setinProgress(false);
  };

  const handleDelete = async () => {
    setinProgress(true);
    await removeFromS3(path);
    setinProgress(false);
  };

  const handleChange = (value) => {
    setPrevContent(content);
    setContent(value);
  };
  const notify = (text) => {
    if (onSaved) {
      onSaved(text);
    }
  };

  const handleBlur = () => {
    if (content && content.length > 0) {
      handleSave();
      notify(`${defaultText} updated`);
    } else if (content.length === 0 && prevContent.length > 0) {
      handleDelete();
      notify(`${defaultText} updated`);
    }
  };

  return (
    <ProgressOverlay inProgress={inProgress} size="2rem">
      <TextField
        multiline
        fullWidth
        id="standard-multiline-static"
        label={defaultText}
        rows={rows}
        value={content}
        onBlur={handleBlur}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
      />
    </ProgressOverlay>
  );
}
