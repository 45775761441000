import { UserStatuses } from 'src/utils/Constants'

export const FilterFields = {
  "email": {
    "name": "email",
    "title": "Email",
    "operator": "^="
  },
  "phone_number": {
    "name": "phone_number",
    "title": "Phone",
    "operator": "^="
  },
  "given_name": {
    "name": "given_name",
    "title": "First Name",
    "operator": "^="
  },
  "family_name": {
    "name": "family_name",
    "title": "Last Name",
    "operator": "^="
  },
  "cognito:user_status": {
    "name": "cognito:user_status",
    "title": "Status",
    "operator": "=",
    "values": Object.keys(UserStatuses).map(key => ({
      value: key,
      title: UserStatuses[key]
    }))
  },
  "status": {
    "name": "status",
    "title": "Enabled",
    "operator": "=",
    "values": [
      {
        "title": "Inactive",
        "value": "Disabled"
      },
      {
        "title": "Active",
        "value": "Enabled"
      }
    ]
  },
};