import { useEffect, useState } from "react";

import { readFile } from "src/utils/S3Helper";
import { renderHtml } from "src/utils/RenderHelper";

export default function ContentViewer({ path }) {
  const [html, setHtml] = useState('');

  useEffect(() => {
    setHtml('');
    readFile(path)
      .then((data) => {
        setHtml(data);
      })
      .catch((error) => {
        console.error(error);
        setHtml('');
      });
  }, [path]);
 
  return <div dangerouslySetInnerHTML={renderHtml(html)} />;
}
