import {
  faFileAlt,
  faFileArchive,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  IconDefinition,
} from '@fortawesome/free-regular-svg-icons';

export const isVideo = (ext: string): boolean => {
  switch (ext) {
    case 'avi':
    case 'mp4':
    case 'm4v':
      return true;
    default:
      return false;
  }
};

export const getFileTypeIcon = (extention: string): IconDefinition => {
  switch (extention) {
    case 'pdf':
      return faFilePdf;
    case 'doc':
    case 'docx':
      return faFileWord;
    case 'xls':
    case 'xlsx':
      return faFileExcel;
    case 'ppt':
    case 'pptx':
      return faFilePowerpoint;
    case 'jpg':
    case 'png':
    case 'gif':
    case 'bmp':
      return faFileImage;
    case 'avi':
    case 'mp4':
    case 'm4v':
      return faFileVideo;
    case 'zip':
    case 'rar':
    case '7z':
      return faFileArchive;
    default:
      return faFileAlt;
  }
};
