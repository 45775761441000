import { FC } from 'react';

import { Scrollbars } from 'react-custom-scrollbars-2';

import Grid from '@material-ui/core/Grid';

import { usePortalView } from 'src/utils/hooks';
import AwsTreeView from 'src/components/aws-tree-view/AwsTreeView';
import EditableAwsTreeView from 'src/components/admin/pages/editable-aws-tree-view/EditableAwsTreeView';

import { useStyles } from './styles';

const SidebarMenuLayout: FC = () => {
  const classes = useStyles();
  const { isProtected } = usePortalView();

  return (
    <Grid item sm={3} md={3} className={classes.root}>
      <Scrollbars autoHide className={classes.scrollbars}>
        {isProtected ? <EditableAwsTreeView /> : <AwsTreeView />}
      </Scrollbars>
    </Grid>
  );
};

export default SidebarMenuLayout;
