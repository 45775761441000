import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 44,
        height: 26,
        padding: 2,
        margin: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: `1px solid ${theme.palette.primary.main}`,
            },
        },
        '&$focusVisible $thumb': {
            color: theme.palette.primary.main,
            border: `6px solid ${theme.palette.common.white}`,
        },
    },
    thumb: {
        width: 20,
        height: 20,
        marginTop: 2,
        marginLeft: 3,
    },
    track: {
        borderRadius: 22 / 2,
        border: `1px solid ${theme.palette.grey[200]}`,
        backgroundColor: theme.palette.grey[200],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});



