const DEFAULT_INSTRUMENTS = [
  {
    Instrument: 'SP-X',
    Serial: '',
    Selected: false,
  },
  {
    Instrument: 'HD-1',
    Serial: '',
    Selected: false,
  },
  {
    Instrument: 'SR-X',
    Serial: '',
    Selected: false,
  },
  {
    Instrument: 'HD-X',
    Serial: '',
    Selected: false,
  },
];

export const getDefaultInstruments = (): typeof DEFAULT_INSTRUMENTS => {
  return DEFAULT_INSTRUMENTS.map((object) => ({ ...object }));
};
