import { Link, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import logo from 'src/assets/images/logo.png';

import './DefaultLayout.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiContainer-root': {
      padding: theme.spacing(0, 4, 0, 8),
    },
  },
  boldLink: {
    fontWeight: 600,
  },
}));

const DefaultLayout = ({ children }) => {
  const location = useLocation();

  const classes = useStyles();

  return (
    <Grid container className={classes.root} justify="flex-start">
      <Grid item xs={12} sm={8} md={4}>
        <Scrollbars autoHide style={{ height: '100vh' }}>
          <div className="DefaultLayoutContainer">
            <div className="DefaultLayoutHeader">
              <div className="DefaultLayoutLogo">
                <Link to="/">
                  <img
                    className="DefaultLayoutLogoImage"
                    src={logo}
                    alt="Logo"
                  />
                </Link>
                <Typography component="div" variant="caption">
                  Customer Portal
                </Typography>
              </div>
              <div className="DefaultLayoutHeaderPrompt">
                {location.pathname === '/signin' && (
                  <span>
                    Don’t have account?{' '}
                    <Link className={classes.boldLink} to="/signup">
                      Register
                    </Link>
                  </span>
                )}
                {location.pathname === '/signup' && (
                  <span>
                    Have an account?{' '}
                    <Link className={classes.boldLink} to="/signin">
                      Login
                    </Link>
                  </span>
                )}
              </div>
            </div>
            <div className="DefaultLayoutContent">{children}</div>
          </div>
        </Scrollbars>
      </Grid>
      <Grid item sm={4} md={8}>
        <div className="DefaultLayoutMainSection">
          <div>
            <Typography variant="h1">Quanterix</Typography>
            <Typography variant="h4">
              The Science of Precision Health
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default DefaultLayout;
