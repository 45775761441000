import { ChangeEventHandler, FC, useState } from 'react';

import {
  Collapse,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Checkbox from '@material-ui/core/Checkbox';

import CheckboxList from 'src/pages/AcceleratorForm/components/CheckboxList';
import { usePortalContext } from 'src/state/context/PortalContext';

interface Props {
  collapsed: boolean;
  searchOptions: any;
  onRefine: (options: any) => void;
  onCollapse: () => void;
}

const SearchRefinemer: FC<Props> = ({
  searchOptions,
  collapsed,
  onRefine,
  onCollapse,
}) => {
  const { folders } = usePortalContext();

  const [selectAll, setSelectAll] = useState(true);

  const handleChangeWithin: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    onRefine({
      ...searchOptions,
      searchWithin: value,
    });
  };

  const onChangeWhere = (value: any) => {
    onRefine({
      ...searchOptions,
      searchFolders: value.items,
    });
  };

  const onChangeSelectAll: ChangeEventHandler<HTMLInputElement> = (event) => {
    const checked = event.target.checked;

    setSelectAll(checked);

    if (checked) {
      onChangeWhere({ items: folders.map((f) => f.name) });
    } else {
      onChangeWhere({ items: [] });
    }
  };

  const { searchWithin, searchFolders } = searchOptions;

  return (
    <Grid container direction="column" className="Refinemer">
      <Link href={'#'} onClick={onCollapse}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            {!collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </Grid>
          <Grid item>
            <Typography>Refine your search</Typography>
          </Grid>
        </Grid>
      </Link>
      <Collapse in={collapsed}>
        <Grid container className="Form">
          <Grid item>
            <FormLabel component="legend">Search By</FormLabel>
            <RadioGroup
              name="searchBy"
              value={searchWithin}
              onChange={handleChangeWithin}
            >
              <FormControlLabel
                value="title"
                label="Title"
                control={<Radio size="small" />}
              />
              <FormControlLabel
                value="content"
                label="Document content"
                control={<Radio size="small" />}
              />
            </RadioGroup>
          </Grid>
          <Grid item>
            <FormLabel component="legend">Search within a category</FormLabel>
            <FormControlLabel
              label="Select All"
              control={
                <Checkbox
                  size="small"
                  checked={selectAll}
                  onChange={onChangeSelectAll}
                />
              }
            />
            <CheckboxList
              size="small"
              value={{ items: searchFolders }}
              items={folders.map((f) => ({ name: f.name, value: f.name }))}
              onChangeValue={onChangeWhere}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default SearchRefinemer;
