import { useEffect } from 'react';

import { UseFormGetValues, UseFormReset } from 'react-hook-form';

import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';

interface UseManifestsSyncWithFormParams {
  sampleReagentsManifests: AcceleratorFormSchema['sampleReagentsManifests'];
  sampleReagentsManifestLinks: AcceleratorFormSchema['sampleReagentsManifestLinks'];
  getValues: UseFormGetValues<AcceleratorFormSchema>;
  reset: UseFormReset<AcceleratorFormSchema>;
}

export const useManifestsSyncWithForm = ({
  sampleReagentsManifests,
  sampleReagentsManifestLinks,
  getValues,
  reset,
}: UseManifestsSyncWithFormParams): void => {
  useEffect(() => {
    const localFormData = getValues();
    reset({
      ...localFormData,
      sampleReagentsManifestLinks,
      sampleReagentsManifests,
    });
  }, [sampleReagentsManifests, sampleReagentsManifestLinks, reset, getValues]);
};
