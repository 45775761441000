import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  actionButton: {
    marginLeft: 15,
  },

  typography: {
    fontWeight: 900,
  },
}));
