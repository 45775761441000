import { FC, useEffect } from 'react';

import { Route, RouteProps, useHistory } from 'react-router-dom';

import { useCognito } from 'src/aws/Cognito';
import PrivateLayout from 'src/layouts/PrivateLayout';

const PrivateRoute: FC<RouteProps> = ({ children, ...restProps }) => {
  const history = useHistory();
  const { checkIsAuthenticated } = useCognito();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await checkIsAuthenticated();
      } catch (_error) {
        history.push('/signin');
      }
    };

    checkAuthentication();
  }, [history, checkIsAuthenticated]);

  return (
    <Route {...restProps}>
      <PrivateLayout>{children}</PrivateLayout>
    </Route>
  );
};

export default PrivateRoute;
