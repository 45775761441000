import { FC } from 'react';

import { useCognito } from 'src/aws/Cognito';

const Welcome: FC = () => {
  const { userFullName } = useCognito();

  return (
    <div className="AuthLayoutContent">
      <h1>{`Welcome ${userFullName}`}</h1>
      <a href="mailto:techsupport@quanterix.com">
        <img
          src="/images/quanterix-welcome.jpg"
          alt="Quanterix Welcome"
          style={{ maxWidth: '100%' }}
        />
      </a>
    </div>
  );
};

export default Welcome;
