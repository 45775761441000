import { FC, useEffect, useMemo } from 'react';

import { Controller, useForm } from 'react-hook-form';

import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import ControlledInput from '@quanterix-ui/core/ControlledInput';

import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';
import { removeByIndex } from 'src/utils/ArrayHelper';
import FilesUploader, { CustomFile } from 'src/components/FilesUploader';

import {
  validateManifests,
  validateMaxLength,
  validateSpecialCharacters2,
  validateWhitespaces,
} from '../../utils';
import {
  MANIFESTS_MAX_COUNT,
  MANIFESTS_MAX_SIZE,
  MANIFESTS_READABLE_MAX_SIZE,
} from '../../constants';
import { useManifestsSyncWithForm } from '../../hooks/useManifestsSyncWithForm';
import { AcceleratorFormStepComponentProps } from '../../typings';
import { useStyles } from '../../styles';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

const Step3: FC<AcceleratorFormStepComponentProps> = ({
  formData,
  isMalwareScanInProgress,
  addFilesToScanQueue,
  removeFileFromScanQueue,
  totalScannedFilesCount,
  inProgress,
  onManifestsUpload,
  onNext,
  onPrevious,
  onFormDataChange,
  onFormCancelOpen,
  onFormCancel,
}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm({ defaultValues: { ...formData } });

  useManifestsSyncWithForm({
    sampleReagentsManifests: formData.sampleReagentsManifests,
    sampleReagentsManifestLinks: formData.sampleReagentsManifestLinks,
    getValues,
    reset,
  });

  const onSubmit = (data: AcceleratorFormSchema) => {
    onNext(data);
  };

  const handleOnPrevious = () => {
    const formData = getValues();
    onPrevious(formData);
  };

  const handleFileUpload = (uploadedFiles: string[]) => {
    const uploadedLinks = uploadedFiles.map((file) => {
      const encodedFile = btoa(encodeURIComponent(file));
      const link = `${process.env.REACT_APP_HOST}/download/${encodedFile}`;
      return link;
    });

    const { sampleReagentsManifests = [], sampleReagentsManifestLinks } =
      getValues();

    const currentLinks = sampleReagentsManifestLinks
      ? JSON.parse(`${sampleReagentsManifestLinks}`)
      : [];

    const newFormData = {
      sampleReagentsManifests: [...sampleReagentsManifests, ...uploadedFiles],
      sampleReagentsManifestLinks: JSON.stringify([
        ...currentLinks,
        ...uploadedLinks,
      ]),
    };

    setValue('sampleReagentsManifests', newFormData.sampleReagentsManifests);

    setValue(
      'sampleReagentsManifestLinks',
      newFormData.sampleReagentsManifestLinks
    );

    addFilesToScanQueue(newFormData.sampleReagentsManifests);
    onManifestsUpload(newFormData);

    trigger('sampleReagentsManifests');
  };

  const handleFileRemove = async (file: string) => {
    const { sampleReagentsManifests = [], sampleReagentsManifestLinks } =
      getValues();

    const currentLinks = sampleReagentsManifestLinks
      ? JSON.parse(`${sampleReagentsManifestLinks}`)
      : [];

    // since file path is unique, we support multiple files with the same name
    const index = (sampleReagentsManifests as string[]).findIndex(
      (f) => f === file
    );

    setValue(
      'sampleReagentsManifests',
      removeByIndex(sampleReagentsManifests, index) as string[] // TODO: should proper handle typings
    );

    setValue(
      'sampleReagentsManifestLinks',
      JSON.stringify(removeByIndex(currentLinks, index))
    );

    removeFileFromScanQueue(file);

    trigger('sampleReagentsManifests');
  };

  const allFieldsData = watch();

  const uploadedFileToShow = useMemo<CustomFile[]>(() => {
    return allFieldsData.sampleReagentsManifests.map((manifest) => ({
      key: manifest,
    }));
  }, [allFieldsData.sampleReagentsManifests]);

  useEffect(() => {
    onFormDataChange(allFieldsData);
  }, [allFieldsData, onFormDataChange]);

  useEffect(() => {
    // automatically add files for malware scan
    if (!isMalwareScanInProgress && totalScannedFilesCount === 0) {
      addFilesToScanQueue(allFieldsData.sampleReagentsManifests);
      onManifestsUpload({
        sampleReagentsManifests: allFieldsData.sampleReagentsManifests,
        sampleReagentsManifestLinks: allFieldsData.sampleReagentsManifestLinks,
      });
    }
  }, [
    allFieldsData.sampleReagentsManifests,
    allFieldsData.sampleReagentsManifestLinks,
    isMalwareScanInProgress,
    totalScannedFilesCount,
    addFilesToScanQueue,
    onManifestsUpload,
  ]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.formControl}>
        <MuiFormLabel required component="legend">
          Tracking Information/Number
        </MuiFormLabel>
        <MuiFormHelperText>
          Please provide tracking information, including courier and tracking
          number.
        </MuiFormHelperText>
        <ControlledTextField
          fullWidth
          name="trackingNumber"
          control={control}
          rules={{
            required: true,
            ...validateMaxLength(40),
            ...validateSpecialCharacters2,
            validate: {
              validateWhitespaces,
            },
          }}
          helperText={errors.trackingNumber?.message}
        />
      </div>
      <div className={classes.formControl}>
        <p>
          <MuiFormLabel>
            An electronic manifest is required for all submissions.
          </MuiFormLabel>
        </p>
        <MuiFormLabel required component="legend">
          Sample and Reagents Manifests
        </MuiFormLabel>
        <MuiFormHelperText>
          This is an electronic packing list that describes your samples with
          identifiers
        </MuiFormHelperText>
        <Controller
          name="sampleReagentsManifests"
          control={control}
          rules={{
            validate: {
              validateManifests,
            },
          }}
          render={({ fieldState: { invalid } }) => (
            <FilesUploader
              isEditMode
              uploadedFiles={uploadedFileToShow}
              maxFilesCount={MANIFESTS_MAX_COUNT}
              maxFileSize={MANIFESTS_MAX_SIZE}
              hasError={invalid}
              onUpload={handleFileUpload}
              onRemove={handleFileRemove}
            />
          )}
        />
        <ControlledInput
          name="sampleReagentsManifestLinks"
          type="hidden"
          control={control}
          style={{ display: 'none' }}
        />
        <MuiFormHelperText>
          Allowed types: <strong>xls xlsx xlsm csv</strong> doc docx ods odt odp
          lhc
        </MuiFormHelperText>
        <MuiFormHelperText>
          {MANIFESTS_READABLE_MAX_SIZE} limit per file
        </MuiFormHelperText>
        <MuiFormHelperText>{MANIFESTS_MAX_COUNT} files max</MuiFormHelperText>
      </div>
      {isMalwareScanInProgress && (
        <MuiBox mt={3} display="flex">
          <MuiTypography>Malware scan in progress &nbsp;</MuiTypography>
          <MuiCircularProgress thickness={5} size={20} />
        </MuiBox>
      )}
      <StepperNavigation
        onFormCancelOpen={onFormCancelOpen}
        onFormCancel={onFormCancel}
      >
        <StepperNavButton direction="previous" onClick={handleOnPrevious} />
        <StepperNavButton direction="next" loading={inProgress} />
      </StepperNavigation>
    </form>
  );
};

export default Step3;
