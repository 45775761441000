import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
}));
