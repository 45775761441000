import { FC } from 'react';

import { useHistory } from 'react-router-dom';

import { useCognito } from 'src/aws/Cognito';
import BaseUserEdit from 'src/components/common/users/BaseUserEdit';

const ProfileEdit: FC = () => {
  const history = useHistory();

  const {
    updateUser,
    userData: { attributes },
  } = useCognito();

  const handleCancelEdit = () => {
    history.push('/profile');
  };

  const handleSaveUser = async (
    rawAttributes: { Name: string; Value: string }[]
  ) => {
    const attributes = rawAttributes.reduce((base, nextValue) => {
      return {
        ...base,
        [nextValue.Name]: nextValue.Value,
      };
    }, {});

    try {
      await updateUser({ attributes });
      history.push('/profile');
    } catch (error) {
      console.error('Profile saving failed. ' + error.message);
    }
  };

  return (
    <BaseUserEdit
      userAttributes={attributes || []}
      cancelEdit={handleCancelEdit}
      saveUser={handleSaveUser}
    />
  );
};

export default ProfileEdit;
