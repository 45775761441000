import { FC } from 'react';

import { useHistory } from 'react-router-dom';

import { useCognito } from 'src/aws/Cognito';
import BaseUserView from 'src/components/common/users/BaseUserView';

const Profile: FC = () => {
  const history = useHistory();

  const {
    userData: { attributes },
  } = useCognito();

  const handleEdit = () => {
    history.push('/profile/edit');
  };

  return (
    <BaseUserView
      profile
      userAttributes={attributes || []}
      onEdit={handleEdit}
    />
  );
};

export default Profile;
