import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';

import TextField from '@quanterix-ui/core/TextField';
import Button from '@quanterix-ui/core/Button';
import Select from '@quanterix-ui/core/Select';
import SelectOption from '@quanterix-ui/core/SelectOption';

import { UserStatuses } from 'src/utils/Constants'

import { FilterFields } from './FilterFields';
import './Filter.css';

const Filter = ({
  isFilterApplied,
  isFiltering,
  onFilterUserApplied,
}) => {
  const [filterValue, setUsersFilterValue] = useState('');
  const [filterField, setUsersFilterField] = useState(FilterFields.email.name);

  const onChangeValue = (e) => {
    const { value } = e.target;
    if (filterValue && !value && isFilterApplied) {
      onFilterUserApplied({});
    }
    setUsersFilterValue(value);
  };

  const onChangeField = (e) => {
    const field = e.target.value;
    setUsersFilterField(field);
    if (FilterFields[field].values) {
      setUsersFilterValue(FilterFields[field].values[0].value);
    } else {
      setUsersFilterValue('');
    }
  };

  const filterUserApplied = (e) => {
    e.preventDefault();
    if (!filterValue) return;
    const filter = {
      field: filterField,
      operator: FilterFields[filterField].operator,
      value: filterValue,
    };
    onFilterUserApplied(filter);
  };

  const onClearFilter = () => {
    if (!FilterFields[filterField].values) {
      setUsersFilterValue('');
    }
    onFilterUserApplied({});
  };

  return (
    <Grid item className="UserFilter">
      <Box mt={4}>
        <form onSubmit={filterUserApplied}>
          <Grid container>
            <FormControl
              className="FilterField"
              variant="outlined"
              size="small"
              color="primary"
            >
              <Select value={filterField} renderValue={(key) => FilterFields[key].title} onChange={onChangeField}>
                {Object.keys(FilterFields).map((key) => (
                  <SelectOption key={key} value={FilterFields[key].name}>
                    {FilterFields[key].title}
                  </SelectOption>
                ))}
              </Select>
            </FormControl>
            {FilterFields[filterField].values ? (
              <FormControl
                className="FilterValue"
                variant="outlined"
                size="small"
                color="primary"
              >
                <Select
                  value={filterValue}
                  renderValue={(value) => {
                    const statusEnabledValue = FilterFields[filterField].values.find((x) => x.value === value);
                    return statusEnabledValue?.title || UserStatuses[value] || value
                  }}
                  onChange={onChangeValue}
                >
                  {FilterFields[filterField].values.map((v) => (
                    <SelectOption key={v.value} value={v.value}>
                      {v.title}
                    </SelectOption>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                className="FilterValue"
                placeholder="Search for value"
                value={filterValue}
                onChange={onChangeValue}
              />
            )}
            <Button
              className="FilterButton"
              type="submit"
              loading={isFiltering}
              disabled={isFiltering || !filterValue}
            >
              Search
            </Button>
            <Button
              className="ClearButton"
              variant="outlined"
              disabled={isFiltering}
              onClick={onClearFilter}
            >
              Clear
            </Button>
          </Grid>
        </form>
      </Box>
    </Grid>
  );
}

export default  Filter;