import { CognitoUserEntity } from './typings';

export const checkIfTimeExpired = (expirationTime: number): boolean => {
  if (typeof expirationTime !== 'number') {
    throw new Error('Token validation: token should be a number');
  }

  const currentUnixTime = new Date().getTime() / 1000;

  return currentUnixTime > expirationTime;
};

export const checkUserAccess = (
  user: CognitoUserEntity,
  group: string
): boolean => {
  const userGroups =
    user?.signInUserSession?.accessToken.payload['cognito:groups'];

  return !!userGroups?.find((userGroup: string) => userGroup === group);
};
