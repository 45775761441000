import { FC } from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import ChangePasswordForm from 'src/components/ChangePasswordForm';

const ProfileChangePassword: FC = () => {
  return (
    <Container maxWidth="sm">
      <Box mt={2} mb={5}>
        <Typography variant="h4">Change Password</Typography>
      </Box>
      <ChangePasswordForm />
    </Container>
  );
};

export default ProfileChangePassword;
