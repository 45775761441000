import { CSV_FILE_EXTENTIONS } from 'src/utils/constants/file';

export const DEFAUL_MAX_FILES_SIZE_PER_UPLOAD = 10 * 1024 * 1024 * 1024; // 10 GB
export const DEFAUL_MAX_FILES_COUNT_PER_UPLOAD = 10;
export const DEFAUL_MAX_FILES_COUNT = 1000;

export const ACCEPTABLE_FILE_TYPES = [
  ...CSV_FILE_EXTENTIONS,

  'application/pdf',
  'application/xml',
  'text/xml',
  'application/octet-stream',
  'application/mp4',
  'video/mp4',
  'audio/mp4',
  '.exe',
  '.rpexpe',
];
