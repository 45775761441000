import { FC } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';

import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';

import { FIELD_NAME } from './constants';
import CurrentPassword from './components/CurrentPassword';
import NewPassword from './components/NewPassword';
import ConfirmPassword from './components/ConfirmPassword';

const defaultValues = {
  [FIELD_NAME.currentPassword]: '',
  [FIELD_NAME.newPassword]: '',
  [FIELD_NAME.repeatPassword]: '',
};

const ChangePasswordForm: FC = () => {
  const { isLoading, changePassword } = useCognito();

  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;

  const handleFormSubmit = ({
    currentPassword,
    newPassword,
  }: typeof defaultValues) => {
    changePassword({ currentPassword, newPassword, redirectTo: '/profile' });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container direction="column">
          <CurrentPassword />
          <NewPassword />
          <ConfirmPassword />
          <Grid item>
            <Button type="submit" loading={isLoading} disabled={isLoading}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ChangePasswordForm;
