import { privateGateway } from '../../../config';

interface RequestFileUrlParams {
  file_name: string;
}

interface RequestFileUrlResult {
  download_url: string;
  file_name: string;
  expire: string;
}

export const requestFileUrl = async ({
  file_name,
}: RequestFileUrlParams): Promise<RequestFileUrlResult> => {
  const response = await privateGateway(
    process.env.REACT_APP_SUBMISSION_API_GATEWAY as string
  ).post(`/form/file/download`, { file_name });

  return response.data;
};
