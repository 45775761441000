import { FC } from 'react';

import Box from '@material-ui/core/Box';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Button from '@quanterix-ui/core/Button';

import { BUTTON_DIRECTION, BUTTON_LABEL } from './constants';

interface Props {
  direction: keyof typeof BUTTON_DIRECTION;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const StepperNavButton: FC<Props> = ({
  direction,
  loading = false,
  disabled = false,
  onClick,
}) => {
  const hideIconStyles = { color: loading ? 'transparent' : 'inherit' };

  const isNext = direction === BUTTON_DIRECTION.next;
  const isPrevious = direction === BUTTON_DIRECTION.previous;
  const isSubmit = direction === BUTTON_DIRECTION.submit;
  const isFinish = direction === BUTTON_DIRECTION.finish;

  const variant = isNext || isFinish || isSubmit ? 'contained' : 'outlined';

  const startIcon = isPrevious && !isFinish && !isSubmit && (
    <ChevronLeftIcon style={{ ...hideIconStyles }} />
  );

  const endIcon = isNext && !isFinish && !isSubmit && (
    <ChevronRightIcon style={{ ...hideIconStyles }} />
  );

  const marginRight = isPrevious ? 3 : 0;

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Box mr={marginRight}>
      <Button
        type="submit"
        variant={variant}
        loading={loading}
        disabled={disabled || loading}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={handleClick}
      >
        {BUTTON_LABEL[direction]}
      </Button>
    </Box>
  );
};

export default StepperNavButton;
