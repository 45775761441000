import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-gutterBottom': {
      margin: theme.spacing(0, 0, 1, 0),
    },
    '& .MuiSvgIcon-root': {
      color: 'rgba(0,0,0,0.54)',
    },
    margin: theme.spacing(0, 0, 2, 0),
  },
  formControl: {
    paddingTop: theme.spacing(2),
  },
  password: {
    paddingTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  button: {
    width: 150,
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    marginTop: -12,
    marginLeft: -87,
  },
  boldLink: {
    fontWeight: 600,
  },
  snackbarButton: {
    color: '#fff',
  },
  phoneNumberWrapper: {
    '& .flag-dropdown': {
      borderRadius: '10px 0 0 10px',
    },
  },
}));
