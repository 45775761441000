export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const kbyte = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const index = Math.floor(Math.log(bytes) / Math.log(kbyte));
  const result = parseFloat((bytes / Math.pow(kbyte, index)).toFixed(decimals));

  return `${result} ${sizes[index]}`;
};
