import { FC } from 'react';

import Grid from '@material-ui/core/Grid';

import DocumentsCount from './components/DocumentsCount';

const AdminPages: FC = () => {
  return (
    <Grid container>
      <DocumentsCount />
    </Grid>
  );
};

export default AdminPages;
