import { FC } from 'react';

import Button, { ButtonProps } from '@quanterix-ui/core/Button';

import { useStyles } from './styles';

export interface DownloadButtonProps extends ButtonProps {
  target?: string;
  rel?: string;
}

const DownloadButton: FC<DownloadButtonProps> = ({
  children,
  href,
  target = '_blank',
  rel = 'noopener noreferrer',
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <a download className={classes.link} href={href} target={target} rel={rel}>
      <Button {...restProps}>{children}</Button>
    </a>
  );
};

export default DownloadButton;
