import { FC } from 'react';

import Typography from '@material-ui/core/Typography';

const COAText: FC = () => (
  <p>
    <Typography>
      Please follow this link to search for Certificates of Analysis and Safety
      Data Sheets
      <br />
      <a
        href="https://www.quanterix.com/sds-and-coas/"
        target="_blank"
        rel="noreferrer"
      >
        https://www.quanterix.com/sds-and-coas/
      </a>
    </Typography>
  </p>
);

export default COAText;
