import { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { renderHtml } from 'src/utils/RenderHelper';

const LegalDocument: FC = () => {
  const [htmlContent, setHtmlContent] = useState('');

  const params = useParams<{ name: string }>();

  useEffect(() => {
    const url = `https://${process.env.REACT_APP_BUCKET_NAME}.s3.${process.env.REACT_APP_BUCKET_REGION}.amazonaws.com/public/__Internal/Legal/${params.name}.html`;

    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        setHtmlContent(html);
      });
  }, [params.name]);

  return <div dangerouslySetInnerHTML={renderHtml(htmlContent)} />;
};

export default LegalDocument;
