import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import { IS_PROD_ENV } from 'src/utils/constants/env';
import { useCognito } from 'src/aws/Cognito';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID as string;
const GTM_ID = process.env.REACT_APP_GTM_ID as string;

export const useGaTracker = (): void => {
  const location = useLocation();

  const {
    isAuthenticated,
    userData: { email },
  } = useCognito();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized && IS_PROD_ENV && isAuthenticated) {
      ReactGA.initialize(GA_TRACKING_ID);
      setInitialized(true);
    }
  }, [initialized, isAuthenticated]);

  useEffect(() => {
    if (initialized && isAuthenticated) {
      TagManager.initialize({
        gtmId: GTM_ID,
        dataLayer: {
          user_group: email.includes('quanterix.com') ? 'internal' : 'external',
        },
      });

      ReactGA.set({
        page: location.pathname,
        user_group: email.includes('quanterix.com') ? 'internal' : 'external',
      });
      ReactGA.pageview(location.pathname + location.search);
      ReactGA.timing({
        category: 'Load',
        variable: 'load',
        value: Math.round(performance.now()),
      });
    }
  }, [initialized, isAuthenticated, email, location.pathname, location.search]);
};
