import { ChangeEvent, FC, useEffect, useMemo } from 'react';

import { Controller, useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/high-res.css';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import ControlledSelect from '@quanterix-ui/core/ControlledSelect';
import SelectOption from '@quanterix-ui/core/SelectOption';

import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';
import countries from 'src/utils/countries';
import states from 'src/utils/states';

import {
  validateMaxLength,
  validateSpecialCharacters1,
  validateSpecialCharacters2,
  validateWhitespaces,
} from '../../utils';
import { useManifestsSyncWithForm } from '../../hooks/useManifestsSyncWithForm';
import { AcceleratorFormStepComponentProps } from '../../typings';
import { useStyles } from '../../styles';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

const Step5: FC<AcceleratorFormStepComponentProps> = ({
  inProgress,
  formData,
  isMalwareScanInProgress,
  hasFilesWithMalware,
  onNext,
  onPrevious,
  onFormDataChange,
  onFormCancelOpen,
  onFormCancel,
}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData },
  });

  useManifestsSyncWithForm({
    sampleReagentsManifests: formData.sampleReagentsManifests,
    sampleReagentsManifestLinks: formData.sampleReagentsManifestLinks,
    getValues,
    reset,
  });

  const isSubmitDisabled = useMemo(() => {
    return (
      formData.sampleReagentsManifests.length === 0 ||
      isMalwareScanInProgress ||
      hasFilesWithMalware
    );
  }, [
    formData.sampleReagentsManifests,
    isMalwareScanInProgress,
    hasFilesWithMalware,
  ]);

  const handleOnPrevious = () => {
    const formData = getValues();
    onPrevious(formData);
  };

  const handleChangeSampleReturn = (
    event: ChangeEvent<HTMLInputElement>,
    onChangeCallback: (value: string) => void
  ) => {
    const { value } = event.target;

    if (value === 'Discard') {
      setValue('returnAddress', '');
      setValue('returnAddress2', '');
      setValue('returnCity', '');
      setValue('returnState', '');
      setValue('returnZip', '');
      setValue('returnCountry', '');
      setValue('shippingAndHandling', '');
    }

    onChangeCallback(value);
  };

  const onSubmit = (data: AcceleratorFormSchema) => {
    onNext(data);
  };

  const allFieldsData = watch();

  useEffect(() => {
    onFormDataChange(allFieldsData);
  }, [allFieldsData, onFormDataChange]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.formControl}>
        <FormLabel component="legend">
          Please address packages as follows:
        </FormLabel>
        <br />
        <h2>
          QUANTERIX <br />
          CRO &amp; LABORATORY SERVICES <br />
          900 MIDDLESEX TPKE, BUILDING 1 <br />
          BILLERICA, MA 01821
        </h2>
        <br />
      </div>
      <div className={classes.formControl}>
        <FormLabel required component="legend" error={!!errors.sampleReturn}>
          Sample Return
        </FormLabel>
        <FormHelperText>
          If "Return Upon Completion" is selected, you will be asked to enter
          shipping carrier payment account information.
        </FormHelperText>
        <Controller
          name="sampleReturn"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <RadioGroup
              value={value}
              onChange={(e) => handleChangeSampleReturn(e, onChange)}
            >
              <FormControlLabel
                value="Return"
                label="Return Upon Completion"
                control={<Radio />}
              />
              <FormControlLabel
                value="Discard"
                label="Discard Upon Completion"
                control={<Radio />}
              />
            </RadioGroup>
          )}
        />
      </div>
      {allFieldsData.sampleReturn === 'Return' && (
        <>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Address
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="returnAddress"
              control={control}
              rules={{
                required: true,
                ...validateMaxLength(80),
                ...validateSpecialCharacters1,
                validate: {
                  validateWhitespaces,
                },
              }}
              helperText={errors.returnAddress?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel component="legend">Address 2</FormLabel>
            <ControlledTextField
              fullWidth
              name="returnAddress2"
              control={control}
              rules={{
                ...validateMaxLength(80),
                ...validateSpecialCharacters1,
                validate: {
                  validateWhitespaces,
                },
              }}
              helperText={errors.returnAddress2?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              City/Town
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="returnCity"
              control={control}
              rules={{
                required: true,
                ...validateMaxLength(80),
                ...validateSpecialCharacters2,
                validate: {
                  validateWhitespaces,
                },
              }}
              helperText={errors.returnCity?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel component="legend">State</FormLabel>
            <ControlledSelect
              fullWidth
              displayEmpty
              name="returnState"
              placeholder="Select state"
              control={control}
              rules={{
                ...validateMaxLength(20),
                ...validateSpecialCharacters2,
                validate: {
                  validateWhitespaces,
                },
              }}
            >
              <SelectOption value="">Select state</SelectOption>
              {states.map((state) => (
                <SelectOption key={state.name} value={state.name}>
                  {state.name}
                </SelectOption>
              ))}
            </ControlledSelect>
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Zip
            </FormLabel>
            <ControlledTextField
              fullWidth
              name="returnZip"
              control={control}
              rules={{
                required: true,
                ...validateMaxLength(20),
                ...validateSpecialCharacters2,
                validate: {
                  validateWhitespaces,
                },
              }}
              helperText={errors.returnZip?.message}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Country
            </FormLabel>
            <ControlledSelect
              fullWidth
              displayEmpty
              name="returnCountry"
              placeholder="Select country"
              control={control}
              rules={{
                required: true,
                ...validateMaxLength(80),
                ...validateSpecialCharacters2,
                validate: {
                  validateWhitespaces,
                },
              }}
            >
              <SelectOption value="">Select country</SelectOption>
              {countries.map((country) => (
                <SelectOption key={country.name} value={country.name}>
                  {country.name}
                </SelectOption>
              ))}
            </ControlledSelect>
          </div>
          <div className={classes.formControl}>
            <FormLabel required component="legend">
              Shipping and Handling
            </FormLabel>
            <FormHelperText>
              Please provide shipping carrier account information for sample
              return.
            </FormHelperText>
            <ControlledTextField
              fullWidth
              multiline
              name="shippingAndHandling"
              rows={5}
              control={control}
              rules={{
                required: true,
                ...validateMaxLength(40),
                ...validateSpecialCharacters2,
                validate: {
                  validateWhitespaces,
                },
              }}
              helperText={errors.shippingAndHandling?.message}
            />
          </div>
        </>
      )}
      <StepperNavigation
        onFormCancelOpen={onFormCancelOpen}
        onFormCancel={onFormCancel}
      >
        <StepperNavButton direction="previous" onClick={handleOnPrevious} />
        <StepperNavButton
          direction="submit"
          loading={inProgress}
          disabled={isSubmitDisabled}
        />
      </StepperNavigation>
    </form>
  );
};

export default Step5;
