import { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ProtectedContainer from 'src/layouts/ProtectedContainer';

import DownloadManifestButton from '../DownloadManifestButton';
import UploadManifestButton from '../UploadManifestButton';

const ManifestTemplateActions: FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mt={4} mb={1}>
          <Typography>
            <strong>Preferred template</strong> for submission can be downloaded
            from the link here:
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box mr={4} mb={4}>
          <DownloadManifestButton />
        </Box>
      </Grid>
      <Grid item>
        <ProtectedContainer groups={['admins']}>
          <Box mb={4}>
            <UploadManifestButton />
          </Box>
        </ProtectedContainer>
      </Grid>
    </Grid>
  );
};

export default ManifestTemplateActions;
