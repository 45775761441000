import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    padding: 5,
    paddingLeft: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],

    '&.hasTextField': {
      paddingLeft: 5,
    },

    '&.hasError': {
      borderColor: theme.palette.error.main,
    },
  },

  textfield: {
    '& *': {
      border: 'none !important',
    },
  },

  actionButton: {
    minWidth: 'auto !important',
  },
}));
