import { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmationDialog(props) {
  const [open, setOpen] = useState(false);
  const text = props.text || '';
  const title = props.title || '';

  useEffect(() => {
    setOpen(!!props.open);
  }, [props.open]);

  const handleConfirm = () => {
    setOpen(false);

    if (props.onConfirm) {
      props.onConfirm(true);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        maxWidth="xs"
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
