export const PERSONAL_INFO_FIELDS = [
  { Label: 'First Name', Attribute: 'given_name' },
  { Label: 'Last Name', Attribute: 'family_name' },
  { Label: 'Company', Attribute: 'custom:company' },
  { Label: 'Email', Attribute: 'email' },
  { Label: 'Website', Attribute: 'website' },
  { Label: 'Phone', Attribute: 'phone_number' },
];

export const ADDRESS_FILEDS = [
  { Label: 'Address 1', Attribute: 'street_address' },
  { Label: 'Address 2', Attribute: 'street_address2' },
  { Label: 'City', Attribute: 'locality' },
  { Label: 'State/Province', Attribute: 'region' },
  { Label: 'Zip/Postal Code', Attribute: 'postal_code' },
  { Label: 'Country', Attribute: 'country' },
];

export const DEFAULT_USER_FORM_VALUES = {
  email: '',
  family_name: '',
  given_name: '',
  phone_number: '',
  company: '',
  instrums: [],
  accelerator_services: false,
  website: '',
  address_country: '',
  address_postal_code: '',
  address_region: '',
  address_locality: '',
  address_street: '',
  address_street2: '',
};
