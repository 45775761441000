import React, { useState, useEffect, useMemo } from "react";
import "./EditableAwsTreeView.css";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  Collapse,
  ListItemIcon,
  Badge,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  treeS3Items,
  createFolder,
  updateFileContent,
  createS3TreeViewElement,
  removeFromS3
} from "src/utils/S3Helper";
import * as Constants from "src/utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from 'react-router-dom';
import arrayMove from "array-move";
import ConfirmationDialog from "src/components/confirmation-dialog/ConfirmationDialog";
import { getFileName } from "src/utils/StringHelper";

import CreatePagePopover from './components/CreatePagePopover'

export default function EditableAwsTreeView() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalAnchorEl, setModalAnchorEl] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [anchorElOpt, setAnchorElOpt] = useState(null);
  const [anchorElVsSel, setAnchorElVsSel] = useState(null);
  const [treeViewItems, setTreeViewItems] = useState([]);
  const [checked, setChecked] = useState([]);
  const [isModalValueRequired, setModalValueRequired] = useState(false);
  const [isModalValueValid, setModalValueValid] = useState(true);
  const [modalState, setModalState] = useState({
    parentName: "",
    type: "",
    opened: false,
  });
  const [toDelete, setToDelete] = useState("");
  const [modalValue, setModalValue] = useState("");

  const history = useHistory();
  const location = useLocation();

  const currentLocation = useMemo(() => {
    if (history.action === 'POP') {
      return encodeURI(location.pathname) + decodeURI(location.hash);
    }

    return decodeURI(encodeURI(location.pathname)) + decodeURI(location.hash);
  }, [location.pathname, location.hash, history.action]);

  useEffect(() => {
    getNodes();
  }, []);

  const getNodes = async () => {
    const items = await treeS3Items('');
    updateTree(items, false);
  };

  const handlePagesMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePagesMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptMenuClick = (event, itemName) => {
    setAnchorElOpt(event.currentTarget);
    setCurrentItem(itemName);
  };

  const handleOptMenuClose = () => {
    setAnchorElOpt(null);
    setCurrentItem("");
  };

  const handleVsSelMenuClick = (event) => {
    setAnchorElVsSel(event.currentTarget);
  };

  const handleVsSelMenuClose = () => {
    setAnchorElVsSel(null);
  };

  const handleAddPage = (e, parent = "") => {
    setModalValueRequired(false)
    setModalAnchorEl(e.currentTarget);
    setModalState({ parentName: parent, type: "Page", opened: true });
  };

  const handleAddRootFolder = () => {
    setModalValueRequired(false)
    setModalState({ parentName: "", type: "Folder", opened: true });
  };

  const handleDeleteCurrent = async () => {
    const folder = currentItem;
    removeFromList([currentItem]);
    setCurrentItem("");
    handleOptMenuClose();
    await removeFromS3(folder + "/");  
  };

  const removeFromList = (items) => {
    let newTreeViewItems = Object.assign([], treeViewItems);
    items.forEach((item) => {
      if (item.includes("/")) {
        const parts = item.split("/");
        const index = newTreeViewItems.findIndex((x) => x.name === parts[0]);
        if (index < 0) return;
        const childIndex = newTreeViewItems[index].children.findIndex(
          (x) => x.name === parts[1]
        );
        if (childIndex < 0) return;
        newTreeViewItems[index].children.splice(childIndex, 1);
        return;
      }
      const index = newTreeViewItems.findIndex((x) => x.name === item);
      if (index < 0) return;
      newTreeViewItems.splice(index, 1);
    });
    updateTree(newTreeViewItems);
  };

  const handleMove = (path, direction) => {
    const index = treeViewItems.findIndex((x) => x.name === path);
    const newIndex = index + direction;
    if (index >= 0 && newIndex >= 0) {
      const newArray = arrayMove(treeViewItems, index, index + direction);
      updateTree(newArray);
    }
  };

  const handleChildMove = (parent, child, direction) => {
    const index = treeViewItems.findIndex((x) => x.name === parent);
    if (index >= 0) {
      const chIndex = treeViewItems[index].children.findIndex(
        (x) => x.name === child
      );
      const newIndex = chIndex + direction;
      if (chIndex >= 0 && newIndex >= 0) {
        treeViewItems[index].children = arrayMove(
          treeViewItems[index].children,
          chIndex,
          chIndex + direction
        );
        updateTree([...treeViewItems]); //TMP fix to redraw tree
      }
    }
  };

  const handleMoveCurrent = (direction) => {
    const pathParts = currentItem.split("/");
    if (pathParts.length > 1) {
      handleChildMove(pathParts[0], pathParts[1], direction);
    } else {
      handleMove(pathParts[0], direction);
    }
  };

  const updateOrder = async (tree) => {
    const data = tree.map((x) => {
      return { node: x.name, children: x.children.map((c) => c.name) };
    });
    await updateFileContent(Constants.OrderFile, JSON.stringify(data));
  };

  const updateTree = (tree, save = true) => {
    if (save) {
      updateOrder(tree);
    }
    setTreeViewItems(tree);
  };

  const handleDeleteConfirm = async (confirmation) => {
    if(confirmation)
    {
      if(currentItem && currentItem.length > 0){
        await handleDeleteCurrent();
      } else  if(checked && checked.length > 0){
        await handleDeleteSelected();
      }
    }
    setToDelete('');

  }

  const handleDeleteSelected = async () => {
    
    const childToDelete = checked.filter((x) => x.includes("/"));
    const rootToDelete = checked.filter((x) => !x.includes("/"));

    removeFromList(checked);
    setChecked([]);
    handleVsSelMenuClose();

    await Promise.all(childToDelete.map((x) => removeFromS3(x + "/")));
    await Promise.all(rootToDelete.map((x) => removeFromS3(x + "/")));
   
  };

  const handleLeftMenuClick = (item) => {
    if (isFolder(item)) {
      let newItems = Object.assign([], treeViewItems);
      const index = newItems.findIndex(
        (x) => x.name === item.name && x.id === item.id
      );
      newItems[index].opened = !item.opened;
      setTreeViewItems(newItems, false);
    } else {
      handlePageClick("", item);
    }
  };

  const handlePageClick = (parentName, item) => {
    let path = item.name;
    if ((parentName?.length ?? 0) > 0) {
      path = parentName + "/" + item.name;
    }
    const encodedUrl = encodeURI(encodeURI(`/admin/pages/folder-editor/${path}`));
    history.push(encodedUrl);
  };

  const isFolder = (item) => {
    return item.isFolder;
  };

  const isAnyChecked = () => {
    return checked.length > 0;
  };

  const changeModalValue = (value) => {
    setModalValue(value);
  };

  const handleCloseModal = () => {
    setModalState({ parentName: "", type: "", opened: false });
    setModalValue("");
    setModalValueRequired(false)
    setModalValueValid(true)
  };

  const handleCreateFromModal = async () => {
    if (!modalValue) {
      setModalValueRequired(true)
      return;
    }
    if (modalValue.indexOf('#') > -1) {
      setModalValueValid(false)
      return;
    }
    const path =
      (modalState.parentName.length > 0 ? modalState.parentName + "/" : "") +
      modalValue +
      "/";
    await createFolder(path);
    if (modalState.type === "Page") {
      await updateFileContent(path + Constants.DefaultPropertiesFile, "{}");
    }

    let newItems = Object.assign([], treeViewItems);
    let item = createS3TreeViewElement(
      modalValue,
      modalValue,
      modalState.type !== "Page"
    );
    if (modalState.parentName.length > 0) {
      const index = newItems.findIndex((x) => x.name === modalState.parentName);
      newItems[index].children.push(item);
    } else {
      newItems.push(item);
    }

    setTreeViewItems(newItems);
    if (modalState.type === "Page") {
      handlePageClick(modalState.parentName, item)
    }
    handleCloseModal();
    handlePagesMenuClose();
  };

  const checkParentIfNeeded = (checkedItems, itemName, isChecked) => {
    if (isChecked) {
      const item = treeViewItems.find((x) => x.name === itemName);
      if (
        item.children.length ===
        item.children.filter((child) => {
          let path = itemName + "/" + child.name;
          return checkedItems.indexOf(path) >= 0;
        }).length
      ) {
        checkedItems.push(itemName);
      }
    } else {
      const index = checkedItems.findIndex((x) => x === itemName);
      if (index >= 0) {
        checkedItems.splice(index, 1);
      }
    }
  };

  const getStyleClass = (item, parentName) => {
    if (item.isFolder) {
      return 'EditableMenu';
    }

    let path = item.name;
    if ((parentName?.length ?? 0) > 0) {
      path = parentName + '/' + item.name
    }

    const selectedLocation = encodeURI(`/admin/pages/folder-editor/${path}`);
    return (currentLocation === selectedLocation) ? 'EditableMenu Selected' : 'EditableMenu';
  }

  const checkAllChildren = (checkedItems, itemName, isChecked) => {
    const item = treeViewItems.find((x) => x.name === itemName);
    item.children.forEach((child) => {
      let path = itemName + "/" + child.name;
      const index = checkedItems.findIndex((x) => x === path);
      if (index < 0 && isChecked) {
        checkedItems.push(path);
      } else if (index >= 0 && !isChecked) {
        checkedItems.splice(index, 1);
      }
    });
  };

  const onMenuChecked = (parentName, itemName) => {
    let checkedItems = Object.assign([], checked);
    let path = itemName;
    if (parentName.length > 0) {
      path = parentName + "/" + itemName;
    }
    const index = checkedItems.indexOf(path);
    if (index < 0) {
      checkedItems.push(path);
      if (parentName.length === 0) {
        checkAllChildren(checkedItems, itemName, true);
      } else {
        checkParentIfNeeded(checkedItems, parentName, true);
      }
    } else {
      checkedItems.splice(index, 1);
      if (parentName.length === 0) {
        checkAllChildren(checkedItems, itemName, false);
      } else {
        checkParentIfNeeded(checkedItems, parentName, false);
      }
    }

    setChecked(checkedItems);
  };

  const isMenuChecked = (parentName, itemName) => {
    let path = itemName;
    if (parentName.length > 0) {
      path = parentName + "/" + itemName;
    }
    return checked.indexOf(path) >= 0;
  };

  const renderPage = (item, parentName) => {
    return (
      <ListItem key={parentName + "_" + item.name} className={getStyleClass(item, parentName)}>
        <ListItemIcon>
          <Checkbox
            checked={isMenuChecked(parentName, item.name)}
            onChange={() => onMenuChecked(parentName, item.name)}
          />
        </ListItemIcon>
        <ListItemText onClick={() => handlePageClick(parentName, item)}>
          {item.name}
        </ListItemText>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => handleOptMenuClick(e, parentName + "/" + item.name)}
        >
          <MoreVertIcon className="MoreVert"/>
        </IconButton>
      </ListItem>
    );
  };

  const renderRoots = (item) => {
    const isItemFolder = isFolder(item);

    return (
      <div key={"div_" + item.name}>
        <ListItem key={"root_" + item.name} className={getStyleClass(item, '')}>
          <ListItemIcon>
            <Checkbox
              checked={isMenuChecked("", item.name)}
              onChange={() => onMenuChecked("", item.name)}
            />
          </ListItemIcon>
          <ListItemText onClick={() => handleLeftMenuClick(item)}>
            {item.name}
            {isItemFolder && (
              item.opened ? (
                <ExpandLess className="ExpColl" />
              ) : (
                <ExpandMore className="ExpColl" />
              )
            )}
          </ListItemText>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(e) => handleOptMenuClick(e, item.name)}
          >
            <MoreVertIcon className="MoreVert" />
          </IconButton>
        </ListItem>
        {isItemFolder && (
          <Collapse
            key={"collapse_" + item.name}
            in={item.opened}
            timeout="auto"
            unmountOnExit
            className="NestedList"
          >
            <List component="div" disablePadding>
              <ListItem>
                <ListItemText
                  className="AddPageLink"
                  onClick={(e) => handleAddPage(e, item.name)}
                >
                  + Add Page
                </ListItemText>
              </ListItem>
              {item.children.map((x) => renderPage(x, item.name))}
            </List>
          </Collapse>
        )}
      </div>
    );
  };

  return (
    treeViewItems.length === 0
      ? <LinearProgress />
      : <>
        <List>
          <ListItem>
            <ListItemText>
              <Button
                onClick={handlePagesMenuClick}
                variant="contained"
                className="QuanButton MenuButton"
              >
                Add Page/Folder &nbsp;
                <FontAwesomeIcon className="sortDown" icon={faSortDown} />
              </Button>
              <Menu
                id="pages-create-menu"
                anchorEl={anchorEl}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handlePagesMenuClose}
                className="MenuVsShadow"
              >
                <MenuItem
                  className="EditPagesMenuItem"
                  onClick={(e) => handleAddPage(e, "")}
                >
                  + Add Page
              </MenuItem>
                <MenuItem
                  className="EditPagesMenuItem"
                  onClick={handleAddRootFolder}
                >
                  + Add Folder
              </MenuItem>
              </Menu>
              <Button
                disabled={!isAnyChecked()}
                onClick={handleVsSelMenuClick}
                variant="text"
                className="QuanButton VsSelected"
              >
                <Badge
                  className="QuanBadge"
                  color="secondary"
                  badgeContent={checked.length}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  With Selected
              </Badge> &nbsp;
                <FontAwesomeIcon className="sortDown" icon={faSortDown} />
              </Button>
              <Menu
                id="with-selected-menu"
                anchorEl={anchorElVsSel}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                open={Boolean(anchorElVsSel)}
                onClose={handleVsSelMenuClose}
                className="MenuVsShadow"
              >
                <MenuItem
                  className="EditPagesMenuItem"
                  onClick={() => setToDelete(`${checked.length} items`)}
                >
                  Delete
              </MenuItem>
              </Menu>
            </ListItemText>
          </ListItem>
          {treeViewItems.map(renderRoots)}
        </List>
        <CreatePagePopover
          value={modalValue}
          isOpen={modalState.opened}
          isValueRequired={isModalValueRequired}
          isValueValid={isModalValueValid}
          variant={modalState.type}
          anchorEl={modalAnchorEl}
          onChange={changeModalValue}
          onCreate={handleCreateFromModal}
          onCancel={handleCloseModal}
        />
        <Menu
          id="options-menu"
          anchorEl={anchorElOpt}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          open={Boolean(anchorElOpt)}
          onClose={handleOptMenuClose}
          className="MenuVsShadow"
        >
          <MenuItem
            className="EditPagesMenuItem"
            onClick={() => handleMoveCurrent(-1)}
          >
            Move Up
        </MenuItem>
          <MenuItem
            className="EditPagesMenuItem"
            onClick={() => handleMoveCurrent(+1)}
          >
            Move Down
        </MenuItem>
          <MenuItem className="EditPagesMenuItem" onClick={() => setToDelete(getFileName(currentItem))}>
            Delete
        </MenuItem>
        </Menu>
        <ConfirmationDialog
          open={toDelete.length > 0}
          title="Delete confirmation"
          text={`Please confirm you want to delete ${toDelete}`}
          onConfirm={handleDeleteConfirm}
        />
      </>
  );
}
