import { FC } from 'react';

import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import {
  QUANTERIX_INFO_EMAIL,
  SUPPORT_PHONE_NUMBER,
} from 'src/utils/constants/contacts';

import './AppFooter.css';

const AppFooter: FC = () => {
  return (
    <Grid className="AppFooter">
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <span className="QuanBig">
            Quanterix <FontAwesomeIcon icon={faCopyright} />{' '}
            {new Date().getFullYear()}
          </span>
        </Grid>
        <Grid item xs={9}></Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={3}>
          Quanterix and Simoa are trademarks of Quanterix Corporation.
        </Grid>
        <Grid item xs={3}>
          900 Middlesex Turnpike <br /> Billerica, MA 01821 USA
        </Grid>
        <Grid item xs={3}>
          {SUPPORT_PHONE_NUMBER}
          <br />
          <a href={`mailto:${QUANTERIX_INFO_EMAIL}`}>{QUANTERIX_INFO_EMAIL}</a>
        </Grid>
        <Grid item xs={3}>
          <Link to="/legal/privacy-policy">Privacy policy</Link> <br />
          <Link to="/legal/terms-conditions">Terms & conditions</Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppFooter;
