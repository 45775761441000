import { Storage } from "aws-amplify";
import { isEmpty } from "./StringHelper";
import { distinct, orderArray } from "./ArrayHelper";
import * as Constants from "./Constants";
import { jsonParseSafe } from "./ParseHelper";
import { trimChar, getFileExtension } from "./StringHelper";
import AWS from 'aws-sdk'
import AwsConfigure from 'src/config/AwsSdkConfig'
import { pathJoin } from './StringHelper'

export function showFileInBrowser(fileExtension) {
  return [ "pdf", "jpg", "gif", "bmp", "png" ].indexOf(fileExtension.toLowerCase()) >= 0;
}

export async function readFile(
  path,
  level = "public",
  bucket = process.env.REACT_APP_BUCKET_NAME
) {
  try {
    const data = await Storage.get(path, {
      level: level,
      download: true,
      bucket: bucket,
    });
    return data.Body.toString();
  } catch (error) {
    // console.error(error);
    return null;
  }
}

export async function getLink(
  path,
  level = "public",
  bucket = process.env.REACT_APP_BUCKET_NAME,
  expires = 604800 // 1 week
) {
  try {
    const fileExtension = getFileExtension(path);
    const config = {
      level: level,
      expires: expires,
      bucket: bucket
    };
    if (!showFileInBrowser(fileExtension)) {
      config.contentDisposition = 'attachment';
    }
    const data = await Storage.get(path, config);
    return data.toString();
  } catch (error) {
    // console.error(error);
    return null;
  }
}

export async function createFolder(
  path,
  level = "public",
  bucket = process.env.REACT_APP_BUCKET_NAME
) {
  try {
    //folder name should end with  forward slash ("/") character
    let folderName = path;
    if (
      folderName &&
      folderName.length > 0 &&
      folderName[folderName.length - 1] !== "/"
    ) {
      folderName += "/";
    }
    await Storage.put(folderName, "", {
      level: level,
      bucket: bucket,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

/**
 *folder should be created explicitly before uploading files, use  createFolder
 */
export async function uploadFile({
  path,
  file,
  user_id = '', // should be a string, otherwise AWS SDK will throw an error
  source = '', // should be a string, otherwise AWS SDK will throw an error
  level = 'public',
  bucket = process.env.REACT_APP_BUCKET_NAME,
}) {
  try {
    const result = await Storage.put(path, file, {
      level: level,
      contentType: file.type,
      bucket: bucket,
      metadata: { user_id, source }
    });
    if (result.key) {
      return pathJoin([bucket, level, result.key])
    } else {
      return false
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function updateFileContent(
  s3path,
  data,
  level = "public",
  bucket = process.env.REACT_APP_BUCKET_NAME
) {
  try {
    await Storage.put(s3path, data, { level: level, bucket: bucket });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function removeFromS3(
  s3path,
  level = "public",
  bucket = process.env.REACT_APP_BUCKET_NAME
) {
  try {
    let childToDelete = await listS3Items(s3path, level, bucket);
    await Promise.all(
      childToDelete.map((x) =>
        Storage.remove(x.key, { level: level, bucket: bucket })
      )
    );
    await Storage.remove(s3path, { level: level, bucket: bucket });
    childToDelete = await listS3Items(trimChar(s3path, "/"), level, bucket);
    await Promise.all(
      childToDelete.map((x) =>
        Storage.remove(x.key, { level: level, bucket: bucket })
      )
    );
    await Storage.remove(trimChar(s3path, "/"), {
      level: level,
      bucket: bucket,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

const listObjects = async (  
  resultList, // This is the list of combined results from all calls.
  bucket,
  path,
  NextContinuationToken // Tells S3 to get the next page of results.
) => {
await AwsConfigure();
const s3 = new AWS.S3({ apiVersion: '2006-03-01', region: 'us-east-2' });
// This function will return a Promise
return new Promise( (resolve, reject) => {  
  // initialize an empty result list on the first call
  let list = resultList ? resultList : [] 
  const params = {
      // a publicly accessible bucket for this example
      Bucket: bucket, 
      // default is MaxKeys is 1000
      // using 3 here to demonstrate use of recursion for multiple pages
      // MaxKeys: 500,
      Prefix: path,
      ContinuationToken: NextContinuationToken
  };

  const request = s3.listObjectsV2(params);
  request.send((err, data) => {
      if (err) {
          reject(err)
      } else {
          // combine the master list with the result of this function call.
          list = list.concat(data.Contents) 
          if(data.IsTruncated) {
              // if the data is truncated then call this function recursively
              listObjects(list, bucket, path, data.NextContinuationToken).then( (list) => {
                  resolve(list)
              })
          } else {
              // The termination condition for the recursion has been met,
              // (There are no more pages left in the bucket) so we resolve here. 
              resolve(list)
          }
      }
  })
})

}

export async function listS3Items(
  path,
  level = "public",
  bucket = process.env.REACT_APP_BUCKET_NAME
) {
  try {
    const list = await listObjects([], bucket, level + '/' + path);
    for (const item of list) {
      for (const key in item) {
        item[key.toLowerCase()] = item[key]
        delete item[key];
      }
      item.key = item.key.replace(level + '/', '');
    }
    return list.filter((x) => !x.key.startsWith("__Internal/"));
  } catch (error) {
    if (error.name === 'RequestTimeTooSkewed') {
      throw error;
    }

    return [];
  }
}

export async function getLinkIfExists(
  path,
  level = "public",
  bucket = process.env.REACT_APP_BUCKET_NAME
) {
  try {
    const list = await Storage.list(path, { level: level, bucket: bucket });
    if (list && list.length > 0) {
      return getLink(path, level, bucket);
    }
    return null;
  } catch (error) {
    // console.error(error);
    return null;
  }
}

export async function treeS3Items(path, {
  level = "public",
  bucket = process.env.REACT_APP_BUCKET_NAME,
  includeEmptyFolders = true,
} = {}) {
  const listItems = await listS3Items(path, level, bucket);
  const order = jsonParseSafe(await readFile(Constants.OrderFile), null);
  return buildFolderTree({
    nodes: listItems, 
    order, 
    includeEmptyFolders
  });
}

function buildFolderTree({
  nodes, 
  order, 
  includeEmptyFolders
}) {
  let result = [];

  nodes.forEach(
    (node) =>
      (node.pathParts = node.key
        .split("/")
        .filter((x) => !isEmpty(x))
        .map((x) => x.trim()))
  );

  const topLevelItems = nodes
    .filter((node) => node.pathParts.length > 0 && node.key !== "_order.json")
    .map((node) => node.pathParts[0])
    .filter(distinct);

  topLevelItems.forEach((topItem) => {

    const isPage = nodes.some(node => 
      node.pathParts.length > 1
      && node.pathParts[0] == topItem
      && node.pathParts[1] == Constants.DefaultPropertiesFile);

    const treeNode = createS3TreeViewElement(
      topItem,
      topItem,
      !isPage
    );

    if (!isPage) {
      const pages = nodes
        .filter(
          (node) =>
            node.pathParts[0] === topItem &&
            (node.pathParts.length > 2 || // sub item is folder if it has children
              (node.pathParts.length === 2 && node.size === 0))
        ) // or if it's size is 0
        .map((node) => node.pathParts[1])
        .filter(distinct);

      const folderHasAtLeastOneChildFile = pages.some(
        page => checkAtLeastOneChildFile({ 
          folder: topItem, 
          page, 
          nodes 
        })
      );

      if (folderHasAtLeastOneChildFile || includeEmptyFolders) {
        treeNode.children = pages.map((item) =>
          createS3TreeViewElement(item, `${topItem}/${item}`, false)
        );

        result.push(treeNode);
      }
    } else {
      const pageHasAtLeastOneChildFile = checkAtLeastOneChildFile({ 
        folder: topItem, 
        page: "", // not needed if page is in the root
        nodes 
      });

      if (pageHasAtLeastOneChildFile || includeEmptyFolders) {
        result.push(treeNode);
      }
    }
  });

  if (order) {
    result = orderArray(
      result,
      (x) => x.name,
      order,
      (o) => o.node
    );
    for (let index = 0; index < result.length; index++) {
      var orderIndex = order.findIndex((x) => x.node === result[index].name);
      if (orderIndex >= 0) {
        result[index].children = orderArray(
          result[index].children,
          (x) => x.name,
          order[orderIndex].children,
          (o) => o
        );
      }
    }
  }

  return result;
}

const checkAtLeastOneChildFile = ({
  folder, 
  page, 
  nodes
}) => {
  return nodes.some(node => {
    if (node.key.indexOf(`${folder}/${page}`) === 0) {
      const lastPart = node.key.split('/').pop();
      return lastPart.indexOf('.') > -1 &&  // is file
        Constants.AllDefaultFiles.indexOf(lastPart) === -1 && // isn't a system file
        lastPart.indexOf(Constants.FileStatuses.New) === -1 && // isn't in a New state
        lastPart.indexOf(Constants.FileStatuses.Pending) === -1 // isn't in a Pending state
    } else {
      return false;
    }
  });
};

export async function publishFile (s3path,
  level = "public",
  bucket = process.env.REACT_APP_BUCKET_NAME 
) {
  await AwsConfigure();
  const s3 = new AWS.S3({ apiVersion: '2006-03-01', region: 'us-east-2' });

  const newS3Path = s3path.replace(Constants.FileStatuses.New, '').replace(Constants.FileStatuses.Pending, '')
  const copySource = bucket + '/' + level + '/' + s3path;
  const destinationSource = level + '/' + newS3Path
  await s3.copyObject({
    Bucket: bucket,
    CopySource: encodeURIComponent(copySource),
    Key: destinationSource
  }).promise()

  const deleteSource = level + '/' + s3path;
  await s3.deleteObject({
    Bucket: bucket,
    Key: deleteSource
  }).promise()

  return newS3Path
}

export async function requireApprovalFile (s3path,
  level = "public",
  bucket = process.env.REACT_APP_BUCKET_NAME 
) {
  await AwsConfigure();
  const s3 = new AWS.S3({ apiVersion: '2006-03-01', region: 'us-east-2' });

  const newS3Path = s3path.replace(Constants.FileStatuses.New, Constants.FileStatuses.Pending)

  const copySource = bucket + '/' + level + '/' + s3path;
  const destinationSource = level + '/' + newS3Path

  await s3.copyObject({
    Bucket: bucket,
    CopySource: encodeURIComponent(copySource),
    Key: destinationSource
  }).promise()

  const deleteSource = level + '/' + s3path;
  await s3.deleteObject({
    Bucket: bucket,
    Key: deleteSource
  }).promise()

  return newS3Path
}

export function createS3TreeViewElement(name, id, isFolder) {
  return { name, id, isFolder, opened: false, children: [] };
}
