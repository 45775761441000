import { ValidationValueMessage } from 'react-hook-form';

interface ValidatePatternResult {
  pattern: ValidationValueMessage<RegExp>;
}

interface ValidateMaxLengthResult {
  maxLength: ValidationValueMessage<number>;
}

export const validateIsNumber = (value: string): string | boolean => {
  return !value || /^\d+$/.test(value) || 'Should be a number';
};

export const validateWhitespaces = (value: string): string | boolean => {
  return !value || !!(value && value.trim()) || 'Whitespace is not allowed';
};

export const validateMaxLength = (
  maxValue: number
): ValidateMaxLengthResult => ({
  maxLength: {
    value: maxValue,
    message: `Max length of this value is limited to ${maxValue}`,
  },
});

export const validateManifests = (value: string[]): string | boolean => {
  return !value || value.length > 0 || 'At least one file should be uploaded';
};

export const validateSpecialCharacters1: ValidatePatternResult = {
  pattern: {
    value: /^[^'"[\]|:\\&+$]+$/,
    message: `Following characters are not allowed: ', ", [, ], |, :, \\, &, +, $`,
  },
};

export const validateSpecialCharacters2: ValidatePatternResult = {
  pattern: {
    value: /^[^|$]+$/,
    message: 'Following characters are not allowed: |, $',
  },
};
