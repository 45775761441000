import { FC } from 'react';

import { SnackbarProvider as NotistackProvider } from 'notistack';

import { useStyles } from './styles';

const SnackbarProvider: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <NotistackProvider
      preventDuplicate
      classes={{
        variantError: classes.error,
        variantSuccess: classes.success,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
