import { FC, useEffect } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import cx from 'classnames';
import 'react-phone-input-2/lib/high-res.css';

import FormLabel from '@material-ui/core/FormLabel';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import ControlledSelect from '@quanterix-ui/core/ControlledSelect';
import SelectOption from '@quanterix-ui/core/SelectOption';

import countries from 'src/utils/countries';
import states from 'src/utils/states';
import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';
import ControlledEmailTextField from 'src/components/elements/ControlledEmailTextField';

import {
  validateMaxLength,
  validateSpecialCharacters1,
  validateSpecialCharacters2,
  validateWhitespaces,
} from '../../utils';
import { useManifestsSyncWithForm } from '../../hooks/useManifestsSyncWithForm';
import { AcceleratorFormStepComponentProps } from '../../typings';
import { useStyles } from '../../styles';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

const Step1: FC<AcceleratorFormStepComponentProps> = ({
  onNext,
  formData,
  onFormDataChange,
  onFormCancelOpen,
  onFormCancel,
}) => {
  const classes = useStyles();

  const formMethods = useForm({ defaultValues: formData });

  const {
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = formMethods;

  const allFieldsData = watch();

  useEffect(() => {
    onFormDataChange(allFieldsData);
  }, [allFieldsData, onFormDataChange]);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  useManifestsSyncWithForm({
    sampleReagentsManifests: formData.sampleReagentsManifests,
    sampleReagentsManifestLinks: formData.sampleReagentsManifestLinks,
    getValues,
    reset,
  });

  const onSubmit = (data: AcceleratorFormSchema) => {
    onNext(data);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            Organization Name
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="organizationName"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(40),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.organizationName?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            Address
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="address"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(80),
              ...validateSpecialCharacters1,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.address?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel component="legend">Address 2</FormLabel>
          <ControlledTextField
            fullWidth
            name="address2"
            control={control}
            rules={{
              ...validateMaxLength(80),
              ...validateSpecialCharacters1,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.address2?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            City/Town
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="city"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(80),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.city?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel component="legend">State</FormLabel>
          <ControlledSelect
            fullWidth
            displayEmpty
            name="state"
            placeholder="Select state"
            control={control}
            rules={{
              ...validateMaxLength(20),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
          >
            <SelectOption value="">Select state</SelectOption>
            {states.map((state) => (
              <SelectOption key={state.name} value={state.name}>
                {state.name}
              </SelectOption>
            ))}
          </ControlledSelect>
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            Zip
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="zip"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(20),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.zip?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            Country
          </FormLabel>
          <ControlledSelect
            fullWidth
            displayEmpty
            name="country"
            placeholder="Select country"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(80),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
          >
            <SelectOption value="">Select country</SelectOption>
            {countries.map((country) => (
              <SelectOption key={country.name} value={country.name}>
                {country.name}
              </SelectOption>
            ))}
          </ControlledSelect>
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            Principal Contact (Sponsor)
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="sponsor"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(40),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.sponsor?.message}
          />
        </div>
        <div className={cx(classes.formControl, classes.phoneNumberWrapper)}>
          <FormLabel required component="legend">
            Principal Contact Phone #
          </FormLabel>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(20),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            render={({
              field: { value, onChange },
              fieldState: { invalid },
            }) => (
              <PhoneInput
                enableSearch
                disableCountryGuess={false}
                value={value}
                buttonStyle={{ borderColor: invalid ? 'red' : undefined }}
                inputStyle={{
                  width: '100%',
                  borderRadius: 10,
                  height: 40,
                  borderColor: invalid ? 'red' : undefined,
                }}
                dropdownStyle={{ borderRadius: 10 }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            Principal Contact Email
          </FormLabel>
          <ControlledEmailTextField required name="email" />
        </div>
        <div className={classes.formControl}>
          <FormLabel component="legend">
            Principal Contact 2 (Sponsor)
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="sponsor2"
            control={control}
            rules={{
              ...validateMaxLength(40),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.sponsor2?.message}
          />
        </div>
        <div className={cx(classes.formControl, classes.phoneNumberWrapper)}>
          <FormLabel component="legend">Principal Contact 2 Phone #</FormLabel>
          <Controller
            name="phone2"
            control={control}
            rules={{
              ...validateMaxLength(20),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            render={({ field: { value, onChange } }) => (
              <PhoneInput
                enableSearch
                disableCountryGuess={false}
                value={value}
                inputStyle={{ width: '100%', borderRadius: 10, height: 40 }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel component="legend">Principal Contact 2 Email</FormLabel>
          <ControlledEmailTextField name="email2" />
        </div>
        <StepperNavigation
          onFormCancelOpen={onFormCancelOpen}
          onFormCancel={onFormCancel}
        >
          <StepperNavButton direction="next" />
        </StepperNavigation>
      </form>
    </FormProvider>
  );
};

export default Step1;
