import { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import arrayMove from 'array-move';
import cx from 'classnames';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MuiIconMoveBottom from '@material-ui/icons/ArrowDownward';
import MuiIconMoveTop from '@material-ui/icons/ArrowUpward';

import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import { uploadFile } from 'src/utils/S3Helper';
import FilesUploader from 'src/components/FilesUploader';
import * as CONSTANTS from 'src/utils/Constants';
import { getFileName, getFileStatus, trimChar } from 'src/utils/StringHelper';
import {
  createFolder,
  getLink,
  listS3Items,
  publishFile,
  readFile,
  removeFromS3,
  requireApprovalFile,
  updateFileContent,
} from 'src/utils/S3Helper';
import { jsonParseSafe } from 'src/utils/ParseHelper';
import { distinct, orderArray } from 'src/utils/ArrayHelper';
import ConfirmDialog from 'src/components/ConfirmDialog';

import NewSectionEdit from './components/NewSectionEdit';
import {
  ACCEPTABLE_FILE_TYPES,
  DEFAUL_MAX_FILES_COUNT,
  DEFAUL_MAX_FILES_SIZE_PER_UPLOAD,
  DEFAUL_MAX_FILES_COUNT_PER_UPLOAD,
} from './constants';
import { normalizeFileName } from './utils';
import {useStyles} from './styles'

const FileList = ({
  folderName,
  editMode,
  searchResults,
  onChildFoldersReady,
  onUpload,
}) => {
  const classes = useStyles();

  const { userData } = useCognito();

  const [childFiles, setChildFiles] = useState([]);
  const [toDelete, setToDelete] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [childFolders, setChildFolders] = useState([]);
  
  const path = trimChar(folderName, '/') + '/'; // make sure folder ends with /

  useEffect(() => {
    const prepareChildren = async (files) => {
      const updatedChildren = [];

      for (const file of files) {
        const urlPath = await getLink(file.key);
        updatedChildren.push({ ...file, urlPath });
      }

      setChildFiles(updatedChildren);
    };

    // TODO: change folder search and folder name creation
    const separateChildren = async (list) => {
      const emptyFoldersToDisplay = list
        .filter((x) => x.size === 0 && x.key !== path)
        .map((x) => trimChar(x.key, '/').replace(path, '').split('/'))
        .map((x) => x[0]);
      const children = list.filter(
        (x) =>
          x.size > 0 && !CONSTANTS.AllDefaultFiles.includes(getFileName(x.key))
      );
      const fileFolders = children
        .map((x) => x.key.replace(path, '').split('/'))
        .filter((x) => x.length > 1)
        .map((x) => x[0]);
      fileFolders.push(...emptyFoldersToDisplay);
      const foldernames = fileFolders.filter(distinct);

      const childFodlers = foldernames.map((x) => {
        return { key: `${path}${x}`, urlPath: '', size: 0 };
      });

      const orderedList = await orderList(childFodlers);

      setToDelete('');
      prepareChildren(children);
      childFoldersChanged(orderedList, false);
    };

    const orderList = async (folders) => {
      const order = jsonParseSafe(
        await readFile(path + CONSTANTS.OrderFile),
        null
      );

      return orderArray(
        folders,
        (k) => k.key,
        order,
        (k) => k
      );
    };

    setChildFolders([]);
    setChildFiles([]);

    if (!searchResults) {
      listS3Items(path).then((data) => {
        separateChildren(data);
      });
    } else {
      separateChildren(searchResults);
    }
  }, [path, searchResults]);

  const childFoldersChanged = (newFolders, save = true) => {
    setChildFolders(newFolders);

    if (save) {
      saveOrder(newFolders.map((x) => x.key));
    }
  
    if (onChildFoldersReady) {
      onChildFoldersReady(newFolders);
    }
  };

  const saveOrder = async (order) => {
    await updateFileContent(path + CONSTANTS.OrderFile, JSON.stringify(order));
  };

  const notify = (data) => {
    if (onUpload) {
      onUpload(data);
    }
  };

  const isChildOfFolder = (path, file) => {
    const filePath = file.key;
    const fileName = getFileName(filePath);

    if (CONSTANTS.AllDefaultFiles.includes(fileName)) {
      return false;
    }

    return (
      filePath.startsWith(path + '/') &&
      filePath.split('/').length - 1 === path.split('/').length
    );
  };

  const removeFolder = async (path) => {
    await removeFromS3(path);

    childFoldersChanged(childFolders.filter((x) => !x.key.startsWith(path)));
    setChildFiles(childFiles.filter((x) => !x.key.startsWith(path)));

    notify({
      message: `Folder ${getFileName(path)} removed`,
      success: true,
      show: true,
    });
  };

  const removeFile = async (path) => {
    await removeFromS3(toDelete);

    setChildFiles(childFiles.filter((x) => x.key !== path));

    notify({
      message: `File ${getFileName(path)} removed`,
      success: true,
      show: true,
    });
  };

  const handleDeleteConfirm = async () => {
    setInProgress(true);

    if (childFiles.some((x) => toDelete === x.key)) {
      await removeFile(toDelete);
    } else if (childFolders.some((x) => toDelete === x.key)) {
      await removeFolder(toDelete);
    }

    setInProgress(false);
  };

  const handleAddSection = async (sectionName) => {
    setInProgress(true);

    const fullPath = `${trimChar(path, '/')}/${sectionName}`;

    if (await createFolder(fullPath)) {
      const folders = [...childFolders];
      folders.push({ key: fullPath, urlPath: '', size: 0 });
      childFoldersChanged(folders);

      notify({
        message: `Section '${sectionName}' added`,
        success: true,
        show: true,
      });
    }

    setInProgress(false);
  };

  const handleMove = (folder, direction) => {
    const index = childFolders.findIndex((x) => x.key == folder);
    const end = index + direction;
    if (end >= 0) {
      const newArray = arrayMove(childFolders, index, end);
      childFoldersChanged(newArray);
    }
  };

  const handlePublish = async (path) => {
    setInProgress(true);

    try {
      const newPath = await publishFile(path);
      const urlPath = await getLink(newPath);

      setChildFiles(
        childFiles.map((x) =>
          x.key === path ? { ...x, key: newPath, urlPath } : x
        )
      );

      notify({
        message: `File ${getFileName(path)} has been published`,
        success: true,
        show: true,
      });
    } catch (error) {
      console.error(error.message);
    } finally {
      setInProgress(false);
    }
  };

  const handleUpload = async (files, folderPath) => {
    if (files.length > 0) {
      const newFiles = [];

      for (const file of files) {
        const fullPath = `${folderPath}/${file.name}`;

        const link = await getLink(fullPath);

        newFiles.push({ key: fullPath, urlPath: link, size: file.size });
      }

      const filterd = childFiles.filter(
        (x) => !newFiles.some((r) => r.key === x.key)
      );

      filterd.push(...newFiles);

      setChildFiles(filterd);
    }
  };

  const handleUploadFiles = async (files, folderPath) => {
    const uploadedFiles = [];
    const failedFiles = [];

    for (const file of files) {
      const fileName = normalizeFileName(file.name);
      const path = folderPath ? `${trimChar(folderPath, '/')}/${fileName}` : fileName;

      try {
        await uploadFile({
          path,
          file,
          user_id: userData.id,
          source: 'cp documents',
        });
        uploadedFiles.push(file);
      } catch (error) {
        console.error(error.message);
        failedFiles.push(file);
      }
    }

    handleUpload(files.map((t) => ({ name: normalizeFileName(t.name), size: t.size })), folderPath);
  };

  const handleRequireApproval = async (path) => {
    const newPath = await requireApprovalFile(path);
    const urlPath = await getLink(newPath);

    setChildFiles(
      childFiles.map((x) =>
        x.key === path ? { ...x, key: newPath, urlPath } : x
      )
    );

    notify({
      message: `File ${getFileName(path)} has been set to require approval`,
      success: true,
      show: true,
    });
  };

  const renderFilesOfFolder = (folderPath, editMode = false) => {
    const folderName = getFileName(folderPath);
    const files = childFiles.filter((x) => isChildOfFolder(folderPath, x));
    const publishedFiles = files.filter(
      (f) => getFileStatus(f.key) === CONSTANTS.FileStatuses.Published
    );
    const shouldHideSection = !editMode && publishedFiles.length === 0;

    return (
      !shouldHideSection && (
        <Box key={folderName} width="100%" my={2}>
          <Box className={cx({ [classes.fieldContainer]: editMode })}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={8}>
                <Typography variant="h3" id={folderName}>
                  {folderName}
                </Typography>
              </Grid>
              <Grid item>
                {editMode && (
                  <Grid container alignItems="center">
                    <IconButton
                      size="small"
                      onClick={() => handleMove(folderPath, -1)}
                    >
                      <MuiIconMoveTop fontSize="default" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleMove(folderPath, 1)}
                    >
                      <MuiIconMoveBottom fontSize="default" />
                    </IconButton>
                    <Box ml={2}>
                      <Button
                        className={classes.actionButton}
                        variant="text"
                        color="secondary"
                        onClick={() => setToDelete(folderPath)}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <FilesUploader
              withSuccessfulUploadNotification
              isEditMode={editMode}
              uploadedFiles={files}
              accept={ACCEPTABLE_FILE_TYPES}
              maxFilesCount={DEFAUL_MAX_FILES_COUNT}
              maxFilesSizePerUpload={DEFAUL_MAX_FILES_SIZE_PER_UPLOAD}
              maxFilesCountPerUpload={DEFAUL_MAX_FILES_COUNT_PER_UPLOAD}
              customUploadHandler={(files) => handleUploadFiles(files, folderPath)}
              onRemove={setToDelete}
              onFilePublish={handlePublish}
              onRequireApproval={handleRequireApproval}
            />
          </Box>
        </Box>
      )
    );
  };

  return (
    <div>
      <Grid
        container
        item
        direction="column"
        alignItems="flex-start"
        xs={12}
        className="files-list-container"
      >
        {editMode && <NewSectionEdit onAddSection={handleAddSection} />}
        {childFolders.map((x) => renderFilesOfFolder(x.key, editMode))}
      </Grid>
      <div>
      {createPortal(
        <ConfirmDialog
          open={!!toDelete}
          title={`Please confirm you want to delete ${getFileName(toDelete)}`}
          acceptButtonText="Delete"
          onClose={() => setToDelete('')}
          onAccept={handleDeleteConfirm}
        />,
        document.getElementById('modal-root')
      )}
      </div>
    </div>
  );
};

export default FileList;
