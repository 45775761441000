import { FileStatuses } from './Constants'

export function trimChar(s, c) {
  if (c === "]") c = "\\]";
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp("^[" + c + "]+|[" + c + "]+$", "g"), "");
}

export function isEmpty(str) {
  return !str || str.trim().length === 0;
}

export function getFolderName(path) {
  return path.split("/").slice(-2)[0];
}

export function getFileName(path) {
  let rawName = path.split("/").slice(-1)[0];
  for (const statusKey of Object.keys(FileStatuses)) {
    const status = FileStatuses[statusKey]
    rawName = rawName.replace(status, '');
  }
  return rawName;
}

export function getFileStatus(path) {
  let rawName = path.split("/").slice(-1)[0];
  for (const statusKey of Object.keys(FileStatuses)) {
    const status = FileStatuses[statusKey]
    if (rawName.indexOf(status) > -1) {
      return status
    }
  }

  return FileStatuses.Published
}

export function getFileExtension(fileName) {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function pathJoin(parts, sep){
  var separator = sep || '/';
  var replace   = new RegExp(separator+'{1,}', 'g');
  return parts.join(separator).replace(replace, separator);
}

export function addOrUpdateArrayItem (array = [], item, findPredicate) {
  array = array || [];
  findPredicate = findPredicate || (i => i === item)
  const index = array.findIndex(findPredicate);
  if (index === -1){
    return array.concat(item);
  }
  else {
    return [
      ...array.slice(0, index),
      item,
      ...array.slice(index + 1)
    ];
  }
}

export function removeItemFromArray (array, item, findPredicate) {
  array = array || [];
  findPredicate = findPredicate || (i => i === item)
  const index = array.findIndex(findPredicate);
  if (index === -1){
    return [...array]
  }
  else {
    return [
      ...array.slice(0, index),
      ...array.slice(index + 1)
    ]
  }
}

export function addOrRemoveArrayItem (array, item, findPredicate) {
  array = array || [];
  const index = array.findIndex(findPredicate || (x => x === item));
  if (index === -1) {
      return array.concat(item);
  }
  else {
      return [
          ...array.slice(0, index),
          ...array.slice(index + 1)
      ]
  }
}

export function capitalize (s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export const validatePhone = (value) => {
  return (
    /^[+]?[0-9]{10,13}$/g.test(value) ||
    'Wrong Phone format. Minimum 10, maximum 13 digits allowed.'
  );
}