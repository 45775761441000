import { ChangeEventHandler, FC, useEffect, useState } from 'react';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@quanterix-ui/core/TextField';

import { addOrRemoveArrayItem } from 'src/utils/StringHelper';

import { SampleTypesFull } from '../../typings';

import { Item } from './typings';

// {
//   items: ['1, 2, 3', 'Other'],
//   other: 'asd'
// }

interface Props {
  value: SampleTypesFull;
  items: Item[];
  size?: 'small' | 'medium';
  onChangeValue: (options: SampleTypesFull) => void;
}

const CheckboxeList: FC<Props> = ({
  items,
  value,
  size = 'medium',
  onChangeValue,
}) => {
  const [isOther, setIsOther] = useState(false);
  const [otherValue, setOtherValue] = useState('');

  useEffect(() => {
    if (value) {
      setIsOther(value.items?.includes('Other'));
      setOtherValue(value.other || '');
    }
  }, [value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value: newValue } = event.target;
    const currentItems = value ? value.items : [];
    const newItems = addOrRemoveArrayItem(currentItems, newValue);
    const isOtherNew = newValue === 'Other' ? !isOther : isOther;

    onChangeValue({
      items: newItems,
      other: isOtherNew ? otherValue : undefined,
    });
    setIsOther(isOtherNew);
  };

  const handleOtherChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value: newValue } = event.target;

    setOtherValue(newValue);
    onChangeValue({ items: value.items, other: newValue });
  };

  return (
    <FormGroup>
      {items.map((item) => (
        <FormControlLabel
          key={item.value}
          control={
            <Checkbox
              size={size}
              checked={Boolean(value?.items?.indexOf(item.value) > -1)}
              name={item.value}
              value={item.value}
              onChange={handleChange}
            />
          }
          label={item.name}
        />
      ))}
      {isOther && (
        <TextField
          fullWidth
          name="other"
          value={otherValue}
          onChange={handleOtherChange}
        />
      )}
    </FormGroup>
  );
};

export default CheckboxeList;
