export enum BUTTON_DIRECTION {
  next = 'next',
  previous = 'previous',
  submit = 'submit',
  finish = 'finish',
}

export const BUTTON_LABEL = {
  [BUTTON_DIRECTION.next]: 'Next Page',
  [BUTTON_DIRECTION.previous]: 'Previous Page',
  [BUTTON_DIRECTION.submit]: 'Submit',
  [BUTTON_DIRECTION.finish]: 'Finish',
};
