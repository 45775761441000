import { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { getFAImage } from './utils';

interface Props {
  extension: string;
  urlPath: string;
  name: string;
  folder: string;
}

const SearchResultItem: FC<Props> = ({ extension, urlPath, name, folder }) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={7}>
        <Box className="fileHolder">
          {getFAImage(extension)}
          &nbsp;
          <Link
            href={urlPath}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
            className="FileLink"
          >
            {name}
          </Link>
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Box className="SearchResultCategoryHolder">
          <Typography>{folder}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchResultItem;
