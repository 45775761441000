import { formatBytes } from 'src/utils/helpers/formatters';
import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';

import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import { AcceleratorFormStepComponents } from './typings';

export const MANIFESTS_MAX_COUNT = 10;
export const MANIFESTS_MAX_SIZE = 5 * 1024 * 1024; // 5 MB
export const MANIFESTS_READABLE_MAX_SIZE = formatBytes(MANIFESTS_MAX_SIZE);

export enum TEMPLATES {
  manifest = 'manifest',
}

export const ACCELERATOR_FORM_STEP_NAMES = [
  'Site Information',
  'Project Information',
  'Materials Information',
  'Sample Testing Details',
  'Project Completion',
  'Complete',
];

export enum ACCELERATOR_FORM_STEP_INDEX {
  siteInformation = 0,
  projectInformation = 1,
  materialsInformation = 2,
  sampleTestingDetails = 3,
  projectCompletion = 4,
  complete = 5,
}

export enum MALWARE_SCAN_STATUS {
  inProgress = 'in_progress',
  malwareFound = 'malware_found',
  ok = 'ok',
}

export const ACCELERATOR_FORM_STEP_COMPONENTS: AcceleratorFormStepComponents = {
  0: Step1,
  1: Step2,
  2: Step3,
  3: Step4,
  4: Step5,
  5: Step6,
};

export const ACCELERATOR_FORM_LAST_STEP_NUMBER =
  Object.keys(ACCELERATOR_FORM_STEP_COMPONENTS).length - 1;

export enum SIMOA_PLATFORMS {
  planarArrayTechnology = 'Planar Array Technology',
  beadTechnology = 'Bead Technology',
}

export const DEFAULT_ACCELERATOR_FORM_DATA: AcceleratorFormSchema = {
  organizationName: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  sponsor: '',
  phone: '',
  email: '',
  sponsor2: '',
  phone2: '',
  email2: '',
  projectId: '',
  poNumber: '',
  projectType: 'Homebrew',
  platform: '',
  projectDescription: '',
  homebrewProjectDescription: '',
  trackingNumber: '',
  sampleReagentsManifests: [],
  sampleReagentsManifestLinks: '[]',
  sampleTypes: '',
  sampleTypesFull: { items: [], other: undefined },
  speciesOrigin: '',
  totalSampleNumber: '',
  sampleTypeVolumes: '',
  sampleTestingInformation: '',
  calibratorReplicates: 'Triplicate',
  controlReplicates: 'Duplicate',
  sampleReplicates: 'Duplicate',
  customDilutionFactor: 'per kit instructions MRD',
  sampleReturn: 'Return',
  returnAddress: '',
  returnAddress2: '',
  returnCity: '',
  returnState: '',
  returnZip: '',
  returnCountry: '',
  shippingAndHandling: '',
};
