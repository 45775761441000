import { FC, useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import TextField, { TextFieldProps } from '@quanterix-ui/core/TextField';

const PasswordTextField: FC<TextFieldProps> = (props) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const handlePasswordShownToggle = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <TextField
      {...props}
      type={isPasswordShown ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={handlePasswordShownToggle}>
              {isPasswordShown ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextField;
