import { makeStyles } from '@material-ui/core/styles';

const heightOfTheContainer = 40;

export const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: heightOfTheContainer + 10, // 10px is for padding
    padding: '5px 5px 5px 16px',
    margin: '3px 0',
    borderRadius: 10,

    '& .action-icon': {
      display: 'none',
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[100],

      '& .action-icon': {
        display: 'block',
      },
    },

    '& a': {
      color: 'initial',
      textDecoration: 'none',
    },

    '& svg': {
      color: theme.palette.primary.main,
    },
  },

  gridItemHeightHolder: {
    width: 1,
    height: heightOfTheContainer,
  },

  fileTypeIconContainer: {
    position: 'relative',
    display: 'inline-block',
    width: 17,
    height: 17,
  },

  fileTypeIcon: {
    fontSize: 17,

    '&.inProgress': {
      visibility: 'hidden',
    },
  },

  fileProgressContainer: {
    position: 'absolute',
    top: 1,
    left: 1,
  },

  italicLink: {
    fontStyle: 'italic',
    opacity: 0.4,
  },

  actionButton: {
    minWidth: 'auto !important',
  },
}));
