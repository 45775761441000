import { FC } from 'react';

import { Router as ReactRouter, Redirect, Switch } from 'react-router-dom';

import { useGaTracker } from 'src/utils/hooks/useGaTracker';
import SignIn from 'src/pages/SignIn';
import SignUp from 'src/pages/SignUp';
import ForgotPassword from 'src/pages/ForgotPassword';
import EmailVerification from 'src/pages/EmailVerification';
import ChangeExpiredPassword from 'src/pages/ChangeExpiredPassword';
import ConfirmUser from 'src/components/admin/users/ConfirmUser';
import UserEdit from 'src/components/admin/users/UserEdit';
import UserList from 'src/components/admin/users/UserList';
import UserNew from 'src/components/admin/users/UserNew';
import UserView from 'src/components/admin/users/UserView';
import FolderViewer from 'src/components/folder-viewer/FolderViewer';
import LegalDocument from 'src/pages/LegalDocument';
import Welcome from 'src/pages/Welcome';
import Search from 'src/pages/Search';
import DownloadPage from 'src/pages/Download/Download';
import AcceleratorForm from 'src/pages/AcceleratorForm';
import Profile from 'src/pages/Profile';
import ProfileChangePassword from 'src/pages/ProfileChangePassword';
import ProfileEdit from 'src/pages/ProfileEdit';
import AdminPages from 'src/pages/AdminPages';

import { history } from './config';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import ProtectedRoute from './components/ProtectedRoute';

const Routes: FC = () => {
  useGaTracker();

  return (
    <Switch>
      <PublicRoute exact path="/signin">
        <SignIn />
      </PublicRoute>
      <PublicRoute exact path="/signup">
        <SignUp />
      </PublicRoute>
      <PublicRoute exact path="/legal/:name">
        <LegalDocument />
      </PublicRoute>
      <PublicRoute exact path="/forgot-password">
        <ForgotPassword />
      </PublicRoute>
      <PublicRoute exact path="/verify-email">
        <EmailVerification />
      </PublicRoute>
      <PublicRoute exact path="/change-password">
        <ChangeExpiredPassword />
      </PublicRoute>

      <PrivateRoute exact path="/profile">
        <Profile />
      </PrivateRoute>
      <PrivateRoute exact path="/profile/change-password">
        <ProfileChangePassword />
      </PrivateRoute>
      <PrivateRoute exact path="/profile/edit">
        <ProfileEdit />
      </PrivateRoute>
      <PrivateRoute exact path="/welcome">
        <Welcome />
      </PrivateRoute>
      <PrivateRoute exact path="/folder-viewer/:folderName">
        <FolderViewer />
      </PrivateRoute>
      <PrivateRoute exact path="/folder-viewer/:folderName/:fileName">
        <FolderViewer />
      </PrivateRoute>
      <PrivateRoute exact path="/search">
        <Search />
      </PrivateRoute>
      <PrivateRoute exact path="/download/:file">
        <DownloadPage />
      </PrivateRoute>
      <PrivateRoute exact path="/accelerator">
        <AcceleratorForm />
      </PrivateRoute>

      <ProtectedRoute exact path="/admin/pages">
        <AdminPages />
      </ProtectedRoute>
      <ProtectedRoute exact path="/admin/pages/folder-editor/:folderName">
        <FolderViewer editMode />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/admin/pages/folder-editor/:folderName/:fileName"
      >
        <FolderViewer editMode />
      </ProtectedRoute>
      <ProtectedRoute exact path="/admin/users">
        <UserList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/admin/users/new">
        <UserNew />
      </ProtectedRoute>
      <ProtectedRoute exact path="/admin/users/:username">
        <UserEdit />
      </ProtectedRoute>
      <ProtectedRoute exact path="/admin/users/:username/view">
        <UserView />
      </ProtectedRoute>
      <ProtectedRoute exact path="/admin/users/:username/confirm">
        <ConfirmUser />
      </ProtectedRoute>

      <PrivateRoute exact path="/">
        <Redirect to="welcome" />
      </PrivateRoute>
    </Switch>
  );
};

const Router: FC = () => (
  <ReactRouter history={history}>
    <Routes />
  </ReactRouter>
);

export default Router;
