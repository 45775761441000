export function distinct(value, index, self) {
  return self.indexOf(value) === index;
}

export function orderArray(array, getKeyFunction, order, getOrderKey) {
  let orderedArray = [];
  if (order && Array.isArray(order)) {
    for (let index = 0; index < order.length; index++) {
      const existingIndex = array.findIndex(
        (x) => getKeyFunction(x) === getOrderKey(order[index])
      );
      if (existingIndex !== -1) {
        orderedArray.push(array[existingIndex]);
        array.splice(existingIndex, 1);
      }
    }
  }
  orderedArray.push(...array);
  return orderedArray;
}

export function removeByIndex(array, index) {
  if (!array) return null;
  if (index !== -1) {
    array.splice(index, 1);
  }

  return [ ...array ];
}
