import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 720,
    borderRadius: 10,
    padding: 40,
  },

  title: {
    width: '100%',
    padding: 0,
    paddingBottom: 20,
  },

  content: {
    width: '100%',
    padding: 0,
    paddingBottom: 20,
  },

  contentText: {
    padding: 0,
  },

  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  actions: {
    justifyContent: 'flex-end',
    padding: 0,
  },
}));
