import { useState, useEffect} from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { useGetUser, useConfirmUser } from 'src/aws/UserPool';
import {
  disableUserByAdmin,
  enableUserByAdmin,
} from 'src/utils/CognitoIdentityHelper';
import BaseUserView from 'src/components/common/users/BaseUserView';

const UserView = () => {
  const history = useHistory();
  const { username } = useParams();

  const { user, refetchUser } = useGetUser({ username });
  const { confirmUser, inProgress: isConfirmUserInProgress } = useConfirmUser();

  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (user && updated && !isConfirmUserInProgress) {
      setUpdated(false);
      refetchUser();
    }
  }, [updated, user, isConfirmUserInProgress, refetchUser]);

  const handleEdit = () => {
    history.push('/admin/users/' + username);
  };

  const handleEnable = async () => {
    if (!user) {
      refetchUser();
      return;
    }

    const isEnabled = user.Enabled;
    user.Enabled = !isEnabled;

    if (isEnabled) {
      const request = await disableUserByAdmin(user.Username);
      request.on('error', (response) => {
        console.error('User enabling failed. ' + response.error.message);
        user.Enabled = isEnabled;
      });
    } else {
      const request = await enableUserByAdmin(user.Username);
      request.on('error', (response) => {
        console.error('User enabling failed. ' + response.error.message);
        user.Enabled = isEnabled;
      });
    }

    setTimeout(() => {
      refetchUser();
    }, 200)
  };

  const handleConfirm = async () => {
    try {
      confirmUser(user?.Username);
      setUpdated(true);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <BaseUserView
      disabled={!(user?.Enabled || false)}
      status={user?.UserStatus || ''}
      userAttributes={user?.UserAttributes || []}
      onEdit={handleEdit}
      onEnable={handleEnable}
      onConfirm={handleConfirm}
    />
  );
};

export default UserView;
