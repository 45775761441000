import { useHistory, useParams } from 'react-router-dom';

import { useGetUser } from 'src/aws/UserPool';
import { updateUserAttributesByAdmin } from 'src/utils/CognitoIdentityHelper';
import BaseUserEdit from 'src/components/common/users/BaseUserEdit';

const UserEdit = () => {
  const history = useHistory();
  const { username } = useParams();

  const { user } = useGetUser({ username });

  const handleCancelEdit = () => {
    history.push('/admin/users');
  };

  const handleSaveUser = async (attributes) => {
    const request = await updateUserAttributesByAdmin(username, attributes);

    request
      .on('success', function (response) {
        history.push('/admin/users');
      })
      .on('error', function (response) {
        alert('User saving failed. ' + request.response.error.message);
      });
  };

  return (
    <BaseUserEdit
      status={user?.Status || ''}
      userAttributes={user?.UserAttributes || []}
      cancelEdit={handleCancelEdit}
      saveUser={handleSaveUser}
    />
  );
};

export default UserEdit;
