import { FC, useEffect, useState } from 'react';

import DownloadButton from 'src/components/elements/DownloadButton';
import { getTemplateDownloadUrl } from 'src/utils/helpers/blobStorage';

import { TEMPLATES } from '../../constants';

const DownloadManifestButton: FC = () => {
  const [href, setHref] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestDownloadUrl = async () => {
      setLoading(true);
      try {
        const url = await getTemplateDownloadUrl(TEMPLATES.manifest);
        setHref(url);
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    requestDownloadUrl();
  }, []);

  return (
    <DownloadButton href={href} loading={loading} disabled={loading}>
      Download Manifest Template
    </DownloadButton>
  );
};

export default DownloadManifestButton;
