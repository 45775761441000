import { ReactNode } from 'react';

import {
  faFileAlt,
  faFileArchive,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const getFAImage = (extention: string): ReactNode => {
  switch (extention) {
    case 'pdf':
      return <FontAwesomeIcon icon={faFilePdf} />;
    case 'doc':
    case 'docx':
      return <FontAwesomeIcon icon={faFileWord} />;
    case 'xls':
    case 'xlsx':
      return <FontAwesomeIcon icon={faFileExcel} />;
    case 'ppt':
    case 'pptx':
      return <FontAwesomeIcon icon={faFilePowerpoint} />;
    case 'jpg':
    case 'png':
    case 'gif':
    case 'bmp':
      return <FontAwesomeIcon icon={faFileImage} />;
    case 'avi':
    case 'mp4':
    case 'm4v':
      return <FontAwesomeIcon icon={faFileVideo} />;
    case 'zip':
    case 'rar':
    case '7z':
      return <FontAwesomeIcon icon={faFileArchive} />;
    default:
      return <FontAwesomeIcon icon={faFileAlt} />;
  }
};
