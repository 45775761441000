import { FC } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Heading: FC = () => {
  return (
    <Box pb={4}>
      <Typography variant="h4">
        PLEASE SUBMIT THE FOLLOWING FORM WITHIN THE 24-HOUR PERIOD PRIOR TO
        SHIPMENT OF ANY SAMPLES OR ASSAY MATERIALS TO CRO & LABORATORY SERVICES
        AT QUANTERIX. NOTE THAT A TRACKING NUMBER AND P.O. NUMBER ARE REQUIRED
        FOR FORM SUBMISSION. PACKAGES THAT ARRIVE WITHOUT A CORRESPONDING FORM
        SUBMISSION MAY NOT BE ACCEPTED.
      </Typography>
    </Box>
  );
};

export default Heading;
