import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface Folder {
  name: string;
}

export interface PortalContextResult {
  folders: Folder[];
  setFolders: Dispatch<SetStateAction<Folder[]>>;
}

export const PortalContext = createContext<PortalContextResult>({
  folders: [],
  setFolders: () => {
    // void
  },
});

const PortalContextProvider: FC = ({ children }) => {
  const [folders, setFolders] = useState<Folder[]>([]);

  const portalContext: PortalContextResult = {
    folders,
    setFolders,
  };

  return (
    <PortalContext.Provider value={portalContext}>
      {children}
    </PortalContext.Provider>
  );
};

export const usePortalContext = (): PortalContextResult => {
  const context = useContext(PortalContext);

  if (!context) {
    throw new Error(
      'usePortalContext must be used within a PortalContextProvider'
    );
  }

  return context;
};

export default PortalContextProvider;
