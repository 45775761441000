import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Link from '@material-ui/core/Link';

import Button from '@quanterix-ui/core/Button';

import { UseMalwareScanResult } from '../../hooks/useMalwareScan';
import { TerminatedBy } from '../../typings';

import { useStyles } from './styles';

interface Props {
  isCancelDialogOpen: boolean;
  isMaterialsInfoStepActive: boolean;
  isMalwareScanCompleted: UseMalwareScanResult['isCompleted'];
  totalScannedFilesCount: UseMalwareScanResult['totalScannedFilesCount'];
  filesWithMalware: UseMalwareScanResult['temporaryFilesWithMalware'];
  cleanFilesWithMalware: () => void;
  onLinkClick: (terminatedReason?: TerminatedBy) => void;
}

const MalwareScanDialog: FC<Props> = ({
  isCancelDialogOpen,
  isMaterialsInfoStepActive,
  isMalwareScanCompleted,
  totalScannedFilesCount,
  filesWithMalware,
  cleanFilesWithMalware,
  onLinkClick,
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const modalRootElement = useRef<HTMLElement | null>(null);

  const hasFilesWithMalware = useMemo(
    () => filesWithMalware.length > 0,
    [filesWithMalware]
  );

  const isAllFilesHasMalware = useMemo(() => {
    return filesWithMalware.length === totalScannedFilesCount;
  }, [filesWithMalware, totalScannedFilesCount]);

  const filesWithMalwareToShow = useMemo(() => {
    if (hasFilesWithMalware && !isAllFilesHasMalware) {
      const filesWithCorrectNaming = filesWithMalware.reduce<string[]>(
        (base, nextFile) => {
          const fileNameParts = nextFile.split('/');
          const fileName = fileNameParts[fileNameParts.length - 1];

          return [...base, fileName];
        },
        []
      );

      return filesWithCorrectNaming;
    }

    return undefined;
  }, [hasFilesWithMalware, isAllFilesHasMalware, filesWithMalware]);

  const isMalwareFoundInAllFilesOnStepWithFilesUploader = useMemo(() => {
    return isMaterialsInfoStepActive && isAllFilesHasMalware;
  }, [isMaterialsInfoStepActive, isAllFilesHasMalware]);

  const isMalwareFoundInAllFilesOnDifferentStep = useMemo(() => {
    return !isMaterialsInfoStepActive && isAllFilesHasMalware;
  }, [isMaterialsInfoStepActive, isAllFilesHasMalware]);

  const isMalwareFoundInSomeFilesOnStepWithFilesUploader = useMemo(() => {
    return (
      isMaterialsInfoStepActive && hasFilesWithMalware && !isAllFilesHasMalware
    );
  }, [isMaterialsInfoStepActive, hasFilesWithMalware, isAllFilesHasMalware]);

  const isMalwareFoundInSomeFilesOnDifferentStep = useMemo(() => {
    return (
      !isMaterialsInfoStepActive && hasFilesWithMalware && !isAllFilesHasMalware
    );
  }, [isMaterialsInfoStepActive, hasFilesWithMalware, isAllFilesHasMalware]);

  useEffect(() => {
    modalRootElement.current = document.getElementById('modal-root');
  }, []);

  useEffect(() => {
    if (!isCancelDialogOpen && hasFilesWithMalware && isMalwareScanCompleted) {
      setIsOpen(true);
    }
  }, [isCancelDialogOpen, hasFilesWithMalware, isMalwareScanCompleted]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCloseWithCleanUp = () => {
    cleanFilesWithMalware();
    handleClose();
  };

  const handleLinkClick = () => {
    if (isMalwareFoundInAllFilesOnDifferentStep) {
      onLinkClick('error');
    }

    if (isMalwareFoundInSomeFilesOnDifferentStep) {
      onLinkClick('warning');
    }

    handleClose();
  };

  if (!modalRootElement.current) {
    return null;
  }

  const filesWithMalwareToShowAsString = filesWithMalwareToShow?.join(', ');
  const wasOrWereString =
    filesWithMalwareToShow && filesWithMalwareToShow.length > 1
      ? 'they were'
      : 'it was';

  return createPortal(
    <Dialog classes={{ paper: classes.paper }} open={isOpen}>
      <DialogTitle className={classes.title}>
        {(isMalwareFoundInAllFilesOnStepWithFilesUploader ||
          isMalwareFoundInAllFilesOnDifferentStep) &&
          `Malware was detected in all uploaded manifest files and they were deleted. Please upload at least one valid manifest file for this submission.`}
        {isMalwareFoundInSomeFilesOnStepWithFilesUploader &&
          `Malware detected in ${filesWithMalwareToShowAsString} and ${wasOrWereString} deleted. Please continue with request or upload a new manifest file.`}
        {isMalwareFoundInSomeFilesOnDifferentStep &&
          `Malware detected in ${filesWithMalwareToShowAsString} and ${wasOrWereString} deleted. Do you want to continue with the submission or return to upload a new manifest file?`}
      </DialogTitle>
      {(isMalwareFoundInAllFilesOnDifferentStep ||
        isMalwareFoundInSomeFilesOnDifferentStep) && (
        <DialogContent className={classes.content}>
          <DialogContentText>
            <Link
              className={classes.link}
              variant="body2"
              color="inherit"
              onClick={handleLinkClick}
            >
              Return to Materials Information page
            </Link>
          </DialogContentText>
        </DialogContent>
      )}
      {!isMalwareFoundInAllFilesOnDifferentStep && (
        <DialogActions className={classes.actions}>
          {isMalwareFoundInAllFilesOnStepWithFilesUploader && (
            <Button onClick={handleClose}>Ok</Button>
          )}
          {isMalwareFoundInSomeFilesOnStepWithFilesUploader && (
            <Button onClick={handleCloseWithCleanUp}>Ok</Button>
          )}
          {isMalwareFoundInSomeFilesOnDifferentStep && (
            <Button onClick={handleCloseWithCleanUp}>Yes, continue</Button>
          )}
        </DialogActions>
      )}
    </Dialog>,
    modalRootElement.current
  );
};

export default MalwareScanDialog;
