import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-gutterBottom': {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },

  formControl: {
    paddingTop: theme.spacing(2),
  },

  button: {
    width: 150,
  },

  wrapper: {
    position: 'relative',
    marginTop: theme.spacing(2),
  },

  boldLink: {
    fontWeight: 600,
  },
}));
