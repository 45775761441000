import { render } from 'react-dom';
import Amplify from 'aws-amplify';

import { awsconfig } from 'src/config/CognitoConfig';

import App from './App';
import './index.css';

Amplify.configure(awsconfig);

render(<App />, document.getElementById('root'));
