/* eslint-disable jsx-a11y/no-autofocus */
import { FC, ReactNode } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@quanterix-ui/core/Button';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  title: ReactNode;
  cancelButtonText?: string;
  acceptButtonText?: string;
  onAccept: () => void;
  onClose: () => void;
  onCancel?: () => void;
}

const ConfirmDialog: FC<Props> = ({
  open,
  title,
  cancelButtonText = 'Cancel',
  acceptButtonText = 'Ok',
  onAccept,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();

  const handleCancel = () => {
    if (onCancel) onCancel();

    onClose();
  };

  const handleAccept = () => {
    onAccept();
    onClose();
  };

  return (
    <Dialog classes={{ paper: classes.paper }} open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" onClick={handleCancel}>
          {cancelButtonText}
        </Button>
        <Button autoFocus onClick={handleAccept}>
          {acceptButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
