import { useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import ControlledPasswordTextField from 'src/components/elements/ControlledPasswordTextField';

import CodeVerificationForm from './components/CodeVerificationForm';
import { useStyles } from './styles';

const ForgotPassword = () => {
  const classes = useStyles();

  const { isLoading, resetPassword } = useCognito();

  const [email, setEmail] = useState('');
  const [codeSent, setCodeSent] = useState(false);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const formMethods = useForm({
    defaultValues: {
      verificationCode: '',
      password: '',
      confirmPassword: '',
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const handleConfirm = async ({ verificationCode, password }) => {
    try {
      await resetPassword({ email, verificationCode, password });

      enqueueSnackbar('Your password has been changed.', {
        variant: 'success',
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCodeSubmitted = ({ email }) => {
    setEmail(email);
    setCodeSent(true);
  };

  if (!codeSent) {
    return <CodeVerificationForm onCodeSubmitted={handleCodeSubmitted} />;
  }

  return (
    <FormProvider {...formMethods}>
      <div className={classes.root}>
        <form onSubmit={handleSubmit(handleConfirm)}>
          <div className={classes.formControl}>
            <FormLabel component="legend">Verification Code</FormLabel>
            <ControlledTextField
              fullWidth
              name="verificationCode"
              control={control}
              rules={{
                required: true,
              }}
            />
            <FormHelperText>
              {errors.verificationCode?.message || `Please check your email ${email} for the verification code`}
            </FormHelperText>
          </div>
          <div className={classes.password}>
            <FormLabel component="legend">New password</FormLabel>
            <FormControl fullWidth size="small" error={!!errors.password}>
              <ControlledPasswordTextField
                withMaxLength
                withPattern
                name="password"
              />
              <FormHelperText>
                Use 10 or more characters with uppercase and lowercase letters,
                numbers and special characters (e.g. %,$)
              </FormHelperText>
            </FormControl>
          </div>
          <div className={classes.password}>
            <FormLabel component="legend">Confirm Password</FormLabel>
            <FormControl
              fullWidth
              error={!!errors.confirmPassword}
              size="small"
            >
              <ControlledPasswordTextField
                name="confirmPassword"
                equalTo="password"
              />
              <FormHelperText>{errors.confirmPassword?.message}</FormHelperText>
            </FormControl>
          </div>
          <div className={classes.container}>
            <div className={classes.wrapper}>
              <Button
                className={classes.button}
                type="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                Confirm
              </Button>
            </div>
          </div>
          <p>
            <Typography variant="caption">
              By submitting this form, you agree to Quanterix’s{' '}
              <Link className={classes.boldLink} to="/legal/terms-conditions">
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link className={classes.boldLink} to="/legal/privacy-policy">
                Privacy Policy
              </Link>
              . In addition, you agree to receive information regarding
              Quanterix products and services. You will be able to unsubscribe
              at any time.
            </Typography>
          </p>
        </form>
      </div>
    </FormProvider>
  );
};

export default ForgotPassword;
