import React from 'react';

import { useCognito } from 'src/aws/Cognito';

interface Props {
  groups?: string[];
}

const ProtectedContainer: React.FC<Props> = ({ children, groups = [] }) => {
  const { checkAccess } = useCognito();

  const availableGroups = groups.filter((group) => checkAccess(group));
  const accessDenied = availableGroups.length === 0;

  if (accessDenied) return null;

  return <>{children}</>;
};

export default ProtectedContainer;
