import { ChangeEvent, FC } from 'react';

import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

import Input from '@quanterix-ui/core/Input';
import Button from '@quanterix-ui/core/Button';

import { useStyles } from './styles';

interface Props {
  value: string;
  isOpen: boolean;
  isValueRequired: boolean;
  isValueValid: boolean;
  variant: 'Page' | 'Folder';
  anchorEl: HTMLElement;
  onChange: (value: string) => void;
  onCreate: () => void;
  onCancel: () => void;
}

const CreatePagePopover: FC<Props> = ({
  value,
  isOpen,
  isValueRequired,
  isValueValid,
  variant,
  anchorEl,
  onChange,
  onCreate,
  onCancel,
}) => {
  const classes = useStyles();

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      classes={{ paper: classes.rootPaper }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onCancel}
    >
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Input
            fullWidth
            placeholder={`Enter ${variant} name`}
            className={classes.textfield}
            value={value}
            onChange={changeHandler}
          />
          {isValueRequired && (
            <FormHelperText error>
              {`${variant} name is required`}
            </FormHelperText>
          )}
          {!isValueValid && (
            <FormHelperText error>
              {`${variant} name can't contain #`}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={6} className={classes.buttonWrapper}>
          <Button fullWidth onClick={onCreate}>
            {`Create ${variant}`}
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.buttonWrapper}>
          <Button fullWidth variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default CreatePagePopover;
