import { privateGateway } from '../../../config';

interface RequestDocumentsCountResult {
  count: number;
}

export const requestDocumentsCount =
  async (): Promise<RequestDocumentsCountResult> => {
    const response = await privateGateway(
      process.env.REACT_APP_DOCUMENTS_API as string
    ).get(`/files_count`);

    return response.data;
  };
