import { useEffect, useMemo, useState } from 'react';

import { createPortal } from 'react-dom';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { getLinkIfExists } from 'src/utils/S3Helper';
import * as Constants from 'src/utils/Constants';
import { search } from 'src/pages/Search/services/api';
import FileList from 'src/components/FileList';

import ContentViewer from './content-viewer/ContentViewer';
import ContentEditor from './content-editor/ContentEditor';
import ImageEditor from './image-editor/ImageEditor';
import SearchBar from './search-bar/SearchBar';
import './FolderViewer.css';

const FolderViwer = (props) => {
  const [imagePath, setImagePath] = useState(null);
  const [folders, setFolders] = useState([]);
  const [foldersLoaded, setFoldersLoaded] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [message, setMessage] = useState({
    success: false,
    message: '',
    show: false,
  });

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const currentPath = useMemo(() => {
    const fileName = params.fileName ? `/${params.fileName}` : '';
    const pathFromParams = params.folderName + fileName;

    if (history.action === 'POP') {
      return pathFromParams
        ? decodeURI(encodeURI(pathFromParams))
        : decodeURI(encodeURI(location.pathname));
    }

    return pathFromParams
      ? decodeURI(pathFromParams)
      : decodeURI(location.pathname);
  }, [location, params, history.action]);

  const pathParts = currentPath.split('/');
  const folderName = pathParts[0];
  const pageName = pathParts.length === 1 ? folderName : pathParts[1];

  const onSubmitSearch = async (query, searchWithin = 'title') => {
    setSearchInProgress(true);
    const data = await search(query, {
      searchWithin,
      searchPages: [pageName],
      searchFolders: [folderName],
    });
    setSearchResults(data.files.map((f) => ({ key: f.key, size: 10 })));
    setSearchInProgress(false);
  };

  const onClearSearch = async (query) => {
    setSearchResults(null);
  };

  const editMode = props.editMode || false;
  useEffect(() => {
    setFoldersLoaded(false);
    setSearchResults(null);
    setSearchInProgress(false);
    const imagePath = `${currentPath}/${Constants.DefaultImageFile}`;
    getLinkIfExists(imagePath).then((data) => {
      setImagePath(data);
    });
  }, [currentPath]);

  const handleChildFoldersReady = async (foldersData) => {
    setFolders(foldersData);
    setFoldersLoaded(true);
  };

  const handleClose = (event, reason) => {
    setMessage({ show: false });
  };
  const handleUpload = (eventMessage) => {
    setMessage(eventMessage);
  };

  const handleMessage = (msg) => {
    setMessage({
      success: true,
      message: msg,
      show: true,
    });
  };

  const renderImage = () => {
    if (editMode) {
      return (
        <Grid item xs={6} sm={4}>
          <Box className="folderImage">
            <ImageEditor
              path={`${currentPath}/${Constants.DefaultImageFile}`}
              onUpload={handleUpload}
            />
          </Box>
        </Grid>
      );
    } else if (imagePath && imagePath?.length > 0) {
      return (
        <Grid item xs={6} sm={4}>
          <Box className="folderImage">
            <img src={imagePath} alt="" />
          </Box>
        </Grid>
      );
    }
  };

  const showCategoryNavigation = folders.length > 0;

  const searchBarHolder = document.getElementById('searchBarHolder');

  return (
    <>
      {!foldersLoaded && <LinearProgress />}
      <Box visibility={foldersLoaded ? 'visible' : 'hidden'}>
        <Container className="AuthLayoutContent">
          <Grid container>
            <Grid
              item
              container
              alignItems={'stretch'}
              justify={'space-between'}
            >
              <Grid
                container
                item
                alignItems="stretch"
                justify="space-between"
              >
                <>
                  {searchBarHolder &&
                    createPortal(
                      <SearchBar
                        path={currentPath}
                        inProgress={searchInProgress}
                        onSubmitSearch={onSubmitSearch}
                        onClearSearch={onClearSearch}
                      />,
                      searchBarHolder
                    )}
                </>
                {searchResults ? (
                  <Typography variant="h4">Search Results:</Typography>
                ) : (
                  <>
                    {renderImage()}
                    <Grid item xs={6} sm={8}>
                      <Box pl={3}>
                        <Grid container direction="column" justify="space-between">
                          <Grid item>
                            <div className="folderHeader">
                              {editMode ? (
                                <ContentEditor
                                  defaultText="Title"
                                  rows={1}
                                  path={`${currentPath}/${Constants.DefaultHeaderFile}`}
                                  onSaved={handleMessage}
                                />
                              ) : (
                                <ContentViewer
                                  path={`${currentPath}/${Constants.DefaultHeaderFile}`}
                                />
                              )}
                            </div>
                          </Grid>
                          <Grid item>
                            <Box pt={2}>
                              <div className="folderContent">
                                {editMode ? (
                                  <ContentEditor
                                    defaultText="Description"
                                    rows={6}
                                    path={`${currentPath}/${Constants.DefaultTextFile}`}
                                    onSaved={handleMessage}
                                  />
                                ) : (
                                  <ContentViewer
                                    path={`${currentPath}/${Constants.DefaultTextFile}`}
                                  />
                                )}
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box mt={4}>
                  {!searchResults || searchResults.length > 0 ? (
                    <FileList
                      folderName={currentPath}
                      searchResults={searchResults}
                      editMode={editMode}
                      onChildFoldersReady={handleChildFoldersReady}
                      onUpload={handleUpload}
                    />
                  ) : (
                    <Typography className="noResultsFoundMessage">
                      Found <strong>0</strong> matches in <strong>0</strong>{' '}
                      documents
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {editMode && (
            // TODO: this snackbar should be removed
            <Snackbar
              autoHideDuration={600}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={message.show}
            >
              <Alert
                severity={message.success ? 'success' : 'error'}
                onClose={handleClose}
              >
                {message.message}
              </Alert>
            </Snackbar>
          )}
        </Container>
      </Box>
    </>
  );
};

export default FolderViwer;
