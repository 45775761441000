import { FC } from 'react';

import Typography from '@material-ui/core/Typography';

import SearchResultItem from '../SearchResultItem';
import SearchResultHeader from '../SearchResultHeader';

interface Props {
  result: any;
}

const SearchResults: FC<Props> = ({ result }) => {
  if (!result) return null;

  return (
    <>
      <p>
        <Typography>
          Found <strong>{result.matches}</strong> matches in{' '}
          <strong>{result.files.length}</strong> documents:
        </Typography>
      </p>
      {result.matches > 0 && (
        <>
          <SearchResultHeader />
          {result.files.map((f: any) => (
            <SearchResultItem
              key={f.key}
              extension={f.extension}
              urlPath={f.urlPath}
              name={f.name}
              folder={f.folder}
            />
          ))}
        </>
      )}
    </>
  );
};

export default SearchResults;
