import { FC } from 'react';

import cx from 'classnames';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '@quanterix-ui/core/Button';

import { UserStatuses } from 'src/utils/Constants';

import { useStyles } from './styles';

interface Props {
  status: keyof typeof UserStatuses;
  small?: boolean;
  onConfirm: () => void;
}

const ConfirmationInfo: FC<Props> = ({ status, small = false, onConfirm }) => {
  const classes = useStyles();

  const warningClass = small ? 'warning small' : 'warning';
  const successClass = small ? 'highlighted-text small' : 'highlighted-text';

  const userStatus = UserStatuses[status];

  if (status === 'UNCONFIRMED') {
    return (
      <Grid container wrap="nowrap" alignItems="center">
        <Typography
          className={cx(classes.typography, warningClass)}
          component="span"
        >
          {userStatus}
        </Typography>
        <Button
          className={classes.actionButton}
          size="small"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Grid>
    );
  }

  return (
    <Box fontWeight={900}>
      <Typography
        className={cx(
          classes.typography,
          status === 'CONFIRMED' ? successClass : warningClass
        )}
        component="span"
      >
        {userStatus}
      </Typography>
    </Box>
  );
};

export default ConfirmationInfo;
