import { FC, useState } from 'react';

import { createPortal } from 'react-dom';
import { useSnackbar } from 'notistack';

import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import { requestExportUsersList } from 'src/api/endpoints/admin';
import { SNACKBAR } from 'src/utils/constants/app';
import ConfirmDialog from 'src/components/ConfirmDialog';

const ExportUsersList: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userData } = useCognito();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExportUsers = async () => {
    setIsloading(true);
    try {
      await requestExportUsersList();
      enqueueSnackbar(`Users list was sent to: ${userData.email}`, {
        ...SNACKBAR.defaultOptions,
        variant: 'success',
        persist: false,
        resumeHideDuration: SNACKBAR.resumeHideDuration.success,
      });
    } catch (error) {
      console.error(error.message);
      enqueueSnackbar(error.message, {
        ...SNACKBAR.defaultOptions,
        variant: 'error',
        persist: false,
        resumeHideDuration: SNACKBAR.resumeHideDuration.error,
      });
    } finally {
      setIsloading(false);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        loading={isLoading}
        disabled={isLoading}
        onClick={handleOpen}
      >
        Export Users List
      </Button>
      {createPortal(
        <ConfirmDialog
          open={open}
          title="Export will be emailed to you shortly"
          acceptButtonText="Continue"
          onClose={handleClose}
          onAccept={handleExportUsers}
        />,
        document.getElementById('modal-root') as HTMLDivElement
      )}
    </>
  );
};

export default ExportUsersList;
