import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@quanterix-ui/core/TextField';

import { validateEmail } from './utils';

interface Props {
  name: string;
  required?: boolean;
}

const ControlledEmailTextField: FC<Props> = ({ name, required = false }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, ref },
        fieldState: { error, invalid },
      }) => (
        <TextField
          fullWidth
          value={value}
          inputRef={ref}
          error={invalid}
          helperText={error?.message}
          onChange={onChange}
        />
      )}
      rules={{
        required,

        validate: {
          validateEmail,
        },
      }}
    />
  );
};

export default ControlledEmailTextField;
